import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Box, Container, useTheme } from '@mui/material';
import { CgCloseR } from 'react-icons/cg';

import { CenteredMenuItem, ThemeToggle } from '../components';
import { useAuth0User } from '../hooks';

type Props = {
    setIsMenuOpen: (x: boolean) => void;
    isDarkMode: boolean;
    setIsDarkMode: (x: boolean) => void;
};
export function Menu({ isDarkMode, setIsDarkMode, setIsMenuOpen }: Props) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { t } = useTranslation();
    const { user } = useAuth0User();
    const navigate = useNavigate();
    const { logout } = useAuth0();
    const theme = useTheme();

    const goto = (path: string) => {
        setIsMenuOpen(false);
        navigate(path);
    };

    return (
        <Box
            sx={{
                zIndex: 100000,
                height: '100vh',
                overflow: 'hidden'
            }}
        >
            <Container
                maxWidth="sm"
                sx={{
                    marginTop: '6rem'
                }}
            >
                <Box
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline'
                        },
                        position: 'absolute',
                        right: '20px',
                        top: '20px'
                    }}
                    onClick={() => setIsMenuOpen(false)}
                >
                    <CgCloseR size={'40'} color={theme.palette.text.primary} />
                </Box>
                <Box
                    sx={{
                        marginTop: isMobile ? '0' : '4rem',
                        overflow: 'hidden'
                    }}
                >
                    {user.user_metadata?.role === 'researcher' && (
                        <>
                            <CenteredMenuItem goto={goto} path="/statistics">
                                {t('views.menu.item_statistics')}
                            </CenteredMenuItem>
                            <CenteredMenuItem goto={goto} path="/earnings">
                                {t('views.menu.item_earnings')}
                            </CenteredMenuItem>
                            <CenteredMenuItem
                                goto={goto}
                                path="/manage-content"
                            >
                                {t('views.menu.item_manage_content')}
                            </CenteredMenuItem>
                        </>
                    )}
                    <CenteredMenuItem goto={goto} path="/">
                        {t('views.menu.item_content')}
                    </CenteredMenuItem>
                    <CenteredMenuItem goto={goto} path="/about-us">
                        {t('views.menu.item_about_us')}
                    </CenteredMenuItem>
                    <CenteredMenuItem goto={goto} path="/settings">
                        {t('views.menu.item_settings')}
                    </CenteredMenuItem>
                    <CenteredMenuItem
                        logout={() =>
                            logout({
                                logoutParams: {
                                    returnTo: window.location.origin
                                }
                            })
                        }
                    >
                        {t('views.menu.item_logout')}
                    </CenteredMenuItem>
                    <ThemeToggle
                        isDarkMode={isDarkMode}
                        setIsDarkMode={setIsDarkMode}
                    />
                </Box>
            </Container>
        </Box>
    );
}
