import { useTranslation } from 'react-i18next';
import {
    useMediaQuery,
    Container,
    Box,
    Typography,
    useTheme
} from '@mui/material';

import {
    LogoLargeDark,
    LogoLargeLight,
    LogoSmallDark,
    LogoSmallLight
} from '../components';

type TeamImageProps = {
    src: string;
};
function TeamImage({ src }: TeamImageProps) {
    return (
        <img
            style={{
                margin: '1rem',
                width: '250px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            src={src}
        />
    );
}

export function AboutUs() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Container maxWidth="sm">
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <Box sx={{ marginTop: '5rem' }}>
                    {isSmallScreen ? (
                        isDarkMode ? (
                            <LogoSmallDark />
                        ) : (
                            <LogoSmallLight />
                        )
                    ) : isDarkMode ? (
                        <LogoLargeDark />
                    ) : (
                        <LogoLargeLight />
                    )}
                </Box>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.text.primary,
                        textAlign: 'center'
                    }}
                >
                    {t('views.landing_page.subheader')}
                </Typography>
            </Box>
            <Box>
                <Typography
                    variant="h6"
                    style={{
                        color: theme.palette.text.primary,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        marginTop: '3rem'
                    }}
                >
                    {t('views.landing_page.header')}
                </Typography>
                <Box
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: isSmallScreen ? 'column' : 'row'
                    }}
                >
                    <Box>
                        <TeamImage
                            src={
                                'https://backend.artreview.com/wp-content/uploads/2013/06/a39275279829652b0757c1d2e199f3ec_0.jpg'
                            }
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                textAlign: 'center'
                            }}
                        >
                            Ludde Gubbe
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                textAlign: 'center',
                                fontWeight: '400'
                            }}
                        >
                            CTO
                        </Typography>
                    </Box>
                    <Box>
                        <TeamImage
                            src={
                                'https://backend.artreview.com/wp-content/uploads/2013/06/a39275279829652b0757c1d2e199f3ec_0.jpg'
                            }
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                textAlign: 'center'
                            }}
                        >
                            Ludde Gubbe
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                textAlign: 'center',
                                fontWeight: '400'
                            }}
                        >
                            CTO
                        </Typography>
                    </Box>
                    <Box>
                        <TeamImage
                            src={
                                'https://backend.artreview.com/wp-content/uploads/2013/06/a39275279829652b0757c1d2e199f3ec_0.jpg'
                            }
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                textAlign: 'center'
                            }}
                        >
                            Ludde Gubbe
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                textAlign: 'center',
                                fontWeight: '400'
                            }}
                        >
                            CTO
                        </Typography>
                    </Box>
                    <Box>
                        <TeamImage
                            src={
                                'https://backend.artreview.com/wp-content/uploads/2013/06/a39275279829652b0757c1d2e199f3ec_0.jpg'
                            }
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                textAlign: 'center'
                            }}
                        >
                            Ludde Gubbe
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                textAlign: 'center',
                                fontWeight: '400'
                            }}
                        >
                            CTO
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}
