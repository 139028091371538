import { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FormUser } from '../reducers';
import { userLanguage } from '../i18n/i18n';
import { useAuth0User } from '../hooks';
import { Loading, MessageSnackbar, ResearcherForm } from '../components';
import { AvailableLanguages } from '../i18n/i18n';

export function UpdateResearcher() {
    const { t } = useTranslation();
    const { user, updateUser } = useAuth0User();
    const [showMessage, setShowMessage] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

    const updateAuth0User = async (u: Partial<FormUser>) => {
        try {
            if (!isLoadingUpdate) {
                setIsLoadingUpdate(true);
                await updateUser(
                    {
                        name: u.name,
                        email: u.email,
                        nickname: u.nickname,
                        picture: u.picture,
                        user_metadata: {
                            title: u.title ?? '',
                            tasks: u.tasks ?? '',
                            channel_backgroundimage:
                                u.channel_backgroundimage ?? '',
                            channel_name: u.channel_name,
                            channel_description: u.channel_description,
                            has_inited: true,
                            language: u.language as AvailableLanguages,
                            role: 'researcher',
                            subscriptions: user.user_metadata?.subscriptions
                        }
                    },
                    user.sub ?? user.user_id
                );
                setIsLoadingUpdate(false);
                setShowMessage(true);
            }
        } catch (e) {
            setIsLoadingUpdate(false);
        }
    };

    if (isLoadingUpdate) {
        return <Loading />;
    }

    return (
        <Box>
            {showMessage && (
                <>
                    <MessageSnackbar
                        message={t('views.form.data_saved')}
                        duration={2000}
                        onClose={() => setShowMessage(false)}
                    />
                </>
            )}
            <ResearcherForm
                action={updateAuth0User}
                _user={{
                    name: user.name,
                    email: user.email,
                    nickname: user.nickname,
                    picture: user.picture,
                    channel_backgroundimage:
                        user?.user_metadata?.channel_backgroundimage ?? '',
                    channel_name: user?.user_metadata?.channel_name ?? '',
                    channel_description:
                        user?.user_metadata?.channel_description ?? '',
                    title: user?.user_metadata?.title ?? '',
                    tasks: user?.user_metadata?.tasks ?? '',
                    language:
                        user?.user_metadata?.language ??
                        (userLanguage as AvailableLanguages)
                }}
            />
        </Box>
    );
}
