import { useState } from 'react';
import {
    Reply,
    ThumbDown,
    ThumbDownOutlined,
    ThumbUp,
    ThumbUpOutlined
} from '@mui/icons-material';
import {
    Box,
    Avatar,
    Typography,
    IconButton,
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    Divider,
    useTheme
} from '@mui/material';

import { Comment } from '../typings';
import { getTimeSince } from '../utils';
import { useTranslation } from 'react-i18next';
import { useAuth0User } from '../hooks';

type Props = {
    reply: boolean;
    comments: Comment[];
    handleDislike?: (x: string) => void;
    handleLike?: (x: string) => void;
    handleAddReply: (x: string, y: string) => void;
};
export function ListComments({
    handleLike,
    handleDislike,
    comments,
    reply,
    handleAddReply
}: Props) {
    const theme = useTheme();
    const [isReply, setIsReply] = useState<null | string>(null);
    const [comment, setComment] = useState('');
    const { user } = useAuth0User();
    const { t } = useTranslation();

    const onClickReply = (id: string) => {
        setIsReply(id);
    };

    const onClickReplyCancel = () => {
        setIsReply(null);
        setComment('');
    };

    const onClickDislike = (id: string) => {
        if (handleDislike) {
            handleDislike(id);
        }
    };

    const onClickLike = (id: string) => {
        if (handleLike) {
            handleLike(id);
        }
    };

    const onClickAddReply = (id: string) => {
        handleAddReply(id, comment);
        setIsReply(null);
        setComment('');
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: '100%',
                bgcolor: 'background.paper',
                marginTop: '1rem',
                marginLeft: reply ? '2rem' : ''
            }}
        >
            {comments.map((com: Comment, index: number) => (
                <Box key={index}>
                    <ListItem>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Avatar
                                    src={com.thumbnail}
                                    alt={com.owner_name}
                                    sx={{ width: '2.5rem', height: '2.5rem' }}
                                />
                                <ListItemText
                                    sx={{
                                        position: 'relative',
                                        top: '0.5rem',
                                        left: '1rem',
                                        color: theme.palette.text.primary
                                    }}
                                    secondary={`${
                                        com.owner_name
                                    } - ${getTimeSince(com.created_at)}`}
                                />
                            </Box>
                            <ListItemText
                                sx={{ color: theme.palette.text.primary }}
                                primary={com.comment}
                            />
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <IconButton
                                    onClick={() =>
                                        handleLike !== undefined &&
                                        onClickLike(com.id)
                                    }
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        {com.liked_by.includes(
                                            user.user_id.split('|')[1]
                                        ) ? (
                                            <ThumbUp />
                                        ) : (
                                            <ThumbUpOutlined />
                                        )}
                                        {com.liked_by.length > 0 && (
                                            <Typography
                                                sx={{
                                                    position: 'relative',
                                                    top: '0.15rem',
                                                    left: '0.4rem',
                                                    color: theme.palette.text
                                                        .primary,
                                                    fontSize: '0.9rem'
                                                }}
                                            >
                                                {com.liked_by.length}
                                            </Typography>
                                        )}
                                    </Box>
                                </IconButton>
                                <IconButton
                                    onClick={() =>
                                        handleDislike !== undefined &&
                                        onClickDislike(com.id)
                                    }
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        {com.disliked_by.includes(
                                            user.user_id.split('|')[1]
                                        ) ? (
                                            <ThumbDown />
                                        ) : (
                                            <ThumbDownOutlined />
                                        )}
                                        {com.disliked_by.length > 0 && (
                                            <Typography
                                                sx={{
                                                    position: 'relative',
                                                    top: '0.15rem',
                                                    left: '0.4rem',
                                                    color: theme.palette.text
                                                        .primary,
                                                    fontSize: '0.9rem'
                                                }}
                                            >
                                                {com.disliked_by.length}
                                            </Typography>
                                        )}
                                    </Box>
                                </IconButton>

                                {!reply && (
                                    <IconButton
                                        onClick={() => onClickReply(com.id)}
                                    >
                                        <Reply />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </ListItem>
                    {com.replies && com.replies.length > 0 && (
                        <ListComments
                            handleDislike={handleDislike}
                            handleLike={handleLike}
                            handleAddReply={handleAddReply}
                            comments={com.replies}
                            reply={true}
                        />
                    )}
                    {isReply === com.id && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                width: '100%',
                                marginBottom: '3rem',
                                marginTop: '1rem'
                            }}
                        >
                            <TextField
                                label={t('views.content.add_reply_placeholder')}
                                variant="standard"
                                fullWidth
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                sx={{ marginTop: '1rem' }}
                            />
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => onClickAddReply(com.id)}
                                sx={{ marginTop: '0.5rem' }}
                            >
                                {t('views.content.add_reply')}
                            </Button>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={onClickReplyCancel}
                                sx={{ marginTop: '0.5rem' }}
                            >
                                {t('views.content.add_reply_cancel')}
                            </Button>
                        </Box>
                    )}

                    {!reply && <Divider />}
                </Box>
            ))}
        </List>
    );
}
