import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    useMediaQuery,
    Button,
    Box,
    Typography,
    useTheme
} from '@mui/material';

import {
    LoginButton,
    Article,
    LogoLargeDark,
    LogoLargeLight,
    LogoSmallDark,
    LogoSmallLight
} from '../components';

function Logo({
    isSmallScreen,
    isDarkMode
}: {
    isSmallScreen: boolean;
    isDarkMode: boolean;
}) {
    return isSmallScreen ? (
        <Box sx={{ marginTop: '4rem' }}>
            {isDarkMode ? <LogoSmallDark /> : <LogoSmallLight />}
        </Box>
    ) : (
        <>{isDarkMode ? <LogoLargeDark /> : <LogoLargeLight />}</>
    );
}
export function LandingPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const theme = useTheme();

    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Box
            sx={
                isSmallScreen
                    ? {}
                    : {
                          height: '100vh',
                          width: '100vw',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          margin: '0.5rem'
                      }
            }
        >
            <LoginButton />
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <Logo isSmallScreen={isSmallScreen} isDarkMode={isDarkMode} />
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.text.primary,
                        textAlign: 'center',
                        margin: '0 1.5rem'
                    }}
                >
                    {t('views.landing_page.subheader')}
                </Typography>
            </Box>
            <Box
                sx={{
                    padding: isSmallScreen ? '1rem' : '4rem',
                    width: isSmallScreen ? '90%' : '700px'
                }}
            >
                <Typography
                    variant="h6"
                    style={{
                        textAlign: 'center',
                        color: theme.palette.text.primary,
                        textTransform: 'uppercase',
                        padding: '2rem'
                    }}
                >
                    {t('views.landing_page.header')}
                </Typography>
                <Article text={t('views.landing_page.paragraph') ?? ''} />
                <Box style={{ marginTop: '3rem' }}>
                    <Button
                        onClick={() => navigate('/new-researcher')}
                        variant="outlined"
                        style={{
                            marginBottom: '5rem',
                            float: 'left',
                            width: '40%',
                            height: '100px'
                        }}
                        size={'large'}
                    >
                        {t('views.landing_page.option_researcher')}
                    </Button>
                    <Button
                        onClick={() => navigate('/new-viewer')}
                        style={{
                            float: 'right',
                            width: '40%',
                            height: '100px'
                        }}
                        variant="outlined"
                        size={'large'}
                    >
                        {t('views.landing_page.option_viewer')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
