import React, { useEffect, useState } from 'react';

import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    Box,
    TableHead,
    TableRow,
    Input,
    Button,
    Checkbox
} from '@mui/material';

import { TagFilter } from './TagFilter';
import { unixTimeToYYMMDD } from '../utils';
import { Content } from '../typings';
import { useTranslation } from 'react-i18next';

function ArrowDownward() {
    return (
        <BsSortDownAlt
            style={{ position: 'relative', top: '2px', left: '2px' }}
        />
    );
}

function ArrowUpward() {
    return (
        <BsSortUpAlt
            style={{ position: 'relative', top: '2px', left: '2px' }}
        />
    );
}

type Order = {
    created_at: 'des' | 'asc';
    owner: 'des' | 'asc';
    title: 'des' | 'asc';
};

type Props = {
    content: Content[];
    onDelete: (ids: string) => void;
    onDeleteMany: (ids: string[]) => void;
    updateContent: (item: Content) => void;
    setIsNewContentVideoModalOpen: (x: boolean) => void;
};
export function ResearcherContentTable({
    content,
    onDelete,
    updateContent,
    setIsNewContentVideoModalOpen,
    onDeleteMany
}: Props) {
    const { t } = useTranslation();
    const [sortedData, setSortedData] = useState<Content[]>(content);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [order, setOrder] = useState<Order>({
        created_at: 'des',
        owner: 'des',
        title: 'des'
    });
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const handleSort = (field: keyof Content) => {
        const sorted = [...sortedData].sort((a, b) =>
            a[field] > b[field]
                ? order[field as keyof Order] === 'des'
                    ? 1
                    : -1
                : a[field] < b[field as keyof Order]
                ? order[field as keyof Order] === 'des'
                    ? -1
                    : 1
                : 0
        );
        setSortedData(sorted);
        setOrder({
            ...order,
            [field]: order[field as keyof Order] === 'des' ? 'asc' : 'des'
        });
    };

    const handleTagFilter = (tag: string) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(
                selectedTags.filter((selectedTag) => selectedTag !== tag)
            );
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleDelete = (item: string) => {
        onDelete(item);
    };

    const handleDeleteMany = () => {
        onDeleteMany(selectedItems);
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        id: string
    ) => {
        if (event.target.checked) {
            setSelectedItems([...selectedItems, id]);
        } else {
            setSelectedItems(selectedItems.filter((item) => item !== id));
        }
    };

    const filteredData =
        searchTerm.length > 0
            ? sortedData.filter(
                  (item) =>
                      item.title
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                      item.description
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
              )
            : sortedData;

    const filteredByTag =
        selectedTags.length > 0
            ? filteredData.filter((item) => item.tags.some((i) => selectedTags))
            : filteredData;

    useEffect(() => {
        setSortedData(content);
    }, [content]);

    return (
        <>
            <Box
                sx={{
                    marginTop: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <Input
                    sx={{ width: '100%', fontSize: '1.7rem' }}
                    type="text"
                    placeholder={t('views.table.search') ?? ''}
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </Box>

            <TagFilter
                selectedTags={selectedTags}
                predefinedTags={[
                    'Physics',
                    'English',
                    'Chemistry',
                    'Philosophy',
                    'Computer Science',
                    'Art',
                    'History',
                    'Economics',
                    'Mathematics'
                ]}
                handleTagFilter={handleTagFilter}
            />
            <Box sx={{ width: '100%', position: 'relative', top: '2rem' }}>
                <Button
                    sx={{ float: 'right' }}
                    size="small"
                    onClick={() => setIsNewContentVideoModalOpen(true)}
                >
                    {t('views.content.upload_content_video')}
                </Button>
                <Button
                    sx={{ float: 'left' }}
                    size="small"
                    disabled={selectedItems.length === 0}
                    onClick={() => handleDeleteMany()}
                >
                    {t('views.table.remove_selected')}
                </Button>
            </Box>
            <Table sx={{ marginTop: '2rem' }}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell onClick={() => handleSort('created_at')}>
                            {t('views.table.created_at')}{' '}
                            {order.created_at === 'des' ? (
                                <ArrowDownward />
                            ) : (
                                <ArrowUpward />
                            )}
                        </TableCell>
                        <TableCell>{t('views.table.tags')}</TableCell>
                        <TableCell onClick={() => handleSort('title')}>
                            {t('views.table.title')}{' '}
                            {order.title === 'des' ? (
                                <ArrowDownward />
                            ) : (
                                <ArrowUpward />
                            )}
                        </TableCell>
                        <TableCell>{t('views.table.description')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredByTag.map((item) => (
                        <TableRow
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(211,211,211,0.2)'
                                }
                            }}
                            key={item.id}
                        >
                            <TableCell>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <Checkbox
                                        checked={selectedItems.includes(
                                            item.id
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, item.id)
                                        }
                                    />
                                    <img
                                        style={{ width: '100px' }}
                                        src={item.thumbnail}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell onClick={() => updateContent(item)}>
                                {unixTimeToYYMMDD(item.created_at)}
                            </TableCell>
                            <TableCell onClick={() => updateContent(item)}>
                                {item.tags.join(', ')}
                            </TableCell>
                            <TableCell onClick={() => updateContent(item)}>
                                {item.title}
                            </TableCell>
                            <TableCell onClick={() => updateContent(item)}>
                                {item.description}
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => handleDelete(item.id)}>
                                    <FaTrash />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}
