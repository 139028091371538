export const sp = {
    translation: {
        views: {
            title: 'dizcover',
            about_us: {
                header: 'Sobre nosotros'
            },
            landing_page: {
                subheader: '[Conectando la ciencia con las personas]',
                header: 'Nuestra misión',
                paragraph: `Nuestra misión es hacer *la investigación accesible para todos*. Apoyamos a los investigadores en la transformación de su investigación en videos, juegos o contenido digital y hacerlos accesibles a través de nuestra plataforma.
                    *Las ganancias apoyarán la educación y el desarrollo económico de los países de bajos ingresos a través de nuestro modelo de negocio en el que conectamos educación, formación y empleo.`,
                option_researcher: 'Eres un investigador?',
                option_viewer: 'Eres un usuario?'
            },
            menu: {
                item_main: 'Inicio',
                item_content: 'Contenido',
                item_manage_content: 'Administrar mi contenido',
                item_statistics: 'Estadísticas',
                item_earnings: 'Puntos de impacto',
                item_settings: 'Ajustes',
                item_about_us: 'Sobre nosotros',
                item_logout: 'Desconectar'
            },
            content: {
                by: 'Por:',
                choosen_tags: 'Categorias seleccionadas',
                choose_video: 'Elije un video',
                people_like_this: 'A los usuarios les gusta',
                add_reply_cancel: 'Cancelar',
                add_reply: 'Responder',
                add_reply_placeholder: 'Adicionar respuesta',
                add_comment: 'Comentario',
                share: 'Compartir',
                title: 'Título',
                title_placeholder: ' Título',
                description: 'Descripción',
                description_placeholder: ' Descripción',
                cancel: 'Cancelar',
                upload_content_video: 'Subir contenido (video)',
                update: {
                    legend: 'Actualizar contenido de video'
                },
                create: {
                    legend: 'Crear contenido de video'
                }
            },
            form: {
                data_saved: 'Datos guardados',
                create_account: 'Crear Cuenta',
                choose_channel_background: 'Fondo del Canal',
                choose_picture: 'Elija una imagen',
                choose_language: 'Elija un idioma',
                name: 'Nombre',
                name_placeholder: 'su nombre',
                nickname: 'usuario',
                nickname_placeholder: 'su usuario',
                email: 'Email',
                email_placeholder: 'su email',
                title: 'Título',
                title_placeholder: 'su título',
                tasks: 'Area de investigación',
                tasks_placeholder: 'describa su área de investigación',
                channel_name: 'Nombre del canal',
                channel_description: 'Descripción del canal',
                submit: 'Enviar',
                password1: 'Clave',
                password2: 'Clave de nuevo',
                password1_placeholder: 'Clave',
                password2_placeholder: 'Clave de nuevo',
                delete_user_button: 'Eliminar cuenta',
                delete_user_confirmation:
                    'Esta seguro que quiere eliminar su usuario?',
                delete_user_confirmation_cancel: 'Cancelar',
                delete_user_confirmation_affirm: 'Sí'
            },
            table: {
                search: 'Buscar por título o descripción',
                created_at: 'Fecha',
                owner: 'Propietario',
                channel_name: 'Canal',
                tags: 'Tags',
                title: 'Título',
                description: 'Descripción',
                remove_selected: 'Eliminar selección',
                upload: 'Nuevo video'
            },
            statistics: {
                header: 'Visitas por periodo'
            },
            earnings: {
                header: 'Puntos de impacto ($) por periodo'
            },
            settings: {
                header: 'Ajustes'
            },
            breadcrumbs: {
                home: 'Inicio'
            }
        }
    }
};
