import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { FormUserViewer } from '../reducers';
import { userLanguage } from '../i18n/i18n';
import { useAuth0User } from '../hooks';
import { ViewerForm, Loading, MessageSnackbar } from '../components';
import { AvailableLanguages } from '../i18n/i18n';

export function UpdateViewer() {
    const { t } = useTranslation();
    const { user, updateUser } = useAuth0User();
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const updateAuth0User = async (u: Partial<FormUserViewer>) => {
        try {
            if (!isLoadingUpdate) {
                setIsLoadingUpdate(true);
                await updateUser(
                    {
                        name: u.name,
                        email: u.email,
                        nickname: u.nickname,
                        picture: u.picture,
                        user_metadata: {
                            has_inited: true,
                            language: u.language as AvailableLanguages,
                            role: 'viewer'
                        }
                    },
                    user.sub ?? user.user_id
                );
                setIsLoadingUpdate(false);
                setShowMessage(true);
            }
        } catch (e) {
            setIsLoadingUpdate(false);
        }
    };

    if (isLoadingUpdate) {
        return <Loading />;
    }

    return (
        <Box>
            {showMessage && (
                <>
                    <MessageSnackbar
                        message={t('views.form.data_saved')}
                        duration={2000}
                        onClose={() => setShowMessage(false)}
                    />
                </>
            )}
            <ViewerForm
                action={updateAuth0User}
                _user={{
                    name: user.name,
                    email: user.email,
                    nickname: user.nickname,
                    picture: user.picture,
                    language:
                        user?.user_metadata?.language ??
                        (userLanguage as AvailableLanguages)
                }}
            />
        </Box>
    );
}
