import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Favorite, Home, Share } from '@mui/icons-material';
import {
    Box,
    Breadcrumbs,
    Typography,
    Container,
    Button,
    TextField,
    useTheme,
    Avatar
} from '@mui/material';

import {
    ShareModal,
    Loading,
    StyledBreadcrumb,
    ListComments
} from '../components';
import { AccessTokenContext, AccessTokenContextType } from '../contexts';
import { Channel, Comment, Content, ContentMeta } from '../typings';
import {
    getChannelById,
    getContentByOwner,
    postStatsVideo,
    putContentMeta,
    formatComments,
    getContentMetaById,
    sortDates
} from '../utils';
import { useTranslation } from 'react-i18next';
import { useAuth0User } from '../hooks';

const isComments = (xs: any[] | Comment[]): xs is Comment[] => xs[0].comment;

export function ViewerVideo() {
    const { channelId, videoId } = useParams();
    const [content, setContent] = useState<Content[]>([]);
    const { user } = useAuth0User();
    const [video, setVideo] = useState<null | Content>(null);
    const { t } = useTranslation();
    const [postedStats, setPostedStats] = useState(false);
    const theme = useTheme();
    const [loaded, setLoaded] = useState(false);
    const [channelData, setChannelData] = useState<null | Channel>(null);
    const { accessToken } = useContext(
        AccessTokenContext
    ) as AccessTokenContextType;
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [liked, setLiked] = useState(false);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState<Comment[]>([]);
    const [likes, setLikes] = useState<string[]>([]);

    const shareUrl = 'https://your-website-url.com';

    const handleShareButtonClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleLikeButtonClick = async () => {
        if (video) {
            const result: null | ContentMeta = await putContentMeta(
                accessToken,
                video.id,
                {
                    like: !liked
                }
            );
            if (
                result &&
                result.comments !== undefined &&
                result.comments.length > 0 &&
                isComments(result.comments)
            ) {
                setComments(formatComments(result.comments.sort(sortDates)));
            }

            if (result && result.likes !== undefined) {
                const id = user.user_id.split('|')[1];
                setLiked(result.likes.includes(id));
                setLikes(result.likes);
            }
        }
    };

    const handleCommentChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setComment(event.target.value);
    };

    const handleAddComment = async () => {
        if (video && comment.trim().length) {
            const result = await putContentMeta(accessToken, video.id, {
                thumbnail: user.picture ?? '',
                reply_to: null,
                owner_name: user.name,
                comment
            });
            if (result && result.comments && isComments(result.comments)) {
                setComments(formatComments(result.comments.sort(sortDates)));
            }
            setComment('');
        }
    };

    const handleLike = async (id: string) => {
        if (video) {
            const result = await putContentMeta(accessToken, video.id, {
                like_comment: id
            });
            if (result && result.comments && isComments(result.comments)) {
                setComments(formatComments(result.comments.sort(sortDates)));
            }
        }
    };

    const handleDislike = async (id: string) => {
        if (video) {
            const result = await putContentMeta(accessToken, video.id, {
                dislike_comment: id
            });
            if (result && result.comments && isComments(result.comments)) {
                setComments(formatComments(result.comments.sort(sortDates)));
            }
        }
    };

    const handleAddReply = async (commentId: string, reply: string) => {
        if (video && reply.trim().length) {
            const result = await putContentMeta(accessToken, video.id, {
                thumbnail: user.picture ?? '',
                reply_to: commentId,
                owner_name: user.name,
                comment: reply
            });
            if (result && result.comments && isComments(result.comments)) {
                setComments(formatComments(result.comments.sort(sortDates)));
            }
            setComment('');
        }
    };

    useEffect(() => {
        (async () => {
            if (!postedStats && channelId && videoId && accessToken) {
                try {
                    setPostedStats(true);
                    await postStatsVideo(videoId, channelId, accessToken);
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [channelId, videoId, accessToken, postedStats]);

    useEffect(() => {
        if (likes.length > 0) {
            const id = user.user_id.split('|')[1];
            setLiked(likes.includes(id));
        } else {
            setLiked(false);
        }
    }, [likes, user, setLiked]);

    useEffect(() => {
        (async () => {
            try {
                if (channelId && videoId && !loaded) {
                    const result = await getContentByOwner(
                        accessToken,
                        channelId
                    );
                    if (result) {
                        const _video =
                            content.find((c) => c.id === videoId) ?? null;
                        setVideo(_video);
                        setContent(result);
                    }
                    const _channelData = await getChannelById(
                        accessToken,
                        channelId
                    );
                    if (_channelData) {
                        setChannelData(_channelData);
                    }
                    const coms = await getContentMetaById(accessToken, videoId);
                    if (coms) {
                        setComments(
                            formatComments(coms.comments.sort(sortDates))
                        );
                        const id = user.user_id.split('|')[1];
                        setLiked(coms.likes.includes(id));
                        setLikes(coms.likes);
                    }

                    setLoaded(true);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [channelId, user, videoId, accessToken, loaded, content]);

    return !content.length || !video || channelData == null || !channelId ? (
        <Loading />
    ) : (
        <Container maxWidth="md" sx={{ height: '100vh' }}>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="/"
                    label={t('views.breadcrumbs.home')}
                    icon={<Home fontSize="small" />}
                />
                <StyledBreadcrumb
                    component="a"
                    href={`/${channelId}`}
                    label={channelData.channelName}
                />
                <StyledBreadcrumb label={video.title} />
            </Breadcrumbs>
            <Box
                sx={{
                    display: 'flex',
                    marginTop: '2rem',
                    flexDirection: 'column'
                }}
            >
                <video style={{ width: '100%' }} preload="auto" controls>
                    <source src={video.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                color: theme.palette.text.primary
                            }}
                        >
                            {video.title}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginLeft: '3rem'
                            }}
                        >
                            <Button
                                variant="text"
                                color="primary"
                                startIcon={<Share />}
                                onClick={handleShareButtonClick}
                            >
                                {t('views.content.share')}
                            </Button>
                            <Favorite
                                onClick={handleLikeButtonClick}
                                sx={{
                                    cursor: 'pointer',
                                    marginLeft: '1rem',
                                    color: liked ? 'red' : 'gray'
                                }}
                            />
                            {likes.length > 0 && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        opacity: 0.5,
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    {likes.length}{' '}
                                    {t('views.content.people_like_this')}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                '&:hover': {
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                }
                            }}
                            onClick={() => navigate(`/${channelId}`)}
                        >
                            <Avatar src={channelData.owner_thumbnail} />
                            <Typography
                                sx={{
                                    marginLeft: '0.4rem',
                                    fontSize: '0.7trm',
                                    color: theme.palette.text.primary
                                }}
                            >
                                {channelData.owner}
                            </Typography>
                        </Box>
                    </Box>
                    <ShareModal
                        open={modalOpen}
                        onClose={handleCloseModal}
                        shareUrl={shareUrl}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: '1rem'
                    }}
                >
                    <TextField
                        label="Add a comment"
                        variant="standard"
                        fullWidth
                        value={comment}
                        onChange={handleCommentChange}
                        sx={{ marginTop: '1rem' }}
                    />
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleAddComment}
                        sx={{ position: 'relative', top: '1rem' }}
                    >
                        {t('views.content.add_comment')}
                    </Button>
                </Box>
                {comments.length > 0 && (
                    <ListComments
                        handleAddReply={handleAddReply}
                        comments={comments}
                        reply={false}
                        handleLike={handleLike}
                        handleDislike={handleDislike}
                    />
                )}
            </Box>
        </Container>
    );
}
