import { Box, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: '#ffffff'
        }
    }
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#2B2D53'
        }
    }
});

type Props = {
    isDarkMode: boolean;
    setIsDarkMode: (x: boolean) => void;
};
export function ThemeToggle({ isDarkMode, setIsDarkMode }: Props) {
    const toggleTheme = () => {
        const newIsDarkTheme = !isDarkMode;
        setIsDarkMode(newIsDarkTheme);
        document.body.style.backgroundColor = newIsDarkTheme
            ? '#2B2D53'
            : '#ffffff';
        localStorage.setItem('dizcovertheme', String(newIsDarkTheme));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <IconButton onClick={toggleTheme}>
                {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
        </Box>
    );
}
