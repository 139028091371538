import { useTranslation } from 'react-i18next';
import { Box, Typography, Link, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { unixTimeToYYMMDD } from '../utils';
import { Content } from '../typings';

type Props = {
    content: Content[];
};
export function ViewerContentTableMobile({ content }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Box sx={{ marginTop: '1rem' }}>
            {content.map((item) => (
                <Box key={item.id} sx={{ marginBottom: '1rem' }}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: theme.palette.text.primary,
                            marginLeft: '0.2rem'
                        }}
                    >
                        {item.title}{' '}
                        <span
                            style={{
                                float: 'right',
                                position: 'relative',
                                right: '10px',
                                top: '5px',
                                fontSize: '1rem',
                                fontWeight: '400',
                                color: theme.palette.text.primary,
                                opacity: 0.5
                            }}
                        >
                            {unixTimeToYYMMDD(item.created_at)}
                        </span>
                    </Typography>
                    <img
                        style={{ width: '100%' }}
                        src={item.thumbnail}
                        onClick={() => navigate(`/${item.owner}/${item.id}`)}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.text.primary,
                            marginLeft: '0.2rem'
                        }}
                    >
                        <strong style={{ marginRight: '0.5rem' }}>
                            {t('views.content.by')}
                        </strong>
                        <Link
                            sx={{
                                color: theme.palette.text.primary
                            }}
                            onClick={() => navigate(`/${item.owner}`)}
                        >
                            {item.channel_name}
                        </Link>
                    </Typography>
                </Box>
            ))}
        </Box>
    );
}
