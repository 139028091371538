import { useContext, useEffect, useState } from 'react';

import { useAuth0User } from './useAuth0User';
import { Content } from '../typings';
import { getContent } from '../utils';
import { AccessTokenContext, AccessTokenContextType } from '../contexts';

export function useContent() {
    const { user } = useAuth0User();
    const { accessToken } = useContext(
        AccessTokenContext
    ) as AccessTokenContextType;
    const [content, setContent] = useState<Content[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const result = await getContent(accessToken);
                if (
                    result &&
                    user &&
                    user.user_metadata &&
                    user.user_metadata.subscriptions
                ) {
                    const sortedResult = result.sort((a, b) => {
                        if (
                            user &&
                            user.user_metadata &&
                            user.user_metadata.subscriptions
                        ) {
                            const aIsSubscribed =
                                user?.user_metadata?.subscriptions.includes(
                                    a.owner
                                );
                            const bIsSubscribed =
                                user?.user_metadata?.subscriptions.includes(
                                    b.owner
                                );
                            if (aIsSubscribed && !bIsSubscribed) {
                                return -1;
                            } else if (!aIsSubscribed && bIsSubscribed) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                        return 0;
                    });
                    setContent(sortedResult);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return { content };
}
