import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n/i18n';
import App from './App';

import './index.css';
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0Provider
                domain="dev-dnwubwa3257m75rh.eu.auth0.com"
                clientId="OySi8PlULM5Hjqf7Hvc9Y4kxBJp7efbX"
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience:
                        'https://dev-dnwubwa3257m75rh.eu.auth0.com/api/v2/',
                    scope: 'read:current_user openid profile'
                }}
            >
                <I18nextProvider i18n={i18n}>
                    <App />
                </I18nextProvider>
            </Auth0Provider>
        </BrowserRouter>
    </React.StrictMode>
);
