import {
    Content,
    Channel,
    Earning,
    Statistics,
    User,
    ContentMeta
} from '../typings';
import { domain, env } from '../env';

enum HttpMethod {
    'GET' = 'GET',
    'DELETE' = 'DELETE',
    'PUT' = 'PUT',
    'POST' = 'POST'
}

async function request(
    method: HttpMethod,
    url: string,
    accessToken: string | null,
    body?: string,
    extraHeaders?: Record<string, string>
) {
    try {
        const options: RequestInit = {
            method
        };
        if (accessToken) {
            options.headers = {
                Authorization: `Bearer ${accessToken}`
            };
        }
        if (body) {
            options.body = body;
        }
        if (extraHeaders) {
            const newHeaders = { ...options.headers, ...extraHeaders };
            options.headers = newHeaders;
        }
        const response = await fetch(url, options);
        try {
            const result = await response.json();
            return result;
        } catch (e) {
            return response;
        }
    } catch (e) {
        throw e;
    }
}

export async function postVideoMp4(
    f: any,
    accessToken: string
): Promise<string | null> {
    const url = `${domain[env]}video`;
    const r = await request(HttpMethod.POST, url, accessToken, f, {
        'Content-Type': 'video/mp4'
    });
    return r;
}

export async function postStatsChannel(
    owner_id: string,
    accessToken: string
): Promise<string | null> {
    const url = `${domain[env]}stats-channel`;
    const body = JSON.stringify({ owner_id });
    const r = await request(HttpMethod.POST, url, accessToken, body);
    return r;
}

export async function postStatsVideo(
    video_id: string,
    owner_id: string,
    accessToken: string
): Promise<string | null> {
    const url = `${domain[env]}stats-video`;
    const body = JSON.stringify({ owner_id, video_id });
    const r = await request(HttpMethod.POST, url, accessToken, body);
    return r;
}
//
//unsubscribe!
export async function postSubscription(
    id: string,
    ids: string[],
    accessToken: string
): Promise<string | null> {
    const url = `${domain[env]}subscription/${id}`;
    const body = JSON.stringify({ ids });
    const r = await request(HttpMethod.POST, url, accessToken, body);
    return r;
}

export async function postThumbnail(
    f: any,
    accessToken: string,
    id: string
): Promise<string | null> {
    const url = `${domain[env]}thumbnail/${id}`;
    const r = await request(HttpMethod.POST, url, accessToken, f, {
        'Content-Type': 'image/jpeg'
    });
    return r;
}

export async function postImage(f: any): Promise<string | null> {
    const url = `${domain[env]}picture`;
    const r = await request(HttpMethod.POST, url, null, f, {
        'Content-Type': 'image/jpeg'
    });
    return r;
}

export async function deleteAuth0User(
    accessToken: string,
    sub: string
): Promise<Record<string, string | number>> {
    const id = sub.split('|')[1] ?? '123';
    const url = `${domain[env]}users/${id}`;
    const r = await request(HttpMethod.DELETE, url, accessToken);
    return r;
}

export async function addAuth0User(user: Partial<User>): Promise<User | null> {
    const url = `${domain[env]}users`;
    const body = JSON.stringify(user);
    const r = await request(HttpMethod.POST, url, null, body);
    return r;
}

export async function getChannelById(
    accessToken: string,
    id: string
): Promise<Channel | null> {
    const url = `${domain[env]}channel/${id}`;
    const r = await request(HttpMethod.GET, url, accessToken);
    return r;
}

export async function getContentByOwner(
    accessToken: string,
    owner: string
): Promise<Content[] | null> {
    const url = `${domain[env]}content/${owner}`;
    const r = await request(HttpMethod.GET, url, accessToken);
    return r;
}

export async function addContent(
    accessToken: string,
    content: Partial<Content>
): Promise<Content | null> {
    const url = `${domain[env]}content`;
    const body = JSON.stringify(content);
    const r = await request(HttpMethod.POST, url, accessToken, body);
    return r;
}

export async function postStats(f: any): Promise<null> {
    const url = `${domain[env]}stats`;
    const r = await request(HttpMethod.POST, url, null, f, {
        'Content-Type': 'image/jpeg'
    });
    return r;
}
///
export async function putContentMeta(
    accessToken: string,
    id: string,
    content: Record<string, boolean | null | string>
): Promise<ContentMeta | null> {
    const url = `${domain[env]}content-meta/${id}`;
    const body = JSON.stringify(content);
    const r = await request(HttpMethod.PUT, url, accessToken, body);
    return r;
}

export async function getContentMetaById(
    accessToken: string,
    id: string
): Promise<ContentMeta | null> {
    const url = `${domain[env]}content-meta/${id}`;
    const r = await request(HttpMethod.GET, url, accessToken);
    return r;
}

export async function putContent(
    accessToken: string,
    content: Partial<Content>
): Promise<Content | null> {
    const url = `${domain[env]}content/${content.id}`;
    const body = JSON.stringify(content);
    const r = await request(HttpMethod.PUT, url, accessToken, body);
    return r;
}

export async function deleteContent(
    accessToken: string,
    id: string
): Promise<null> {
    const url = `${domain[env]}content/${id}`;
    const r = await request(HttpMethod.DELETE, url, accessToken);
    return r;
}

export async function deleteContentMany(
    accessToken: string,
    ids: string[]
): Promise<null> {
    const url = `${domain[env]}content`;
    const body = JSON.stringify({ ids });
    try {
        const r = await request(HttpMethod.DELETE, url, accessToken, body);
        return r;
    } catch (e) {
        console.error(e);
    }
    return null;
}

export async function getEarningsByOwner(
    accessToken: string,
    owner: string
): Promise<Earning[] | null> {
    const url = `${domain[env]}earnings/${owner}`;
    const r = await request(HttpMethod.GET, url, accessToken);
    return r;
}

export async function getStatsByOwner(
    accessToken: string,
    owner: string
): Promise<Statistics | null> {
    const id = owner.split('|')[1] ?? '123';
    const url = `${domain[env]}stats/${id}`;
    const r = await request(HttpMethod.GET, url, accessToken);
    return r;
}

export async function getContent(
    accessToken: string
): Promise<Content[] | null> {
    const url = `${domain[env]}content`;
    const r = await request(HttpMethod.GET, url, accessToken);
    return r;
}

export async function updateAuth0User(
    accessToken: string,
    sub: string,
    user: Partial<User>
): Promise<User | null> {
    const id = sub.split('|')[1] ?? '123';
    const url = `${domain[env]}users/${id}`;
    const body = JSON.stringify(user);
    const r = await request(HttpMethod.PUT, url, accessToken, body);
    return r;
}

export function getAuth0User(
    accessToken: string,
    sub: string,
    domain: string
): Promise<User | { error: string } | null> {
    const url = `https://${domain}/api/v2/users/${sub}`;
    return request(HttpMethod.GET, url, accessToken);
}

export async function awakeAPI(
    accessToken: string
): Promise<{ message: string } | null> {
    const url = `${domain[env]}awake`;
    const r = await request(HttpMethod.GET, url, accessToken);
    return r;
}
