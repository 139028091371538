import { useState } from 'react';

import { addAuth0User, postVideoMp4, postImage } from '../utils/api';
import { User } from '../typings';

export function useNewUser() {
    const [isLoading, setIsLoading] = useState(false);

    const postPicture = async (f: any): Promise<null | undefined | string> => {
        try {
            setIsLoading(true);
            const r = await postImage(f);
            setIsLoading(false);
            return r;
        } catch (e) {
            setIsLoading(false);
        }
    };

    const postVideo = async (
        f: any,
        accessToken: string
    ): Promise<null | undefined | string> => {
        try {
            setIsLoading(true);
            const r = await postVideoMp4(f, accessToken);
            setIsLoading(false);
            return r;
        } catch (e) {
            setIsLoading(false);
        }
    };

    const addUser = async (u: Partial<User> & { password: string }) => {
        try {
            setIsLoading(true);
            const r = await addAuth0User(u);
            return r;
        } catch (e) {
            setIsLoading(false);
        }
    };

    return {
        postPicture,
        postVideo,
        addUser,
        isLoading
    };
}
