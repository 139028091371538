import { Box, Backdrop, CircularProgress } from '@mui/material';

export function Loading() {
    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
