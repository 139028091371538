import { useEffect, useState, useReducer, Dispatch, ChangeEvent } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AccountCircle, Wallpaper } from '@mui/icons-material';
import {
    FormControl,
    Select,
    MenuItem,
    Box,
    Button,
    TextField,
    SelectChangeEvent,
    InputLabel,
    useMediaQuery,
    useTheme
} from '@mui/material';

import { Loading, Dialog, MessageSnackbar } from '../components';
import { validationSchema, convertImageToBase64 } from '../utils';
import {
    FormUser,
    FormActionUser,
    DispatchAction,
    userFormReducer
} from '../reducers';

import { useNewUser } from '../hooks';
import i18n, { AvailableLanguages, userLanguage } from '../i18n/i18n';
import { User } from '../typings';

type Props = {
    action: (u: Partial<User>) => void;
    _user?: FormUser;
};
export function ResearcherForm({ action, _user }: Props) {
    const { t } = useTranslation();

    const isMobile = useMediaQuery('(max-width:600px)');
    const [user, dispatch]: [FormUser, Dispatch<FormActionUser>] = useReducer(
        userFormReducer,
        _user
            ? {
                  name: _user.name,
                  email: _user.email,
                  nickname: _user.nickname,
                  picture: _user.picture,
                  channel_backgroundimage: _user.channel_backgroundimage,
                  channel_name: _user.channel_name,
                  channel_description: _user.channel_description,
                  title: _user.title,
                  tasks: _user.tasks,
                  language: _user.language
              }
            : {
                  name: '',
                  email: '',
                  nickname: '',
                  picture: '',
                  title: '',
                  channel_backgroundimage: '',
                  channel_name: '',
                  channel_description: '',
                  password1: '',
                  password2: '',
                  tasks: '',
                  language: userLanguage as AvailableLanguages
              }
    );
    const [errorMessage, setErrorMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const { postPicture } = useNewUser();
    const theme = useTheme();
    const [isLoadingPicture, setIsLoadingPicture] = useState(false);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [initialInteraction, setInitialInteraction] = useState(
        _user
            ? {
                  email: Boolean(user.email.length),
                  name: Boolean(user.name.length),
                  nickname: Boolean(user.nickname.length)
              }
            : {
                  email: Boolean(user.email.length),
                  name: Boolean(user.name.length),
                  nickname: Boolean(user.nickname.length),
                  title: Boolean(user.title.length),
                  tasks: Boolean(user.tasks.length),
                  channel_name: Boolean(user.channel_name.length),
                  channel_description: Boolean(user.channel_description.length),
                  password1: Boolean(user?.password1?.length),
                  password2: Boolean(user?.password2?.length)
              }
    );

    const handleFileChange = async (
        event: ChangeEvent<HTMLInputElement>,
        key: keyof FormUser
    ) => {
        let count = 0;
        let success = false;
        async function tryPostImage() {
            try {
                if (!success && event.target.files) {
                    setIsLoadingPicture(true);
                    const file = event.target.files[0];
                    const b64 = await convertImageToBase64(file);
                    count++;
                    const path = await postPicture(b64);
                    success = true;
                    dispatch({
                        type: DispatchAction.UPDATE,
                        payload: { [key]: path ?? user[key] }
                    });
                    setIsLoadingPicture(false);
                }
            } catch (e) {
                success = false;
                setIsLoadingPicture(false);
            }
        }
        while (count < 3) {
            await tryPostImage();
            if (success) {
                break;
            }
        }
    };

    useEffect(() => {
        (async () => {
            try {
                await validationSchema.validate(user, { abortEarly: false });
                setFormErrors({});
            } catch (err: any) {
                const errors: { [key: string]: string } = err.inner.reduce(
                    (
                        acc: Record<string, string>,
                        error: yup.ValidationError
                    ) =>
                        (error.path as string) in initialInteraction &&
                        initialInteraction[
                            error.path as keyof typeof initialInteraction
                        ]
                            ? {
                                  ...acc,
                                  [error.path as keyof FormUser]: error.message
                              }
                            : acc,
                    {}
                );
                setFormErrors(errors);
            }
        })();
    }, [user, initialInteraction]);

    if (errorMessage) {
        <Dialog
            title={t('components.user_form.dialog_error_header')}
            message={errorMessage}
            setMessage={setErrorMessage}
        />;
    }

    if (isLoadingPicture) {
        return <Loading />;
    }

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
            {showMessage && (
                <>
                    <MessageSnackbar
                        message={t('views.form.data_saved')}
                        duration={2000}
                        onClose={() => setShowMessage(false)}
                    />
                </>
            )}
            <Box
                sx={{
                    margin: 0,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                {user?.picture?.includes('gravatar') ||
                !user?.picture?.length ? (
                    <AccountCircle
                        sx={{
                            color: 'rgba(160,160,160,1)',
                            opacity: 0.7,
                            borderRadius: '50%',
                            width: isMobile ? '250px' : '300px',
                            height: isMobile ? '250px' : '300px'
                        }}
                    />
                ) : (
                    <img
                        alt="user"
                        src={user.picture}
                        style={{
                            borderRadius: '50%',
                            width: isMobile ? '250px' : '300px',
                            height: isMobile ? '250px' : '300px'
                        }}
                    />
                )}
                <Button
                    variant="outlined"
                    style={{
                        width: '250px',
                        marginTop: '1rem',
                        marginBottom: '2rem'
                    }}
                    component="label"
                >
                    {t('views.form.choose_picture')}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e, 'picture')}
                        hidden
                    />
                </Button>
            </Box>
            <Box
                sx={{
                    margin: 0,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                {!user?.channel_backgroundimage?.length ? (
                    <Wallpaper
                        sx={{
                            color: 'rgba(160,160,160,1)',
                            opacity: 0.7,
                            borderRadius: '50%',
                            width: isMobile ? '250px' : '300px',
                            height: isMobile ? '250px' : '300px'
                        }}
                    />
                ) : (
                    <img
                        alt="wallpaper"
                        src={user.channel_backgroundimage}
                        style={{
                            width: '100%'
                        }}
                    />
                )}
                <Button
                    variant="outlined"
                    style={{
                        width: '250px',
                        marginTop: '1rem',
                        marginBottom: '2rem'
                    }}
                    component="label"
                >
                    {t('views.form.choose_channel_background')}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                            handleFileChange(e, 'channel_backgroundimage')
                        }
                        hidden
                    />
                </Button>
            </Box>
            <TextField
                required
                label={t('views.form.name')}
                type="text"
                id={'name'}
                sx={{ width: '100%', marginBottom: '1rem' }}
                name={'name'}
                value={user.name}
                onBlur={() =>
                    setInitialInteraction({ ...initialInteraction, name: true })
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    dispatch({
                        type: DispatchAction.UPDATE,
                        payload: { name: e.target.value }
                    })
                }
                autoComplete="name"
                error={!!formErrors.name}
                helperText={formErrors.name}
            />
            <div>
                <TextField
                    required
                    label={t('views.form.nickname')}
                    type="text"
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    id={'nickname'}
                    name={'nickname'}
                    value={user.nickname}
                    onBlur={() =>
                        setInitialInteraction({
                            ...initialInteraction,
                            nickname: true
                        })
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { nickname: e.target.value }
                        })
                    }
                    error={!!formErrors.nickname}
                    helperText={formErrors.nickname}
                />
            </div>
            <div>
                <TextField
                    required
                    label={t('views.form.email')}
                    type="text"
                    id={'email'}
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    autoComplete="email"
                    name={'email'}
                    value={user.email}
                    onBlur={() =>
                        setInitialInteraction({
                            ...initialInteraction,
                            email: true
                        })
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { email: e.target.value }
                        })
                    }
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                />
            </div>
            <div>
                <TextField
                    required
                    label={t('views.form.title')}
                    type="text"
                    id={'title'}
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    name={'title'}
                    value={user.title}
                    onBlur={() =>
                        setInitialInteraction(
                            initialInteraction.title !== undefined
                                ? { ...initialInteraction, title: true }
                                : initialInteraction
                        )
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { title: e.target.value }
                        })
                    }
                    error={!!formErrors.title}
                    helperText={formErrors.title}
                />
            </div>
            <div>
                <TextField
                    required
                    label={t('views.form.tasks')}
                    type="text"
                    id={'tasks'}
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    name={'tasks'}
                    onBlur={() =>
                        setInitialInteraction(
                            initialInteraction.tasks !== undefined
                                ? { ...initialInteraction, tasks: true }
                                : initialInteraction
                        )
                    }
                    value={user.tasks}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { tasks: e.target.value }
                        })
                    }
                    error={!!formErrors.tasks}
                    helperText={formErrors.tasks}
                />
            </div>
            <div>
                <TextField
                    required
                    label={t('views.form.channel_name')}
                    type="text"
                    id={'channel_name'}
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    name={'channel_name'}
                    value={user.channel_name}
                    onBlur={() =>
                        setInitialInteraction(
                            initialInteraction.channel_name !== undefined
                                ? { ...initialInteraction, channel_name: true }
                                : initialInteraction
                        )
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { channel_name: e.target.value }
                        })
                    }
                    error={!!formErrors.channel_name}
                    helperText={formErrors.channel_name}
                />
            </div>
            <div>
                <TextField
                    required
                    multiline
                    rows={5}
                    label={t('views.form.channel_description')}
                    type="text"
                    id={'channel_description'}
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    name={'channel_description'}
                    value={user.channel_description}
                    onBlur={() =>
                        setInitialInteraction(
                            initialInteraction.channel_description !== undefined
                                ? {
                                      ...initialInteraction,
                                      channel_description: true
                                  }
                                : initialInteraction
                        )
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { channel_description: e.target.value }
                        })
                    }
                    error={!!formErrors.channel_description}
                    helperText={formErrors.channel_description}
                />
            </div>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel
                    id="language-select-label"
                    sx={{
                        padding: '0 5px',
                        width: '127px',
                        marginBottom: '1rem',
                        marginTop: '2px',
                        marginLeft: '-8px',
                        backgroundColor: theme.palette.background.default
                    }}
                >
                    {t('views.form.choose_language')}
                </InputLabel>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={i18n.language}
                    onChange={(e: SelectChangeEvent<string>) => {
                        const language = (e?.target?.value ??
                            'en') as AvailableLanguages;
                        i18n.changeLanguage(language);
                        localStorage.setItem('language', language);
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { language }
                        });
                    }}
                >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="sp">Español</MenuItem>
                    <MenuItem value="sv">Svenska</MenuItem>
                </Select>
            </FormControl>
            {!_user && (
                <Box sx={{ marginTop: '2rem' }}>
                    <div>
                        <TextField
                            required
                            label={t('views.form.password1')}
                            type="password"
                            autoComplete="new-password"
                            id={'password1'}
                            sx={{ width: '100%', marginBottom: '1rem' }}
                            name={'password1'}
                            value={user.password1}
                            onBlur={() =>
                                setInitialInteraction(
                                    initialInteraction.password1 !== undefined
                                        ? {
                                              ...initialInteraction,
                                              password1: true
                                          }
                                        : initialInteraction
                                )
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                dispatch({
                                    type: DispatchAction.UPDATE,
                                    payload: { password1: e.target.value }
                                })
                            }
                            error={!!formErrors.password1}
                            helperText={formErrors.password1}
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            label={t('views.form.password2')}
                            type="password"
                            autoComplete="current-password"
                            sx={{ width: '100%', marginBottom: '1rem' }}
                            id={'password2'}
                            name={'password2'}
                            value={user.password2}
                            onBlur={() =>
                                setInitialInteraction(
                                    initialInteraction.password2 !== undefined
                                        ? {
                                              ...initialInteraction,
                                              password2: true
                                          }
                                        : initialInteraction
                                )
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                dispatch({
                                    type: DispatchAction.UPDATE,
                                    payload: { password2: e.target.value }
                                })
                            }
                            error={
                                initialInteraction.password2 &&
                                user.password1 !== user.password2
                            }
                            helperText={
                                initialInteraction.password2 &&
                                user.password1 !== user.password2
                                    ? 'Passwords do not match'
                                    : ''
                            }
                        />
                    </div>
                </Box>
            )}
            <Box>
                <Button
                    sx={{ float: 'right' }}
                    disabled={
                        Object.keys(formErrors).length > 0 ||
                        (user.password1 === undefined ||
                        user.password2 === undefined
                            ? [
                                  user.name,
                                  user.nickname,
                                  user.channel_name,
                                  user.channel_description,
                                  user.tasks,
                                  user.email,
                                  user.title
                              ]
                            : [
                                  user.name,
                                  user.email,
                                  user.nickname,
                                  user.channel_name,
                                  user.channel_description,
                                  user.tasks,
                                  user.title,
                                  user.password1,
                                  user.password2
                              ]
                        ).some((x: string) => x.length === 0) ||
                        user.password1 !== user.password2
                    }
                    size="small"
                    onClick={() => action(user)}
                >
                    {t('views.form.submit')}
                </Button>
            </Box>
        </Box>
    );
}
