import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import { useAuth0User } from '../hooks';
import { UpdateViewer } from './UpdateViewer';
import { UpdateResearcher } from './UpdateResearcher';
import {
    useMediaQuery,
    Container,
    Typography,
    Box,
    Modal,
    Button,
    useTheme
} from '@mui/material';

export function Settings() {
    const { user, isLoading, deleteUser } = useAuth0User();
    const { logout } = useAuth0();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const theme = useTheme();

    const handleDelete = async () => {
        const r = await deleteUser();
        if (r && r.status === 204) {
            logout({ logoutParams: { returnTo: window.location.origin } });
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={
                isMobile
                    ? {
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          margin: 0
                      }
                    : {
                          backgroundColor: theme.palette.background.default
                      }
            }
        >
            <Typography
                variant="h6"
                sx={{
                    color: theme.palette.text.primary,
                    margin: '5rem',
                    textAlign: 'center',
                    textTransform: 'uppercase'
                }}
            >
                {t('views.settings.header')}
            </Typography>
            <div>
                {user?.user_metadata?.role === 'researcher' ? (
                    <UpdateResearcher />
                ) : (
                    <UpdateViewer />
                )}
            </div>
            <Box sx={{ margin: '5rem 0' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsModalOpen(true)}
                >
                    {t('views.form.delete_user_button')}
                </Button>
            </Box>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        width: '400px',
                        height: '180px',
                        padding: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            marginBottom: '1rem',
                            fontSize: '1.2rem'
                        }}
                    >
                        {t('views.form.delete_user_confirmation')}
                    </Typography>
                    <Box sx={{ margin: '1rem' }}>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            onClick={() => setIsModalOpen(false)}
                            sx={{ marginRight: '2rem', width: '125px' }}
                        >
                            {t('views.form.delete_user_confirmation_cancel')}
                        </Button>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            color="secondary"
                            onClick={handleDelete}
                            sx={{ width: '125px' }}
                        >
                            {t('views.form.delete_user_confirmation_affirm')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
}
