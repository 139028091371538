import { createContext } from 'react';

export type AccessTokenContextType = {
    accessToken: string;
    setAccessToken: (a: string) => void;
};

export const AccessTokenContext = createContext<AccessTokenContextType | null>(
    null
);
