import { useState } from 'react';
import {
    Button,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    message: string;
    title: string;
    setMessage: (s: string) => void;
};
export function Dialog({ title, message, setMessage }: Props) {
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();

    const handleClose = () => {
        setMessage('');
        setIsOpen(false);
    };

    return (
        <MuiDialog open={isOpen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('components.dialog.ok_button')}
                </Button>
            </DialogActions>
        </MuiDialog>
    );
}
