import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

export function LoginButton() {
    const { loginWithRedirect } = useAuth0();

    const onClickLogin = () => {
        const browserLanguage = navigator.language || 'EN-en';
        const loginUrl = new URL(window.location.href);
        loginUrl.searchParams.set('ui_locales', browserLanguage);
        loginWithRedirect({ appState: { returnTo: loginUrl.toString() } });
    };

    return (
        <Button
            style={{
                position: 'absolute',
                right: '10px',
                top: '10px'
            }}
            onClick={onClickLogin}
        >
            Login
        </Button>
    );
}
