type Props = {
    fill?: string;
    background?: string;
};
export function LogoLargeDark({ fill, background }: Props) {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            viewBox="30 400 1800 300"
        >
            <path
                fill="#2B2D53"
                opacity="1.000000"
                stroke="none"
                d="
M1790.000000,1081.000000
	C1193.333496,1081.000000 597.166870,1081.000000 1.000240,1081.000000
	C1.000160,721.000183 1.000160,361.000336 1.000080,1.000385
	C640.999695,1.000257 1280.999390,1.000257 1920.999268,1.000128
	C1920.999512,360.999634 1920.999512,720.999268 1920.999756,1080.999512
	C1877.500000,1081.000000 1834.000000,1081.000000 1790.000000,1081.000000
M810.310913,641.857117
	C861.127136,634.661682 896.384888,595.727661 897.585571,546.870300
	C898.522583,508.743347 883.025452,478.802582 850.293335,458.592651
	C828.945618,445.411835 805.275940,441.833801 780.688110,444.084320
	C755.306580,446.407562 733.028076,456.011353 715.347229,474.774353
	C695.155823,496.201538 686.645996,521.931091 688.899536,551.134583
	C691.468994,584.431274 706.983276,610.231079 735.406677,627.753052
	C758.154663,641.776184 783.246887,645.077515 810.310913,641.857117
M63.954655,551.500000
	C63.954655,580.227173 63.954655,608.954346 63.954655,637.746826
	C89.661224,637.746826 114.637543,638.138062 139.589737,637.533508
	C148.264664,637.323364 157.270691,635.771057 165.459213,632.918823
	C220.526627,613.737976 245.723236,552.026001 218.987473,500.265839
	C202.606537,468.552521 175.894638,450.571991 139.658539,449.032593
	C120.039482,448.199097 100.358994,448.809326 80.706207,448.773438
	C75.257179,448.763489 69.808121,448.771942 63.954655,448.771942
	C63.954655,482.963043 63.954655,516.731506 63.954655,551.500000
M393.680847,537.144836
	C373.433105,570.483093 353.185394,603.821350 332.701599,637.548218
	C387.129730,637.548218 440.807587,637.548218 494.526428,637.548218
	C494.526428,623.490417 494.526428,609.759521 494.526428,595.574036
	C468.490021,595.574036 442.777039,595.574036 416.231537,595.574036
	C445.877899,546.433533 475.098358,497.998932 504.636444,449.037872
	C451.977844,449.037872 400.129517,449.037872 348.234253,449.037872
	C348.234253,463.041595 348.234253,476.776947 348.234253,491.091980
	C372.655579,491.091980 396.802399,491.091980 421.591187,491.091980
	C412.112885,506.704834 403.068298,521.603210 393.680847,537.144836
M615.201660,594.552673
	C600.768555,592.597656 589.437683,585.523804 580.594604,574.030823
	C565.759766,554.750488 568.273499,522.223694 586.025208,505.795715
	C607.639954,485.792908 639.729797,486.985992 659.808411,508.587097
	C660.873535,509.733032 661.541016,511.248596 662.393616,512.592102
	C662.879639,512.229492 663.365601,511.866943 663.851562,511.504364
	C663.851562,492.749725 663.891418,473.994781 663.742371,455.241302
	C663.734253,454.225525 662.345886,452.732117 661.260864,452.285919
	C641.249512,444.056671 620.513916,441.283051 599.178162,445.228485
	C552.408752,453.877075 518.881897,494.833557 518.963318,542.850769
	C519.034424,584.785034 545.233887,622.778015 583.420044,636.783752
	C609.807129,646.461914 635.695496,644.057373 661.350159,634.222900
	C662.515808,633.776001 663.690491,631.663696 663.741760,630.285400
	C663.989319,623.630981 663.853394,616.962219 663.853394,610.298584
	C663.853455,598.724854 663.853394,587.151123 663.853394,574.327393
	C650.178162,588.370850 635.065491,596.334717 615.201660,594.552673
M260.353149,500.499786
	C260.353149,546.225525 260.353149,591.951233 260.353149,637.642822
	C277.342316,637.642822 293.451202,637.642822 309.782715,637.642822
	C309.782715,574.649292 309.782715,511.885681 309.782715,449.147705
	C293.321320,449.147705 276.933929,449.147705 260.353149,449.147705
	C260.353149,466.184601 260.353149,482.842163 260.353149,500.499786
M1762.500732,519.819702
	C1756.369995,519.819702 1750.239136,519.819702 1743.923218,519.819702
	C1743.923218,510.771210 1743.923218,502.097198 1743.923218,493.010498
	C1763.459229,493.010498 1782.540649,493.010498 1801.656250,493.010498
	C1801.656250,477.467773 1801.656250,462.347382 1801.656250,447.050903
	C1764.225464,447.050903 1726.969971,447.050903 1689.621338,447.050903
	C1689.621338,511.476135 1689.621338,575.669617 1689.621338,639.946594
	C1727.137695,639.946594 1764.368164,639.946594 1801.686523,639.946594
	C1801.686523,624.516907 1801.686523,609.384460 1801.686523,593.699585
	C1782.330078,593.699585 1763.112305,593.699585 1743.857910,593.699585
	C1743.857910,584.245056 1743.857910,575.347534 1743.857910,566.116760
	C1762.188354,566.116760 1780.130371,566.116760 1798.185547,566.116760
	C1798.185547,550.559570 1798.185547,535.347046 1798.185547,519.819702
	C1786.437134,519.819702 1774.968994,519.819702 1762.500732,519.819702
M1235.450684,620.499512
	C1235.450684,611.727966 1235.450684,602.956421 1235.450684,593.761108
	C1215.885010,593.761108 1196.804932,593.761108 1177.452393,593.761108
	C1177.452393,584.460815 1177.452393,575.591187 1177.452393,566.220032
	C1195.795532,566.220032 1213.847778,566.220032 1231.875610,566.220032
	C1231.875610,550.481812 1231.875610,535.247314 1231.875610,519.531128
	C1213.543335,519.531128 1195.485840,519.531128 1177.418823,519.531128
	C1177.418823,510.623077 1177.418823,502.202911 1177.418823,493.179993
	C1196.882812,493.179993 1216.091553,493.179993 1235.245361,493.179993
	C1235.245361,477.463074 1235.245361,462.353973 1235.245361,447.074158
	C1197.805786,447.074158 1160.573120,447.074158 1123.386963,447.074158
	C1123.386963,511.525299 1123.386963,575.732361 1123.386963,640.030396
	C1160.783569,640.030396 1197.881104,640.030396 1235.450684,640.030396
	C1235.450684,633.726013 1235.450684,627.612488 1235.450684,620.499512
M1325.601562,582.949890
	C1337.226685,600.986633 1348.904175,618.990112 1360.414551,637.099731
	C1361.960938,639.532715 1363.629639,640.310303 1366.421387,640.293640
	C1385.753906,640.178162 1405.087402,640.232666 1424.420654,640.232605
	C1426.022461,640.232544 1427.624146,640.232605 1429.997314,640.232605
	C1409.754761,613.816956 1390.080933,588.143494 1370.198730,562.197876
	C1372.360596,561.359192 1373.891357,560.744873 1375.436523,560.169006
	C1392.876221,553.668884 1403.911743,541.359558 1407.565186,523.154053
	C1408.989624,516.057068 1409.464111,508.509369 1408.820679,501.305450
	C1406.764160,478.279297 1395.724365,461.174164 1373.874268,451.993744
	C1364.519531,448.063202 1354.671021,446.799591 1344.584595,446.818085
	C1319.584839,446.863892 1294.584961,446.826416 1269.585205,446.842377
	C1268.323242,446.843170 1267.061279,447.031830 1265.956421,447.120422
	C1265.956421,511.624542 1265.956421,575.741638 1265.956421,640.012207
	C1283.938965,640.012207 1301.706055,640.012207 1320.078125,640.012207
	C1320.078125,618.482910 1320.078125,597.167847 1320.078125,574.500671
	C1322.302979,577.947754 1323.728882,580.156921 1325.601562,582.949890
M1564.649780,557.738281
	C1569.986694,559.573059 1575.383911,561.251770 1580.645142,563.282410
	C1587.139160,565.788818 1593.864136,567.982117 1599.873047,571.395691
	C1610.130127,577.222412 1609.510376,590.908752 1598.894409,596.223999
	C1595.594727,597.876160 1591.605347,598.662903 1587.879639,598.860107
	C1574.250244,599.581665 1562.612915,594.131897 1551.922485,586.270691
	C1548.653076,583.866577 1545.563965,581.217651 1542.111938,578.456665
	C1534.192139,593.357483 1526.497192,607.835388 1518.301392,623.255554
	C1526.889526,627.812134 1534.898071,632.997070 1543.585205,636.498291
	C1571.831543,647.882690 1600.572876,650.045471 1628.837158,636.961365
	C1653.005127,625.773499 1663.393066,605.309082 1663.746338,579.408203
	C1664.094604,553.880188 1652.159302,535.912354 1628.853149,525.742188
	C1620.969238,522.301880 1612.597412,519.996826 1604.568726,516.865173
	C1598.887085,514.648926 1592.995117,512.546326 1587.944092,509.248657
	C1581.965576,505.345367 1581.823486,497.064331 1587.288208,492.521545
	C1589.854858,490.387848 1593.254639,488.811554 1596.525024,488.053772
	C1605.739746,485.918457 1614.355103,488.445770 1622.391846,492.903717
	C1626.667847,495.275696 1630.626221,498.220703 1634.983887,501.074738
	C1642.250854,486.807312 1649.452393,472.668457 1656.814941,458.213654
	C1634.913940,446.423798 1612.142822,440.129425 1587.538452,441.894257
	C1541.739868,445.179413 1521.462280,482.059143 1528.475098,519.696838
	C1532.164062,539.494934 1545.775513,550.798950 1564.649780,557.738281
M1012.648315,543.025024
	C1010.871765,547.327332 1009.095276,551.629700 1006.937805,556.854736
	C1005.888733,554.293091 1005.316223,552.932556 1004.771912,551.560852
	C991.486450,518.078674 978.144470,484.618713 965.012207,451.076630
	C963.687744,447.693848 962.042725,446.703979 958.525269,446.745331
	C942.033752,446.939331 925.538696,446.825653 909.044861,446.842010
	C907.653259,446.843384 906.261780,446.993713 904.820679,447.077698
	C904.935974,447.751068 904.933777,447.928040 904.996582,448.077667
	C931.478271,511.130310 957.944702,574.189392 984.575928,637.178894
	C985.177063,638.600647 987.630249,640.061829 989.250916,640.097900
	C1000.574402,640.349915 1011.910522,640.051453 1023.232666,640.331848
	C1026.982178,640.424744 1028.671143,639.270569 1030.128906,635.791809
	C1051.876709,583.892883 1073.798340,532.066833 1095.666992,480.218506
	C1100.275146,469.293030 1104.834717,458.347076 1109.659180,446.831512
	C1090.979370,446.831512 1073.021362,446.926544 1055.066284,446.743500
	C1051.849976,446.710724 1050.451050,447.774170 1049.311279,450.664795
	C1037.255493,481.239899 1025.066528,511.762390 1012.648315,543.025024
M1493.100464,633.591064
	C1503.085083,619.693420 1502.110352,602.586670 1490.686279,591.220154
	C1479.555298,580.145081 1461.563965,578.790100 1449.116455,588.352661
	C1438.615601,596.419739 1434.626343,607.386536 1437.151489,620.187744
	C1439.564209,632.419006 1447.124023,640.777039 1459.279053,644.159790
	C1472.352783,647.798035 1483.572388,644.286255 1493.100464,633.591064
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M809.870972,641.899780
	C783.246887,645.077515 758.154663,641.776184 735.406677,627.753052
	C706.983276,610.231079 691.468994,584.431274 688.899536,551.134583
	C686.645996,521.931091 695.155823,496.201538 715.347229,474.774353
	C733.028076,456.011353 755.306580,446.407562 780.688110,444.084320
	C805.275940,441.833801 828.945618,445.411835 850.293335,458.592651
	C883.025452,478.802582 898.522583,508.743347 897.585571,546.870300
	C896.384888,595.727661 861.127136,634.661682 809.870972,641.899780
M757.017395,505.494812
	C755.121216,507.593567 753.149109,509.629059 751.340698,511.800873
	C734.699646,531.785767 737.297546,563.814819 756.918091,580.903931
	C780.693970,601.612122 813.457214,599.243774 834.247986,575.313843
	C854.400269,552.118896 847.371948,513.657593 820.248169,499.100983
	C798.074951,487.201141 777.146667,489.208649 757.017395,505.494812
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M63.954655,551.000000
	C63.954655,516.731506 63.954655,482.963043 63.954655,448.771942
	C69.808121,448.771942 75.257179,448.763489 80.706207,448.773438
	C100.358994,448.809326 120.039482,448.199097 139.658539,449.032593
	C175.894638,450.571991 202.606537,468.552521 218.987473,500.265839
	C245.723236,552.026001 220.526627,613.737976 165.459213,632.918823
	C157.270691,635.771057 148.264664,637.323364 139.589737,637.533508
	C114.637543,638.138062 89.661224,637.746826 63.954655,637.746826
	C63.954655,608.954346 63.954655,580.227173 63.954655,551.000000
M156.220078,586.840942
	C156.997543,586.214172 157.732941,585.524780 158.558609,584.969727
	C178.325073,571.682007 184.999298,537.489258 169.395554,513.406006
	C157.645477,495.270599 137.547531,488.553406 113.946106,491.397461
	C113.946106,526.098572 113.946106,560.778992 113.946106,595.639587
	C128.811493,596.264587 142.912338,595.307312 156.220078,586.840942
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M393.852295,536.823242
	C403.068298,521.603210 412.112885,506.704834 421.591187,491.091980
	C396.802399,491.091980 372.655579,491.091980 348.234253,491.091980
	C348.234253,476.776947 348.234253,463.041595 348.234253,449.037872
	C400.129517,449.037872 451.977844,449.037872 504.636444,449.037872
	C475.098358,497.998932 445.877899,546.433533 416.231537,595.574036
	C442.777039,595.574036 468.490021,595.574036 494.526428,595.574036
	C494.526428,609.759521 494.526428,623.490417 494.526428,637.548218
	C440.807587,637.548218 387.129730,637.548218 332.701599,637.548218
	C353.185394,603.821350 373.433105,570.483093 393.852295,536.823242
z"
            />
            <path
                fill="#FDFDFD"
                opacity="1.000000"
                stroke="none"
                d="
M615.637695,594.633057
	C635.065491,596.334717 650.178162,588.370850 663.853394,574.327393
	C663.853394,587.151123 663.853455,598.724854 663.853394,610.298584
	C663.853394,616.962219 663.989319,623.630981 663.741760,630.285400
	C663.690491,631.663696 662.515808,633.776001 661.350159,634.222900
	C635.695496,644.057373 609.807129,646.461914 583.420044,636.783752
	C545.233887,622.778015 519.034424,584.785034 518.963318,542.850769
	C518.881897,494.833557 552.408752,453.877075 599.178162,445.228485
	C620.513916,441.283051 641.249512,444.056671 661.260864,452.285919
	C662.345886,452.732117 663.734253,454.225525 663.742371,455.241302
	C663.891418,473.994781 663.851562,492.749725 663.851562,511.504364
	C663.365601,511.866943 662.879639,512.229492 662.393616,512.592102
	C661.541016,511.248596 660.873535,509.733032 659.808411,508.587097
	C639.729797,486.985992 607.639954,485.792908 586.025208,505.795715
	C568.273499,522.223694 565.759766,554.750488 580.594604,574.030823
	C589.437683,585.523804 600.768555,592.597656 615.637695,594.633057
z"
            />
            <path
                fill="#FDFDFD"
                opacity="1.000000"
                stroke="none"
                d="
M260.353149,499.999756
	C260.353149,482.842163 260.353149,466.184601 260.353149,449.147705
	C276.933929,449.147705 293.321320,449.147705 309.782715,449.147705
	C309.782715,511.885681 309.782715,574.649292 309.782715,637.642822
	C293.451202,637.642822 277.342316,637.642822 260.353149,637.642822
	C260.353149,591.951233 260.353149,546.225525 260.353149,499.999756
z"
            />
            <path
                fill="#EEEEEE"
                opacity="1.000000"
                stroke="none"
                d="
M1763.000854,519.819702
	C1774.968994,519.819702 1786.437134,519.819702 1798.185547,519.819702
	C1798.185547,535.347046 1798.185547,550.559570 1798.185547,566.116760
	C1780.130371,566.116760 1762.188354,566.116760 1743.857910,566.116760
	C1743.857910,575.347534 1743.857910,584.245056 1743.857910,593.699585
	C1763.112305,593.699585 1782.330078,593.699585 1801.686523,593.699585
	C1801.686523,609.384460 1801.686523,624.516907 1801.686523,639.946594
	C1764.368164,639.946594 1727.137695,639.946594 1689.621338,639.946594
	C1689.621338,575.669617 1689.621338,511.476135 1689.621338,447.050903
	C1726.969971,447.050903 1764.225464,447.050903 1801.656250,447.050903
	C1801.656250,462.347382 1801.656250,477.467773 1801.656250,493.010498
	C1782.540649,493.010498 1763.459229,493.010498 1743.923218,493.010498
	C1743.923218,502.097198 1743.923218,510.771210 1743.923218,519.819702
	C1750.239136,519.819702 1756.369995,519.819702 1763.000854,519.819702
M1781.498779,635.155823
	C1786.468628,635.155823 1791.438599,635.155823 1796.571045,635.155823
	C1796.571045,622.915405 1796.571045,611.134033 1796.571045,598.830933
	C1777.245361,598.830933 1758.011597,598.830933 1738.639648,598.830933
	C1738.639648,586.068359 1738.639648,573.828430 1738.639648,561.073486
	C1756.987915,561.073486 1775.048462,561.073486 1793.114990,561.073486
	C1793.114990,548.805115 1793.114990,537.055420 1793.114990,524.784058
	C1774.841553,524.784058 1756.793213,524.784058 1738.659668,524.784058
	C1738.659668,512.351135 1738.659668,500.444733 1738.659668,487.978577
	C1758.169556,487.978577 1777.370117,487.978577 1796.441528,487.978577
	C1796.441528,475.676178 1796.441528,463.939209 1796.441528,452.124146
	C1762.380981,452.124146 1728.619507,452.124146 1694.787964,452.124146
	C1694.787964,513.179749 1694.787964,574.013000 1694.787964,635.155823
	C1723.577393,635.155823 1752.037964,635.155823 1781.498779,635.155823
z"
            />
            <path
                fill="#F3F3F3"
                opacity="1.000000"
                stroke="none"
                d="
M1235.450684,620.999268
	C1235.450684,627.612488 1235.450684,633.726013 1235.450684,640.030396
	C1197.881104,640.030396 1160.783569,640.030396 1123.386963,640.030396
	C1123.386963,575.732361 1123.386963,511.525299 1123.386963,447.074158
	C1160.573120,447.074158 1197.805786,447.074158 1235.245361,447.074158
	C1235.245361,462.353973 1235.245361,477.463074 1235.245361,493.179993
	C1216.091553,493.179993 1196.882812,493.179993 1177.418823,493.179993
	C1177.418823,502.202911 1177.418823,510.623077 1177.418823,519.531128
	C1195.485840,519.531128 1213.543335,519.531128 1231.875610,519.531128
	C1231.875610,535.247314 1231.875610,550.481812 1231.875610,566.220032
	C1213.847778,566.220032 1195.795532,566.220032 1177.452393,566.220032
	C1177.452393,575.591187 1177.452393,584.460815 1177.452393,593.761108
	C1196.804932,593.761108 1215.885010,593.761108 1235.450684,593.761108
	C1235.450684,602.956421 1235.450684,611.727966 1235.450684,620.999268
M1128.150391,603.499939
	C1128.150391,613.941284 1128.150391,624.382629 1128.150391,634.772461
	C1162.541260,634.772461 1196.391479,634.772461 1230.041016,634.772461
	C1230.041016,622.638367 1230.041016,610.898499 1230.041016,598.751465
	C1210.647949,598.751465 1191.571289,598.751465 1172.353149,598.751465
	C1172.353149,586.101013 1172.353149,573.867981 1172.353149,561.141052
	C1190.660400,561.141052 1208.713135,561.141052 1226.742554,561.141052
	C1226.742554,548.767822 1226.742554,536.893250 1226.742554,524.724792
	C1208.459351,524.724792 1190.523071,524.724792 1172.487793,524.724792
	C1172.487793,512.404724 1172.487793,500.388916 1172.487793,487.966492
	C1191.966309,487.966492 1211.050293,487.966492 1230.127563,487.966492
	C1230.127563,475.800781 1230.127563,464.049744 1230.127563,452.269409
	C1196.044434,452.269409 1162.196411,452.269409 1128.150391,452.269409
	C1128.150391,502.595490 1128.150391,552.547668 1128.150391,603.499939
z"
            />
            <path
                fill="#F2F2F2"
                opacity="1.000000"
                stroke="none"
                d="
M1325.378174,582.657959
	C1323.728882,580.156921 1322.302979,577.947754 1320.078125,574.500671
	C1320.078125,597.167847 1320.078125,618.482910 1320.078125,640.012207
	C1301.706055,640.012207 1283.938965,640.012207 1265.956421,640.012207
	C1265.956421,575.741638 1265.956421,511.624542 1265.956421,447.120422
	C1267.061279,447.031830 1268.323242,446.843170 1269.585205,446.842377
	C1294.584961,446.826416 1319.584839,446.863892 1344.584595,446.818085
	C1354.671021,446.799591 1364.519531,448.063202 1373.874268,451.993744
	C1395.724365,461.174164 1406.764160,478.279297 1408.820679,501.305450
	C1409.464111,508.509369 1408.989624,516.057068 1407.565186,523.154053
	C1403.911743,541.359558 1392.876221,553.668884 1375.436523,560.169006
	C1373.891357,560.744873 1372.360596,561.359192 1370.198730,562.197876
	C1390.080933,588.143494 1409.754761,613.816956 1429.997314,640.232605
	C1427.624146,640.232605 1426.022461,640.232544 1424.420654,640.232605
	C1405.087402,640.232666 1385.753906,640.178162 1366.421387,640.293640
	C1363.629639,640.310303 1361.960938,639.532715 1360.414551,637.099731
	C1348.904175,618.990112 1337.226685,600.986633 1325.378174,582.657959
M1388.978027,546.517883
	C1402.575317,534.097412 1405.388062,517.949768 1403.755737,500.825989
	C1401.852295,480.858978 1392.388428,465.182770 1373.294922,457.498474
	C1365.016602,454.166809 1355.624390,452.433258 1346.673218,452.168732
	C1322.374268,451.450562 1298.040405,451.901154 1273.720459,451.912720
	C1272.786987,451.913147 1271.853394,452.164948 1271.059692,452.279449
	C1271.059692,513.314880 1271.059692,574.051025 1271.059692,634.891174
	C1285.713257,634.891174 1300.121704,634.891174 1314.878174,634.891174
	C1314.878174,609.176941 1314.878174,583.734680 1314.878174,557.308838
	C1316.349121,559.480896 1317.284424,560.806641 1318.163696,562.168579
	C1333.133789,585.356445 1348.140259,608.521118 1362.999512,631.779846
	C1364.633545,634.337402 1366.342407,635.272888 1369.372681,635.240540
	C1384.695801,635.076660 1400.021729,635.173523 1415.346680,635.149597
	C1416.541016,635.147705 1417.735107,634.921387 1419.419678,634.749207
	C1399.923096,609.315369 1380.829590,584.407288 1362.467529,560.453430
	C1371.243530,555.900513 1379.839111,551.441223 1388.978027,546.517883
z"
            />
            <path
                fill="#F2F2F2"
                opacity="1.000000"
                stroke="none"
                d="
M1564.307861,557.555298
	C1545.775513,550.798950 1532.164062,539.494934 1528.475098,519.696838
	C1521.462280,482.059143 1541.739868,445.179413 1587.538452,441.894257
	C1612.142822,440.129425 1634.913940,446.423798 1656.814941,458.213654
	C1649.452393,472.668457 1642.250854,486.807312 1634.983887,501.074738
	C1630.626221,498.220703 1626.667847,495.275696 1622.391846,492.903717
	C1614.355103,488.445770 1605.739746,485.918457 1596.525024,488.053772
	C1593.254639,488.811554 1589.854858,490.387848 1587.288208,492.521545
	C1581.823486,497.064331 1581.965576,505.345367 1587.944092,509.248657
	C1592.995117,512.546326 1598.887085,514.648926 1604.568726,516.865173
	C1612.597412,519.996826 1620.969238,522.301880 1628.853149,525.742188
	C1652.159302,535.912354 1664.094604,553.880188 1663.746338,579.408203
	C1663.393066,605.309082 1653.005127,625.773499 1628.837158,636.961365
	C1600.572876,650.045471 1571.831543,647.882690 1543.585205,636.498291
	C1534.898071,632.997070 1526.889526,627.812134 1518.301392,623.255554
	C1526.497192,607.835388 1534.192139,593.357483 1542.111938,578.456665
	C1545.563965,581.217651 1548.653076,583.866577 1551.922485,586.270691
	C1562.612915,594.131897 1574.250244,599.581665 1587.879639,598.860107
	C1591.605347,598.662903 1595.594727,597.876160 1598.894409,596.223999
	C1609.510376,590.908752 1610.130127,577.222412 1599.873047,571.395691
	C1593.864136,567.982117 1587.139160,565.788818 1580.645142,563.282410
	C1575.383911,561.251770 1569.986694,559.573059 1564.307861,557.555298
M1537.833496,597.376404
	C1533.703491,605.113464 1529.573608,612.850525 1525.357422,620.749329
	C1526.343018,621.511536 1527.070435,622.169495 1527.885498,622.690491
	C1550.331299,637.038086 1574.887451,642.742432 1601.270264,639.754150
	C1635.514893,635.875366 1655.768555,616.123657 1658.422974,583.804626
	C1660.259888,561.441345 1652.418457,543.516663 1631.677734,532.905640
	C1624.767822,529.370544 1617.362671,526.775879 1610.104004,523.957275
	C1603.153076,521.258118 1595.913696,519.233459 1589.141113,516.162292
	C1582.366821,513.090393 1577.792847,507.878754 1578.513428,499.726746
	C1579.269409,491.174377 1584.598633,486.394836 1592.307129,483.829224
	C1601.297607,480.836823 1610.125977,482.077667 1618.624146,485.576538
	C1623.618042,487.632599 1628.291748,490.465607 1633.373169,493.085175
	C1638.913330,482.197296 1644.435791,471.344208 1650.053223,460.304474
	C1630.438477,450.766418 1610.385132,445.644379 1588.901855,446.847870
	C1556.818848,448.645172 1536.073730,468.125061 1532.450928,499.960449
	C1529.646606,524.604492 1540.638306,542.950928 1563.702271,551.900085
	C1569.427002,554.121338 1575.344482,555.847412 1581.060059,558.090210
	C1588.466064,560.996399 1596.188354,563.454529 1603.004028,567.427246
	C1614.716431,574.254028 1615.757202,589.847717 1604.913940,598.044006
	C1600.567017,601.329773 1594.413330,603.253296 1588.894165,603.763123
	C1573.715698,605.164917 1560.661499,599.001099 1548.696655,590.202698
	C1547.039062,588.983765 1545.392212,587.750061 1543.633179,586.443481
	C1541.580688,590.254578 1539.847290,593.473328 1537.833496,597.376404
z"
            />
            <path
                fill="#F3F3F3"
                opacity="1.000000"
                stroke="none"
                d="
M1012.781250,542.662231
	C1025.066528,511.762390 1037.255493,481.239899 1049.311279,450.664795
	C1050.451050,447.774170 1051.849976,446.710724 1055.066284,446.743500
	C1073.021362,446.926544 1090.979370,446.831512 1109.659180,446.831512
	C1104.834717,458.347076 1100.275146,469.293030 1095.666992,480.218506
	C1073.798340,532.066833 1051.876709,583.892883 1030.128906,635.791809
	C1028.671143,639.270569 1026.982178,640.424744 1023.232666,640.331848
	C1011.910522,640.051453 1000.574402,640.349915 989.250916,640.097900
	C987.630249,640.061829 985.177063,638.600647 984.575928,637.178894
	C957.944702,574.189392 931.478271,511.130310 904.996582,448.077667
	C904.933777,447.928040 904.935974,447.751068 904.820679,447.077698
	C906.261780,446.993713 907.653259,446.843384 909.044861,446.842010
	C925.538696,446.825653 942.033752,446.939331 958.525269,446.745331
	C962.042725,446.703979 963.687744,447.693848 965.012207,451.076630
	C978.144470,484.618713 991.486450,518.078674 1004.771912,551.560852
	C1005.316223,552.932556 1005.888733,554.293091 1006.937805,556.854736
	C1009.095276,551.629700 1010.871765,547.327332 1012.781250,542.662231
M1034.902588,611.294861
	C1057.231934,558.290344 1079.561157,505.285828 1102.054077,451.892914
	C1087.036011,451.892914 1072.708740,452.002594 1058.385498,451.809021
	C1055.155273,451.765381 1053.908936,452.897308 1052.757812,455.809174
	C1038.291870,492.401245 1023.662781,528.928833 1009.065308,565.468933
	C1008.486511,566.917725 1007.807129,568.326294 1006.818970,570.556152
	C990.913147,530.454285 975.379395,491.290619 959.823853,452.072021
	C944.072083,452.072021 928.466553,452.072021 912.121826,452.072021
	C937.874390,513.335327 963.451904,574.182129 989.022217,635.011780
	C1001.118530,635.011780 1012.923279,635.011780 1024.827515,635.011780
	C1028.117554,627.278625 1031.367065,619.641052 1034.902588,611.294861
z"
            />
            <path
                fill="#F3F3F3"
                opacity="1.000000"
                stroke="none"
                d="
M1492.884399,633.875732
	C1483.572388,644.286255 1472.352783,647.798035 1459.279053,644.159790
	C1447.124023,640.777039 1439.564209,632.419006 1437.151489,620.187744
	C1434.626343,607.386536 1438.615601,596.419739 1449.116455,588.352661
	C1461.563965,578.790100 1479.555298,580.145081 1490.686279,591.220154
	C1502.110352,602.586670 1503.085083,619.693420 1492.884399,633.875732
M1489.984375,629.348999
	C1490.807861,627.913574 1491.746826,626.531311 1492.437134,625.034424
	C1498.969482,610.869263 1492.291504,594.420654 1477.793457,588.875610
	C1463.025024,583.227173 1446.925171,591.197937 1442.517944,606.339783
	C1439.045776,618.268860 1444.303345,631.119324 1455.104492,637.103577
	C1466.798340,643.582458 1480.165283,640.806763 1489.984375,629.348999
z"
            />
            <path
                fill="#010101"
                opacity="0.000000"
                stroke="none"
                d="
M757.292053,505.269958
	C777.146667,489.208649 798.074951,487.201141 820.248169,499.100983
	C847.371948,513.657593 854.400269,552.118896 834.247986,575.313843
	C813.457214,599.243774 780.693970,601.612122 756.918091,580.903931
	C737.297546,563.814819 734.699646,531.785767 751.340698,511.800873
	C753.149109,509.629059 755.121216,507.593567 757.292053,505.269958
z"
            />
            <path
                fill="#010101"
                opacity="0.000000"
                stroke="none"
                d="
M155.893921,587.002808
	C142.912338,595.307312 128.811493,596.264587 113.946106,595.639587
	C113.946106,560.778992 113.946106,526.098572 113.946106,491.397461
	C137.547531,488.553406 157.645477,495.270599 169.395554,513.406006
	C184.999298,537.489258 178.325073,571.682007 158.558609,584.969727
	C157.732941,585.524780 156.997543,586.214172 155.893921,587.002808
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M1780.998657,635.155823
	C1752.037964,635.155823 1723.577393,635.155823 1694.787964,635.155823
	C1694.787964,574.013000 1694.787964,513.179749 1694.787964,452.124146
	C1728.619507,452.124146 1762.380981,452.124146 1796.441528,452.124146
	C1796.441528,463.939209 1796.441528,475.676178 1796.441528,487.978577
	C1777.370117,487.978577 1758.169556,487.978577 1738.659668,487.978577
	C1738.659668,500.444733 1738.659668,512.351135 1738.659668,524.784058
	C1756.793213,524.784058 1774.841553,524.784058 1793.114990,524.784058
	C1793.114990,537.055420 1793.114990,548.805115 1793.114990,561.073486
	C1775.048462,561.073486 1756.987915,561.073486 1738.639648,561.073486
	C1738.639648,573.828430 1738.639648,586.068359 1738.639648,598.830933
	C1758.011597,598.830933 1777.245361,598.830933 1796.571045,598.830933
	C1796.571045,611.134033 1796.571045,622.915405 1796.571045,635.155823
	C1791.438599,635.155823 1786.468628,635.155823 1780.998657,635.155823
z"
            />
            <path
                fill="#010101"
                opacity="0.000000"
                stroke="none"
                d="
M1128.150391,602.999878
	C1128.150391,552.547668 1128.150391,502.595490 1128.150391,452.269409
	C1162.196411,452.269409 1196.044434,452.269409 1230.127563,452.269409
	C1230.127563,464.049744 1230.127563,475.800781 1230.127563,487.966492
	C1211.050293,487.966492 1191.966309,487.966492 1172.487793,487.966492
	C1172.487793,500.388916 1172.487793,512.404724 1172.487793,524.724792
	C1190.523071,524.724792 1208.459351,524.724792 1226.742554,524.724792
	C1226.742554,536.893250 1226.742554,548.767822 1226.742554,561.141052
	C1208.713135,561.141052 1190.660400,561.141052 1172.353149,561.141052
	C1172.353149,573.867981 1172.353149,586.101013 1172.353149,598.751465
	C1191.571289,598.751465 1210.647949,598.751465 1230.041016,598.751465
	C1230.041016,610.898499 1230.041016,622.638367 1230.041016,634.772461
	C1196.391479,634.772461 1162.541260,634.772461 1128.150391,634.772461
	C1128.150391,624.382629 1128.150391,613.941284 1128.150391,602.999878
z"
            />
            <path
                fill="#010101"
                opacity="0.000000"
                stroke="none"
                d="
M1388.706299,546.749878
	C1379.839111,551.441223 1371.243530,555.900513 1362.467529,560.453430
	C1380.829590,584.407288 1399.923096,609.315369 1419.419678,634.749207
	C1417.735107,634.921387 1416.541016,635.147705 1415.346680,635.149597
	C1400.021729,635.173523 1384.695801,635.076660 1369.372681,635.240540
	C1366.342407,635.272888 1364.633545,634.337402 1362.999512,631.779846
	C1348.140259,608.521118 1333.133789,585.356445 1318.163696,562.168579
	C1317.284424,560.806641 1316.349121,559.480896 1314.878174,557.308838
	C1314.878174,583.734680 1314.878174,609.176941 1314.878174,634.891174
	C1300.121704,634.891174 1285.713257,634.891174 1271.059692,634.891174
	C1271.059692,574.051025 1271.059692,513.314880 1271.059692,452.279449
	C1271.853394,452.164948 1272.786987,451.913147 1273.720459,451.912720
	C1298.040405,451.901154 1322.374268,451.450562 1346.673218,452.168732
	C1355.624390,452.433258 1365.016602,454.166809 1373.294922,457.498474
	C1392.388428,465.182770 1401.852295,480.858978 1403.755737,500.825989
	C1405.388062,517.949768 1402.575317,534.097412 1388.706299,546.749878
M1352.968750,493.561798
	C1342.110474,482.835388 1328.436523,484.695831 1314.981445,484.595154
	C1314.981445,501.988251 1314.981445,518.887207 1314.981445,535.870789
	C1320.861816,535.870789 1326.546143,536.292603 1332.144653,535.779297
	C1342.464600,534.833130 1352.052368,531.707397 1356.631836,521.348999
	C1360.805176,511.908752 1359.998779,502.540924 1352.968750,493.561798
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M1537.973633,597.034180
	C1539.847290,593.473328 1541.580688,590.254578 1543.633179,586.443481
	C1545.392212,587.750061 1547.039062,588.983765 1548.696655,590.202698
	C1560.661499,599.001099 1573.715698,605.164917 1588.894165,603.763123
	C1594.413330,603.253296 1600.567017,601.329773 1604.913940,598.044006
	C1615.757202,589.847717 1614.716431,574.254028 1603.004028,567.427246
	C1596.188354,563.454529 1588.466064,560.996399 1581.060059,558.090210
	C1575.344482,555.847412 1569.427002,554.121338 1563.702271,551.900085
	C1540.638306,542.950928 1529.646606,524.604492 1532.450928,499.960449
	C1536.073730,468.125061 1556.818848,448.645172 1588.901855,446.847870
	C1610.385132,445.644379 1630.438477,450.766418 1650.053223,460.304474
	C1644.435791,471.344208 1638.913330,482.197296 1633.373169,493.085175
	C1628.291748,490.465607 1623.618042,487.632599 1618.624146,485.576538
	C1610.125977,482.077667 1601.297607,480.836823 1592.307129,483.829224
	C1584.598633,486.394836 1579.269409,491.174377 1578.513428,499.726746
	C1577.792847,507.878754 1582.366821,513.090393 1589.141113,516.162292
	C1595.913696,519.233459 1603.153076,521.258118 1610.104004,523.957275
	C1617.362671,526.775879 1624.767822,529.370544 1631.677734,532.905640
	C1652.418457,543.516663 1660.259888,561.441345 1658.422974,583.804626
	C1655.768555,616.123657 1635.514893,635.875366 1601.270264,639.754150
	C1574.887451,642.742432 1550.331299,637.038086 1527.885498,622.690491
	C1527.070435,622.169495 1526.343018,621.511536 1525.357422,620.749329
	C1529.573608,612.850525 1533.703491,605.113464 1537.973633,597.034180
z"
            />
            <path
                fill="#010101"
                opacity="0.000000"
                stroke="none"
                d="
M1034.759521,611.649170
	C1031.367065,619.641052 1028.117554,627.278625 1024.827515,635.011780
	C1012.923279,635.011780 1001.118530,635.011780 989.022217,635.011780
	C963.451904,574.182129 937.874390,513.335327 912.121826,452.072021
	C928.466553,452.072021 944.072083,452.072021 959.823853,452.072021
	C975.379395,491.290619 990.913147,530.454285 1006.818970,570.556152
	C1007.807129,568.326294 1008.486511,566.917725 1009.065308,565.468933
	C1023.662781,528.928833 1038.291870,492.401245 1052.757812,455.809174
	C1053.908936,452.897308 1055.155273,451.765381 1058.385498,451.809021
	C1072.708740,452.002594 1087.036011,451.892914 1102.054077,451.892914
	C1079.561157,505.285828 1057.231934,558.290344 1034.759521,611.649170
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M1489.774414,629.648376
	C1480.165283,640.806763 1466.798340,643.582458 1455.104492,637.103577
	C1444.303345,631.119324 1439.045776,618.268860 1442.517944,606.339783
	C1446.925171,591.197937 1463.025024,583.227173 1477.793457,588.875610
	C1492.291504,594.420654 1498.969482,610.869263 1492.437134,625.034424
	C1491.746826,626.531311 1490.807861,627.913574 1489.774414,629.648376
z"
            />
            <path
                fill="#F3F3F3"
                opacity="1.000000"
                stroke="none"
                d="
M1353.216187,493.827240
	C1359.998779,502.540924 1360.805176,511.908752 1356.631836,521.348999
	C1352.052368,531.707397 1342.464600,534.833130 1332.144653,535.779297
	C1326.546143,536.292603 1320.861816,535.870789 1314.981445,535.870789
	C1314.981445,518.887207 1314.981445,501.988251 1314.981445,484.595154
	C1328.436523,484.695831 1342.110474,482.835388 1353.216187,493.827240
M1353.433960,505.474152
	C1350.102539,493.116669 1335.590454,486.534393 1320.032349,490.327972
	C1320.032349,503.754883 1320.032349,517.181824 1320.032349,531.297729
	C1325.378784,530.910034 1330.536255,530.929260 1335.552368,530.096802
	C1349.230103,527.826904 1354.884155,520.181458 1353.433960,505.474152
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M1353.539307,505.876770
	C1354.884155,520.181458 1349.230103,527.826904 1335.552368,530.096802
	C1330.536255,530.929260 1325.378784,530.910034 1320.032349,531.297729
	C1320.032349,517.181824 1320.032349,503.754883 1320.035278,490.327362
	C1335.590454,486.534393 1350.102539,493.116669 1353.539307,505.876770
z"
            />
        </svg>
    );
}
