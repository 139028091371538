import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import {
    Settings,
    AboutUs,
    ViewerContentRow,
    ViewerChannel,
    ViewerVideo,
    ResearcherContent,
    ResearcherStatistics,
    ResearcherEarnings,
    ErrorPage,
    Menu
} from '../views';
import {
    LogoSmallDarkCornor,
    LogoSmallLightCornor,
    MenuButton
} from '../components';
import { AccessTokenContext, UserContext } from '../contexts';
import { User } from '../typings';
import { Box, useMediaQuery, useTheme } from '@mui/material';

function shouldShowMenu(user: User) {
    // if (!user.user_metadata?.has_inited) {
    //     return false;
    // }
    // if (
    //     user.user_metadata?.has_inited &&
    //     user.logins_count > 1 &&
    //     !user.email_verified
    // ) {
    //     return false;
    // }
    return true;
}

type Props = {
    user: User;
    setUser: (u: User) => void;
    accessToken: string;
    setAccessToken: (x: string) => void;
    isDarkMode: boolean;
    setIsDarkMode: (x: boolean) => void;
};
export function ProtectedRoutes({
    user,
    setUser,
    accessToken,
    setAccessToken,
    isDarkMode,
    setIsDarkMode
}: Props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const theme = useTheme();

    const isSmallScreen = useMediaQuery('(max-width:600px)');

    return (
        <>
            <AccessTokenContext.Provider
                value={{ accessToken, setAccessToken }}
            >
                <UserContext.Provider value={{ user, setUser }}>
                    {isMenuOpen ? (
                        <Menu
                            isDarkMode={isDarkMode}
                            setIsDarkMode={setIsDarkMode}
                            setIsMenuOpen={setIsMenuOpen}
                        />
                    ) : (
                        shouldShowMenu(user) && (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '75px',
                                    backgroundColor:
                                        theme.palette.background.default
                                }}
                            >
                                <MenuButton setIsMenuOpen={setIsMenuOpen} />
                            </Box>
                        )
                    )}
                    {!isSmallScreen && (
                        <>
                            {theme.palette.mode === 'dark' ? (
                                <LogoSmallDarkCornor />
                            ) : (
                                <LogoSmallLightCornor />
                            )}
                        </>
                    )}
                    <Routes>
                        <Route path="/" element={<ViewerContentRow />} />
                        <Route path="/:channelId" element={<ViewerChannel />} />
                        <Route
                            path="/:channelId/:videoId"
                            element={<ViewerVideo />}
                        />
                        {user.user_metadata?.role === 'researcher' && (
                            <>
                                <Route
                                    path="/manage-content"
                                    element={<ResearcherContent />}
                                />
                                <Route
                                    path="/statistics"
                                    element={<ResearcherStatistics />}
                                />
                                <Route
                                    path="/earnings"
                                    element={<ResearcherEarnings />}
                                />
                            </>
                        )}
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </UserContext.Provider>
            </AccessTokenContext.Provider>
        </>
    );
}
