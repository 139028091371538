import { User } from '../typings';
import { AvailableLanguages } from '../i18n/i18n';
import { DispatchAction } from './index';

export type FormUserViewer = Pick<
    User,
    'email' | 'name' | 'nickname' | 'picture'
> & {
    password1?: string;
    password2?: string;
    language: AvailableLanguages;
};

export type FormUser = Pick<User, 'email' | 'name' | 'nickname' | 'picture'> & {
    title: string;
    channel_backgroundimage: string;
    channel_name: string;
    channel_description: string;
    password1?: string;
    password2?: string;
    tasks: string;
    language: AvailableLanguages;
};

export type FormActionUserViewer = {
    type: string;
    payload: Partial<FormUserViewer>;
};

export type FormActionUser = { type: string; payload: Partial<FormUser> };

export function userFormReducer(u: FormUser, action: FormActionUser) {
    if (action.type === DispatchAction.UPDATE) {
        return { ...u, ...action.payload } as typeof u;
    }
    return u;
}

export function userFormViewerReducer(
    u: FormUserViewer,
    action: FormActionUserViewer
) {
    if (action.type === DispatchAction.UPDATE) {
        return { ...u, ...action.payload } as typeof u;
    }
    return u;
}
