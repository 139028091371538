import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Container,
    Button,
    useMediaQuery,
    useTheme,
    Breadcrumbs
} from '@mui/material';
import { Home } from '@mui/icons-material';

import {
    Article,
    StyledBreadcrumb,
    ViewerContentTable,
    Loading,
    ViewerContentTableMobile,
    Hero
} from '../components';
import { AccessTokenContext, AccessTokenContextType } from '../contexts';
import { Channel, Content } from '../typings';
import {
    getChannelById,
    getContentByOwner,
    postStatsChannel,
    postSubscription
} from '../utils';
import { useAuth0User } from '../hooks';
import { useTranslation } from 'react-i18next';

export function ViewerChannel() {
    const { channelId } = useParams();
    const { user } = useAuth0User();
    const [subscriptions, setSubscriptions] = useState<string[]>(
        user?.user_metadata?.subscriptions ?? []
    );
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [content, setContent] = useState<Content[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [postedStats, setPostedStats] = useState(false);
    const [channelData, setChannelData] = useState<null | Channel>(null);
    const { accessToken } = useContext(
        AccessTokenContext
    ) as AccessTokenContextType;

    const unsubscribeToChannel = async () => {
        if (channelId) {
            if (subscriptions.includes(channelId)) {
                const newSubscriptions = subscriptions.filter(
                    (sub) => sub !== channelId
                );
                setSubscriptions(newSubscriptions);
                const id = (user.user_id ?? user.sub).split('|')[1] ?? '123';
                await postSubscription(id, newSubscriptions, accessToken);
            }
        }
    };

    const subscribeToChannel = async () => {
        if (channelId) {
            if (!subscriptions.includes(channelId)) {
                const newSubscriptions = subscriptions.concat(channelId);
                setSubscriptions(newSubscriptions);
                const id = (user.user_id ?? user.sub).split('|')[1] ?? '123';
                await postSubscription(id, newSubscriptions, accessToken);
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (!postedStats && channelId && accessToken) {
                try {
                    setPostedStats(true);
                    await postStatsChannel(channelId, accessToken);
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [channelId, accessToken, postedStats]);

    useEffect(() => {
        (async () => {
            try {
                if (channelId && !content.length) {
                    const result = await getContentByOwner(
                        accessToken,
                        channelId
                    );
                    if (result) {
                        const ts = Array.from(
                            new Set(result.flatMap((item) => item.tags))
                        );
                        setTags(ts);
                        setContent(result);
                    }
                    const _channelData = await getChannelById(
                        accessToken,
                        channelId
                    );
                    if (_channelData) {
                        setChannelData(_channelData);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [channelId, accessToken, content]);

    return !content.length || channelData == null || !channelId ? (
        <Loading />
    ) : isSmallScreen ? (
        <Box sx={{ height: '100vh' }}>
            <Container maxWidth="sm">
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        component="a"
                        href="/"
                        label={t('views.breadcrumbs.home')}
                        icon={<Home fontSize="small" />}
                    />
                    <StyledBreadcrumb
                        component="a"
                        label={channelData.channelName}
                    />
                </Breadcrumbs>
                <Hero
                    headerText={channelData.channelName}
                    backgroundColor={theme.palette.background.paper}
                    backgroundImage={channelData?.channelBackground ?? ''}
                />
                <Box sx={{ marginTop: '1rem' }}>
                    <Article text={channelData.channelDescription} />
                </Box>
                <Button
                    sx={{ width: '125px' }}
                    variant="outlined"
                    onClick={() =>
                        subscriptions.includes(channelId)
                            ? unsubscribeToChannel()
                            : subscribeToChannel()
                    }
                >
                    {subscriptions.includes(channelId)
                        ? 'Unsubscribe'
                        : 'Subscribe'}
                </Button>
                <Box sx={{ marginTop: '2rem' }}>
                    {content.length > 0 && (
                        <ViewerContentTableMobile content={content} />
                    )}
                </Box>
            </Container>
        </Box>
    ) : (
        <Container maxWidth="xl" sx={{ height: '100vh' }}>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="/"
                    label={t('views.breadcrumbs.home')}
                    icon={<Home fontSize="small" />}
                />
                <StyledBreadcrumb
                    component="a"
                    label={channelData.channelName}
                />
            </Breadcrumbs>
            <Hero
                headerText={channelData.channelName}
                backgroundColor={theme.palette.background.paper}
                backgroundImage={channelData?.channelBackground ?? ''}
            />
            <Box sx={{ marginTop: '1rem' }}>
                <Article text={channelData.channelDescription} />
            </Box>
            <Button
                sx={{ width: '125px' }}
                variant="outlined"
                onClick={() =>
                    subscriptions.includes(channelId)
                        ? unsubscribeToChannel()
                        : subscribeToChannel()
                }
            >
                {subscriptions.includes(channelId)
                    ? 'Unsubscribe'
                    : 'Subscribe'}
            </Button>
            <Box sx={{ marginBottom: '5rem' }}>
                {content.length > 0 && (
                    <ViewerContentTable
                        predefinedTags={tags}
                        content={content}
                    />
                )}
            </Box>
        </Container>
    );
}
