import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { FormUser } from '../reducers';
import { useNewUser } from '../hooks';
import { ResearcherForm } from '../components';
import { useMediaQuery, Container, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function NewResearcher() {
    const { loginWithRedirect } = useAuth0();
    const { addUser } = useNewUser();
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');
    const theme = useTheme();

    const redirectToLogin = () => {
        const browserLanguage = navigator.language || 'EN-en';
        const loginUrl = new URL(window.location.origin);
        loginUrl.searchParams.set('ui_locales', browserLanguage);
        loginWithRedirect({ appState: { returnTo: loginUrl.toString() } });
    };

    const postNewUser = async (user: Partial<FormUser>) => {
        try {
            if (!isLoadingUpdate) {
                setIsLoadingUpdate(true);
                await addUser({
                    name: user.name,
                    email: user.email,
                    password: user.password1 as string,
                    nickname: user.nickname,
                    picture: user.picture,
                    user_metadata: {
                        title: user.title,
                        tasks: user.tasks,
                        channel_backgroundimage: user.channel_backgroundimage,
                        channel_name: user.channel_name,
                        channel_description: user.channel_description,
                        has_inited: true,
                        language: user.language,
                        role: 'researcher'
                    }
                });
                setIsLoadingUpdate(false);
                redirectToLogin();
                //setShowMessage(true);
            }
        } catch (e) {
            setIsLoadingUpdate(false);
            //const errorBody = t('components.user_form.dialog_error_body');
            //setErrorMessage('Error');
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={{ backgroundColor: theme.palette.background.default }}
        >
            {isMobile ? (
                <ResearcherForm action={postNewUser} />
            ) : (
                <fieldset
                    style={{
                        color: theme.palette.text.primary,
                        padding: '2rem',
                        margin: '2rem',
                        width: '500px'
                    }}
                >
                    <legend>{t('views.form.create_account')}</legend>
                    <ResearcherForm action={postNewUser} />
                </fieldset>
            )}
        </Container>
    );
}
