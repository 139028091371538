import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { User } from '../typings';
import { getAuth0User } from '../utils';

export function useInitAuth0User(sub: null | string) {
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const [user, setUser] = useState<null | User>(null);
    const [accessToken, setAccessToken] = useState<null | string>(null);
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        (async () => {
            if (user || accessToken || !isLoading || !sub) {
                return;
            }
            const domain = 'dev-dnwubwa3257m75rh.eu.auth0.com';
            const scope = 'read:current_user';
            try {
                const id_token = await getIdTokenClaims();
                if (id_token && '__raw' in id_token) {
                    setAccessToken(id_token.__raw);
                }
                const auth0AccessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: `https://${domain}/api/v2/`,
                        scope
                    }
                });
                const fetchedUser = await getAuth0User(
                    auth0AccessToken,
                    sub,
                    domain
                );
                setIsloading(false);
                if (fetchedUser) {
                    setUser({ ...fetchedUser, sub } as User);
                }
            } catch (e) {
                console.error(e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sub]); // sub in list

    return { user, setUser, accessToken, setAccessToken };
}
