import {
    useEffect,
    useRef,
    MutableRefObject,
    useState,
    useCallback
} from 'react';

import { ViewerContentTableRow, ViewerContentTableMobile } from '../components';
import { useMediaQuery, Box, Container } from '@mui/material';
import { useContent } from '../hooks';

const tags = [
    'Physics',
    'English',
    'Chemistry',
    'Philosophy',
    'Computer Science',
    'Art',
    'History',
    'Economics',
    'Mathematics'
];

export function ViewerContentRow() {
    const { content } = useContent();
    const isMobile = useMediaQuery('(max-width:600px)');
    const containerRef = useRef<null | HTMLDivElement>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const currentlyPlayingVideoRef =
        useRef<MutableRefObject<HTMLVideoElement> | null>(null);

    const getActiveIndexFromScroll = (scrollTop: number) => {
        const videoHeight =
            currentlyPlayingVideoRef != null &&
            currentlyPlayingVideoRef.current &&
            (currentlyPlayingVideoRef.current as unknown as HTMLVideoElement)
                .clientHeight
                ? (
                      currentlyPlayingVideoRef.current as unknown as HTMLVideoElement
                  ).clientHeight
                : 500;
        return Math.floor(scrollTop / videoHeight);
    };

    const handleScroll = useCallback(() => {
        const scrollTop = window.scrollY;
        const newActiveIndex = getActiveIndexFromScroll(scrollTop);
        if (newActiveIndex !== activeIndex) {
            setActiveIndex(newActiveIndex);
        }
    }, [activeIndex]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            document.body.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener(
                    'scroll',
                    handleScroll
                );
            }
        };
    }, [handleScroll, activeIndex, containerRef]);

    return !content.length ? (
        <></>
    ) : isMobile ? (
        <ViewerContentTableMobile content={content} />
    ) : (
        <Container
            ref={containerRef}
            maxWidth={'xl'}
            sx={{
                height: '50%',
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '9rem',
                    width: '15%'
                }}
            ></Box>
            <Box
                sx={{
                    width: '70%'
                }}
            >
                {content.length > 0 && (
                    <ViewerContentTableRow
                        currentlyPlayingVideoRef={
                            currentlyPlayingVideoRef as unknown as MutableRefObject<HTMLVideoElement> | null
                        }
                        activeIndex={activeIndex}
                        predefinedTags={tags}
                        content={content}
                    />
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '9rem',
                    width: '15%'
                }}
            ></Box>
        </Container>
    );
}
