import { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Statistics } from '../typings';
import { Box } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    indexAxis: 'y' as const,
    elements: {
        bar: {
            borderWidth: 2
        }
    },
    responsive: true
};

type Props = {
    data: Statistics;
    earningChannel?: number;
    earningVideo?: number;
};
export function StatsVisualization({
    data,
    earningChannel,
    earningVideo
}: Props) {
    const currentDate = new Date();
    const [beginDate, setBeginDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(currentDate));

    useEffect(() => {
        const earliestDate = data.video.reduce((minDate, item) => {
            const createdAt = new Date(item.created_at);
            return createdAt.getTime() < minDate
                ? createdAt.getTime()
                : minDate;
        }, new Date().getTime());

        setBeginDate(dayjs(earliestDate).startOf('day'));
    }, [data.video]);

    const filteredVideoData = data.video.filter(
        (item) =>
            (!beginDate ||
                dayjs(item.created_at).isAfter(beginDate.startOf('day'))) &&
            (!endDate || dayjs(item.created_at).isBefore(endDate.endOf('day')))
    );

    const filteredChannelData = data.channel.filter(
        (item) =>
            (!beginDate ||
                dayjs(item.created_at).isAfter(beginDate.startOf('day'))) &&
            (!endDate || dayjs(item.created_at).isBefore(endDate.endOf('day')))
    );
    const videoData = filteredVideoData.length * (earningVideo ?? 1);
    const channelData = filteredChannelData.length * (earningChannel ?? 1);

    const props = {
        labels: ['Filtered Period'],
        datasets: [
            {
                label: 'Video',
                data: [videoData],
                borderColor: ['#8FBCBB'],
                backgroundColor: ['#8FBCBB']
            },
            {
                label: 'Channel',
                data: [channelData],
                borderColor: ['#5E81AC'],
                backgroundColor: ['#5E81AC']
            }
        ]
    };

    return (
        <Box sx={{ height: '100vh' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Begin date"
                        value={beginDate}
                        onChange={(newValue) => setBeginDate(newValue)}
                    />
                    <DatePicker
                        label="End date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                sx={{
                    marginTop: '2rem',
                    border: '1px solid rgba(211,211,211,0.8)',
                    padding: '1rem'
                }}
            >
                <Bar options={options} data={props} />
            </Box>
        </Box>
    );
}
