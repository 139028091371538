export const sv = {
    translation: {
        views: {
            title: 'dizcover',
            about_us: {
                header: 'Om oss'
            },
            landing_page: {
                subheader: '[Sammankopplar vetenskap och verklighet]',
                header: 'Vårt kall',
                paragraph: `Vårt kall är att göra *forskning tillgänglig för alla*. Vi hjälper forskare att omvandla sin
                forskning till videos, spel eller annat digitalt innehåll. Sedan tillgängliggör vi detta på vår plattform.

    *Vinsterna kommer genom vår affärsmodell där vi sammankopplar utbildning, övning och jobb att gå till utbildning i låginkomstländer samt ekonomiska insatser* .`,
                option_researcher: 'Är du en forskare?',
                option_viewer: 'Vill du utforska forskning?'
            },
            menu: {
                item_main: 'Hem',
                item_content: 'Innehåll',
                item_manage_content: 'Administrera innehåll',
                item_statistics: 'Statistik',
                item_earnings: 'Inkomster',
                item_settings: 'Inställningar',
                item_about_us: 'Om oss',
                item_logout: 'Logga ut'
            },
            content: {
                choosen_tags: 'Choosen tags',
                people_like_this: 'People like this',
                share: 'Share',
                add_reply_cancel: 'Cancel',
                add_reply: 'Reply',
                add_reply_placeholder: 'Add reply',
                by: 'By:',
                add_comment: 'Comment',
                choose_video: 'Choose a video',
                title: 'Title',
                title_placeholder: 'Title',
                description: 'Description',
                description_placeholder: 'Description',
                cancel: 'Cancel',
                upload_content_video: 'Upload content (video)',
                update: {
                    legend: 'Update video content'
                },
                create: {
                    legend: 'Create video content'
                }
            },
            form: {
                data_saved: 'Data sparad',
                create_account: 'Skapa konto',
                choose_channel_background: 'Kanalens bakgrund',
                choose_picture: 'Välj en bild',
                choose_language: 'Välj språk',
                name: 'Namn',
                name_placeholder: 'Ditt namn',
                nickname: 'Smeknamn',
                nickname_placeholder: 'Ditt smeknamn',
                email: 'Email',
                email_placeholder: 'Din email',
                title: 'Titel',
                title_placeholder: 'Din titel',
                tasks: 'Forskningsområde',
                tasks_placeholder: 'Beskriv ditt forskningsområde',
                channel_name: 'Kanalens namn',
                channel_description: 'Beskrivning av kanalen',
                submit: 'Skicka',
                password1: 'Lösenord',
                password2: 'Lösenord igen',
                password1_placeholder: 'Lösenord igen',
                password2_placeholder: 'Lösenord  igen',
                delete_user_button: 'Ta bort konto',
                delete_user_confirmation:
                    'Är du säker på att du vill ta bort din användare?',
                delete_user_confirmation_cancel: 'Avbryt',
                delete_user_confirmation_affirm: 'Ja'
            },
            table: {
                search: 'Utforska vi titel eller beskrivning',
                created_at: 'Datum',
                owner: 'Ägare',
                channel_name: 'Kanale',
                tags: 'Kategorier',
                title: 'Titel',
                description: 'Beskrivning',
                remove_selected: 'Ta bort valda',
                upload: 'Ny video'
            },
            statistics: {
                header: 'Besökare per period'
            },
            earnings: {
                header: 'Inkomster ($) per period'
            },
            settings: {
                header: 'Inställningar'
            },
            breadcrumbs: {
                home: 'Hem'
            }
        }
    }
};
