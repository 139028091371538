import { Box, Chip } from '@mui/material';

type Props = {
    selectedTags: string[];
    predefinedTags: string[];
    handleTagFilter: (tag: string) => void;
};
export function TagFilter({
    selectedTags,
    predefinedTags,
    handleTagFilter
}: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                marginTop: '2rem',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {predefinedTags.map((tag) => (
                <Chip
                    key={tag}
                    label={tag}
                    color={selectedTags.includes(tag) ? 'primary' : 'default'}
                    onClick={() => handleTagFilter(tag)}
                    style={{ margin: '0.2rem' }}
                />
            ))}
        </Box>
    );
}
