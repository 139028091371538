import { useState, useContext } from 'react';

import {
    getAuth0User,
    deleteAuth0User,
    addContent,
    putContent as changeContent,
    updateAuth0User,
    postImage
} from '../utils/api';
import { User, Content } from '../typings';
import {
    AccessTokenContext,
    AccessTokenContextType,
    UserContext,
    UserContextType
} from '../contexts';

export function useAuth0User() {
    const { accessToken } = useContext(
        AccessTokenContext
    ) as AccessTokenContextType;
    const { user, setUser } = useContext(UserContext) as UserContextType;
    const [isLoading, setIsLoading] = useState(false);

    const postPicture = async (f: any): Promise<null | undefined | string> => {
        try {
            setIsLoading(true);
            const r = await postImage(f);
            setIsLoading(false);
            return r;
        } catch (e) {
            setIsLoading(false);
        }
    };

    const deleteUser = async () => {
        if (accessToken && (user.sub || user.user_id)) {
            try {
                setIsLoading(true);
                const r = await deleteAuth0User(
                    accessToken,
                    user.sub ?? user.user_id
                );
                setIsLoading(false);
                return r;
            } catch (e) {
                setIsLoading(false);
            }
        }
    };

    const putContent = async (v: Partial<Content>) => {
        try {
            setIsLoading(true);
            const r = await changeContent(accessToken, v);
            return r;
        } catch (e) {
            setIsLoading(false);
        }
    };

    const postContent = async (v: Partial<Content>) => {
        //if(user.user_metadata?.role !== 'researcher') return;
        try {
            setIsLoading(true);
            const r = await addContent(accessToken, v);
            return r;
        } catch (e) {
            setIsLoading(false);
        }
    };

    const updateUser = async (u: Partial<User>, id: string) => {
        try {
            setIsLoading(true);
            const r = await updateAuth0User(accessToken, id, u);
            if (r) {
                setUser(r);
            }
            return r;
        } catch (e) {
            setIsLoading(false);
        }
    };

    const refetchUser = async (token: string) => {
        const domain = 'dev-y3ifqmia3zrnr3ml.eu.auth0.com';
        try {
            setIsLoading(true);
            const fetchedUser = await getAuth0User(token, user.user_id, domain);
            setIsLoading(false);
            if (fetchedUser && (fetchedUser as { error: string }).error) {
                console.error('unhandled error');
            } else {
                setUser(fetchedUser as User);
                return (fetchedUser as User).email_verified;
            }
        } catch (e) {
            setIsLoading(false);
        }
    };

    return {
        user,
        setUser,
        deleteUser,
        refetchUser,
        updateUser,
        postContent,
        putContent,
        postPicture,
        isLoading
    };
}
