import { Box, Modal } from '@mui/material';

import { styled } from '@mui/material/styles';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    RedditShareButton,
    WhatsappShareButton
} from 'react-share';
import {
    Facebook,
    Twitter,
    LinkedIn,
    Reddit,
    WhatsApp
} from '@mui/icons-material';

const ModalContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: '8px',
    outline: 'none',
    width: '300px',
    height: '300px'
}));

type Props = {
    open: boolean;
    onClose: () => void;
    shareUrl: string;
};

export function ShareModal({ open, onClose, shareUrl }: Props) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                height: '100vh',
                width: '100vw',

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <ModalContent>
                <Box>
                    <FacebookShareButton url={shareUrl}>
                        <Facebook sx={{ fontSize: '32px' }} />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl}>
                        <Twitter sx={{ fontSize: '32px' }} />
                    </TwitterShareButton>
                    <LinkedinShareButton url={shareUrl}>
                        <LinkedIn sx={{ fontSize: '32px' }} />
                    </LinkedinShareButton>
                    <RedditShareButton url={shareUrl}>
                        <Reddit sx={{ fontSize: '32px' }} />
                    </RedditShareButton>
                    <WhatsappShareButton url={shareUrl}>
                        <WhatsApp sx={{ fontSize: '32px' }} />
                    </WhatsappShareButton>
                </Box>
            </ModalContent>
        </Modal>
    );
}
