import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type Props = {
    backgroundColor: string;
    backgroundImage?: string;
    headerText: string;
};

export function Hero({ backgroundColor, backgroundImage, headerText }: Props) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                width: '100%',
                marginTop: '2rem',
                minHeight: '400px',
                backgroundColor,
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                borderTopRightRadius: '20px',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                color: 'white',
                boxSizing: 'border-box'
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    color: theme.palette.text.primary,
                    fontSize: '2.5rem',
                    fontWeight: 900,
                    marginLeft: '1rem',
                    marginBottom: '1rem'
                }}
            >
                {headerText}
            </Typography>
        </Box>
    );
}
