import { useNavigate } from 'react-router-dom';

type Props = {
    fill?: string;
    background?: string;
};
export function LogoSmallDarkCornor({
    fill = 'white',
    background = 'black'
}: Props) {
    const navigate = useNavigate();
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            onClick={() => navigate('/')}
            style={{
                cursor: 'pointer',
                position: 'absolute',
                left: '10px',
                top: '10px'
            }}
            width="10%"
            viewBox="430 150 1100 600"
        >
            <path
                fill="#2B2D53"
                opacity="1.000000"
                stroke="none"
                d="
M1111.000000,1081.000000
	C740.666748,1081.000000 370.833466,1081.000000 1.000149,1081.000000
	C1.000099,721.000122 1.000099,361.000275 1.000050,1.000294
	C640.999756,1.000196 1280.999512,1.000196 1920.999512,1.000098
	C1920.999634,360.999664 1920.999634,720.999329 1920.999756,1080.999512
	C1651.166626,1081.000000 1381.333374,1081.000000 1111.000000,1081.000000
M1386.881592,245.689438
	C1361.731445,236.857422 1336.011841,235.670532 1310.144409,241.207870
	C1275.759766,248.568420 1248.845825,267.001740 1231.045898,297.695740
	C1216.729980,322.381927 1212.460205,349.086731 1216.927856,377.112793
	C1222.626831,412.861908 1240.909668,440.559906 1271.962402,459.208038
	C1303.850830,478.358063 1338.414307,481.324249 1374.092896,473.407837
	C1400.553589,467.536743 1422.877441,454.154724 1440.005615,432.968109
	C1461.791016,406.021027 1469.908325,374.915253 1464.964478,340.883881
	C1458.165771,294.085815 1431.649292,262.724945 1386.881592,245.689438
M471.578735,471.052704
	C497.743378,471.052856 523.911011,470.806335 550.071838,471.121124
	C577.333679,471.449158 601.733459,463.415558 622.402222,445.850494
	C656.992126,416.454590 670.918579,378.948059 662.053345,334.241364
	C652.027222,283.681091 605.284058,242.817078 548.575256,244.103958
	C522.923889,244.686050 497.248322,244.203827 471.583740,244.204453
	C469.807983,244.204498 468.032257,244.204453 466.085815,244.204453
	C466.085815,320.059875 466.085815,395.320648 466.085815,471.052612
	C467.701019,471.052612 469.148529,471.052612 471.578735,471.052704
M828.500122,294.783264
	C850.528442,294.783264 872.556824,294.783264 895.287415,294.783264
	C859.420715,353.893005 824.047058,412.190277 788.527161,470.728546
	C853.742188,470.728546 918.137939,470.728546 982.567932,470.728546
	C982.567932,453.987335 982.567932,437.573608 982.567932,420.677002
	C951.280823,420.677002 920.254578,420.677002 888.352783,420.677002
	C924.001892,361.586731 959.202942,303.239227 994.610229,244.549835
	C931.445496,244.549835 869.238708,244.549835 807.146606,244.549835
	C807.146606,261.447327 807.146606,277.992859 807.146606,294.783264
	C814.211060,294.783264 820.855652,294.783264 828.500122,294.783264
M1090.205322,314.713562
	C1103.511353,300.961548 1119.753540,294.887390 1138.812134,295.867798
	C1157.856079,296.847473 1172.599731,306.076416 1185.596802,321.090912
	C1185.596802,297.134216 1185.636353,274.689636 1185.483398,252.246384
	C1185.475586,251.084961 1184.065186,249.348724 1182.899292,248.865524
	C1157.490356,238.333878 1131.231934,234.548996 1104.279785,241.145676
	C1061.027222,251.731995 1030.924561,278.140930 1017.550903,320.533173
	C1004.458130,362.034943 1012.172974,401.091248 1040.182983,434.889893
	C1062.076782,461.308502 1090.780396,475.211365 1125.211182,477.114960
	C1145.232300,478.221924 1164.149292,473.535034 1182.819336,467.048035
	C1185.597900,466.082581 1185.627563,464.391174 1185.622681,462.115753
	C1185.577393,441.285217 1185.598999,420.454529 1185.588013,399.623901
	C1185.587402,398.534729 1185.468262,397.445618 1185.404419,396.356506
	C1184.853027,395.941040 1184.301636,395.525574 1183.750122,395.110138
	C1182.561401,396.894012 1181.666504,398.994415 1180.138550,400.412964
	C1163.671631,415.700409 1144.485596,423.285645 1122.038086,418.362701
	C1075.729126,408.206787 1058.164307,351.603271 1090.205322,314.713562
M761.024963,287.500000
	C761.024963,273.218353 761.024963,258.936737 761.024963,244.579865
	C741.024841,244.579865 721.503845,244.579865 701.986450,244.579865
	C701.986450,320.195496 701.986450,395.434784 701.986450,470.725952
	C721.784058,470.725952 741.323669,470.725952 761.024963,470.725952
	C761.024963,409.766510 761.024963,349.133240 761.024963,287.500000
M1385.331421,598.760132
	C1384.388794,597.786804 1382.652100,596.832764 1382.627808,595.837036
	C1382.413574,587.040771 1382.503662,578.237061 1382.503662,568.943726
	C1404.050049,568.943726 1425.292114,568.943726 1446.553467,568.943726
	C1446.553467,551.726746 1446.553467,535.006836 1446.553467,518.402405
	C1405.319824,518.402405 1364.427979,518.402405 1323.582275,518.402405
	C1323.582275,589.479736 1323.582275,660.191650 1323.582275,730.903076
	C1364.834229,730.903076 1405.751221,730.903076 1446.636719,730.903076
	C1446.636719,714.012146 1446.636719,697.409851 1446.636719,680.208130
	C1425.164673,680.208130 1403.940674,680.208130 1382.874023,680.208130
	C1382.874023,669.702759 1382.874023,659.803284 1382.874023,649.420044
	C1403.133545,649.420044 1423.018188,649.420044 1442.947998,649.420044
	C1442.947998,632.318176 1442.947998,615.714172 1442.947998,598.761963
	C1423.953613,598.761963 1405.161987,598.761963 1385.331421,598.760132
M855.542725,535.501770
	C855.542725,600.607422 855.542725,665.713074 855.542725,730.985779
	C875.473633,730.985779 894.914917,730.985779 914.851196,730.985779
	C914.851196,706.938599 914.851196,683.087830 914.851196,657.712830
	C916.566040,660.305481 917.447510,661.609802 918.300659,662.932434
	C932.185913,684.458069 946.114563,705.955994 959.890137,727.551575
	C961.600525,730.232849 963.351257,731.306885 966.631409,731.279846
	C988.129089,731.102722 1009.628906,731.191895 1031.128052,731.178955
	C1032.590576,731.178040 1034.052979,731.053711 1035.996948,730.964783
	C1013.937439,702.184448 992.198608,673.822571 970.204041,645.127014
	C972.398682,644.305786 974.081055,643.662292 975.773010,643.045166
	C991.072021,637.465210 1003.000488,627.810364 1008.463867,612.299866
	C1011.445557,603.835144 1012.924622,594.505493 1013.244385,585.500671
	C1014.266785,556.705444 999.113342,532.851379 973.447571,523.630371
	C964.081970,520.265625 953.695007,518.552246 943.718933,518.305115
	C915.403992,517.603577 887.060730,518.045166 858.728577,518.050110
	C857.785889,518.050293 856.843262,518.194031 855.542786,518.299927
	C855.542786,523.883667 855.542786,529.193359 855.542725,535.501770
M821.753113,556.482300
	C821.753113,543.731384 821.753113,530.980530 821.753113,518.415955
	C780.105774,518.415955 739.214844,518.415955 698.423096,518.415955
	C698.423096,589.497070 698.423096,660.203003 698.423096,730.917419
	C739.656189,730.917419 780.582214,730.917419 821.492310,730.917419
	C821.492310,714.048706 821.492310,697.449219 821.492310,680.275513
	C800.005737,680.275513 778.770935,680.275513 757.615356,680.275513
	C757.615356,669.799988 757.615356,659.904358 757.615356,649.558350
	C777.864990,649.558350 797.788330,649.558350 817.729126,649.558350
	C817.729126,632.392822 817.729126,615.688477 817.729126,598.525757
	C797.502441,598.525757 777.594421,598.525757 757.492432,598.525757
	C757.492432,588.485962 757.492432,578.907776 757.492432,568.853394
	C778.935059,568.853394 800.174500,568.853394 821.753113,568.853394
	C821.753113,564.745422 821.753113,561.109070 821.753113,556.482300
M1225.315918,682.002502
	C1220.922729,683.341980 1216.597656,685.504761 1212.124390,685.873962
	C1195.559692,687.241394 1181.733521,680.249329 1169.014404,670.499023
	C1166.190308,668.334167 1163.478149,666.023315 1160.536865,663.635681
	C1151.874512,679.937134 1143.476074,695.741882 1135.026489,711.642822
	C1135.676025,712.216248 1136.125366,712.699585 1136.654785,713.067932
	C1164.635010,732.535156 1195.543701,740.421082 1229.351196,735.830688
	C1272.394653,729.986389 1296.646240,700.360779 1294.100708,657.111450
	C1292.897095,636.661743 1283.712769,620.494507 1265.791748,610.661316
	C1254.690063,604.569885 1242.331909,600.770752 1230.546143,595.922302
	C1224.430420,593.406372 1217.905151,591.548035 1212.324097,588.146973
	C1203.147339,582.554688 1203.447144,570.925415 1213.089966,566.099609
	C1218.159424,563.562561 1224.564575,562.518250 1230.297485,562.724121
	C1242.768677,563.171875 1252.952148,569.703979 1262.674194,577.817505
	C1270.750488,561.961426 1278.691040,546.371643 1286.705444,530.636719
	C1284.268921,529.335510 1282.420288,528.238770 1280.483154,527.330322
	C1253.482178,514.668030 1225.470947,508.033142 1195.757202,515.086975
	C1155.856812,524.559021 1133.174683,568.476929 1148.038696,606.865540
	C1154.358765,623.188354 1166.946899,632.992432 1182.839844,639.043335
	C1195.865723,644.002502 1209.253174,648.141846 1221.917603,653.885132
	C1235.002563,659.819275 1236.270630,671.600586 1225.315918,682.002502
M513.384766,518.046021
	C494.728302,518.046021 476.071808,518.046021 457.076569,518.046021
	C457.594513,519.550781 457.946320,520.803101 458.446503,521.993042
	C487.271576,590.567627 516.129456,659.128540 544.871643,727.737854
	C546.045471,730.539856 547.508545,731.280457 550.366333,731.246155
	C562.863281,731.096069 575.369995,730.948181 587.858032,731.314331
	C591.987732,731.435425 593.544006,729.896606 595.014160,726.396240
	C623.497986,658.579163 652.105408,590.813904 680.675537,523.033020
	C681.300659,521.549988 681.786316,520.008240 682.445129,518.196594
	C660.794189,518.196594 639.670471,518.196594 618.394897,518.196594
	C602.268860,558.620178 586.173767,598.966248 569.746338,640.145264
	C568.609680,637.584229 567.830017,635.982910 567.176270,634.331848
	C552.690063,597.743958 538.137085,561.181946 523.841064,524.519775
	C521.981689,519.751404 519.804565,516.851074 513.384766,518.046021
M1096.399902,672.077698
	C1082.293945,664.861633 1068.778320,665.730835 1056.337280,675.656372
	C1045.558960,684.255310 1041.451294,699.307739 1045.657227,712.668396
	C1050.367310,727.630554 1063.703003,737.209717 1079.559814,737.020996
	C1094.721558,736.840576 1107.788452,726.924011 1112.160645,712.279785
	C1116.743042,696.931702 1110.923706,681.643921 1096.399902,672.077698
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M1387.257202,245.806458
	C1431.649292,262.724945 1458.165771,294.085815 1464.964478,340.883881
	C1469.908325,374.915253 1461.791016,406.021027 1440.005615,432.968109
	C1422.877441,454.154724 1400.553589,467.536743 1374.092896,473.407837
	C1338.414307,481.324249 1303.850830,478.358063 1271.962402,459.208038
	C1240.909668,440.559906 1222.626831,412.861908 1216.927856,377.112793
	C1212.460205,349.086731 1216.729980,322.381927 1231.045898,297.695740
	C1248.845825,267.001740 1275.759766,248.568420 1310.144409,241.207870
	C1336.011841,235.670532 1361.731445,236.857422 1387.257202,245.806458
M1402.772583,343.982941
	C1392.641968,302.382843 1345.795654,283.818756 1309.685547,303.547638
	C1281.728638,318.821960 1270.104370,351.804779 1282.016602,381.189056
	C1293.779297,410.204590 1326.769775,425.897491 1357.826416,417.442566
	C1385.872803,409.807159 1409.936890,382.145111 1402.772583,343.982941
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M471.087372,471.052673
	C469.148529,471.052612 467.701019,471.052612 466.085815,471.052612
	C466.085815,395.320648 466.085815,320.059875 466.085815,244.204453
	C468.032257,244.204453 469.807983,244.204498 471.583740,244.204453
	C497.248322,244.203827 522.923889,244.686050 548.575256,244.103958
	C605.284058,242.817078 652.027222,283.681091 662.053345,334.241364
	C670.918579,378.948059 656.992126,416.454590 622.402222,445.850494
	C601.733459,463.415558 577.333679,471.449158 550.071838,471.121124
	C523.911011,470.806335 497.743378,471.052856 471.087372,471.052673
M560.444824,297.928925
	C549.011841,294.166748 537.267090,294.698517 525.534668,294.899963
	C525.534668,337.101074 525.534668,378.808411 525.534668,420.482452
	C565.696228,423.774811 594.040710,406.264374 600.854736,374.029144
	C607.578613,342.220673 594.897400,309.053894 560.444824,297.928925
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M828.000183,294.783264
	C820.855652,294.783264 814.211060,294.783264 807.146606,294.783264
	C807.146606,277.992859 807.146606,261.447327 807.146606,244.549835
	C869.238708,244.549835 931.445496,244.549835 994.610229,244.549835
	C959.202942,303.239227 924.001892,361.586731 888.352783,420.677002
	C920.254578,420.677002 951.280823,420.677002 982.567932,420.677002
	C982.567932,437.573608 982.567932,453.987335 982.567932,470.728546
	C918.137939,470.728546 853.742188,470.728546 788.527161,470.728546
	C824.047058,412.190277 859.420715,353.893005 895.287415,294.783264
	C872.556824,294.783264 850.528442,294.783264 828.000183,294.783264
z"
            />
            <path
                fill="#FDFDFD"
                opacity="1.000000"
                stroke="none"
                d="
M1089.978394,314.990051
	C1058.164307,351.603271 1075.729126,408.206787 1122.038086,418.362701
	C1144.485596,423.285645 1163.671631,415.700409 1180.138550,400.412964
	C1181.666504,398.994415 1182.561401,396.894012 1183.750122,395.110138
	C1184.301636,395.525574 1184.853027,395.941040 1185.404419,396.356506
	C1185.468262,397.445618 1185.587402,398.534729 1185.588013,399.623901
	C1185.598999,420.454529 1185.577393,441.285217 1185.622681,462.115753
	C1185.627563,464.391174 1185.597900,466.082581 1182.819336,467.048035
	C1164.149292,473.535034 1145.232300,478.221924 1125.211182,477.114960
	C1090.780396,475.211365 1062.076782,461.308502 1040.182983,434.889893
	C1012.172974,401.091248 1004.458130,362.034943 1017.550903,320.533173
	C1030.924561,278.140930 1061.027222,251.731995 1104.279785,241.145676
	C1131.231934,234.548996 1157.490356,238.333878 1182.899292,248.865524
	C1184.065186,249.348724 1185.475586,251.084961 1185.483398,252.246384
	C1185.636353,274.689636 1185.596802,297.134216 1185.596802,321.090912
	C1172.599731,306.076416 1157.856079,296.847473 1138.812134,295.867798
	C1119.753540,294.887390 1103.511353,300.961548 1089.978394,314.990051
z"
            />
            <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M761.024963,288.000000
	C761.024963,349.133240 761.024963,409.766510 761.024963,470.725952
	C741.323669,470.725952 721.784058,470.725952 701.986450,470.725952
	C701.986450,395.434784 701.986450,320.195496 701.986450,244.579865
	C721.503845,244.579865 741.024841,244.579865 761.024963,244.579865
	C761.024963,258.936737 761.024963,273.218353 761.024963,288.000000
z"
            />
            <path
                fill="#F0F0F0"
                opacity="1.000000"
                stroke="none"
                d="
M1385.850830,598.761047
	C1405.161987,598.761963 1423.953613,598.761963 1442.947998,598.761963
	C1442.947998,615.714172 1442.947998,632.318176 1442.947998,649.420044
	C1423.018188,649.420044 1403.133545,649.420044 1382.874023,649.420044
	C1382.874023,659.803284 1382.874023,669.702759 1382.874023,680.208130
	C1403.940674,680.208130 1425.164673,680.208130 1446.636719,680.208130
	C1446.636719,697.409851 1446.636719,714.012146 1446.636719,730.903076
	C1405.751221,730.903076 1364.834229,730.903076 1323.582275,730.903076
	C1323.582275,660.191650 1323.582275,589.479736 1323.582275,518.402405
	C1364.427979,518.402405 1405.319824,518.402405 1446.553467,518.402405
	C1446.553467,535.006836 1446.553467,551.726746 1446.553467,568.943726
	C1425.292114,568.943726 1404.050049,568.943726 1382.503662,568.943726
	C1382.503662,578.237061 1382.413574,587.040771 1382.627808,595.837036
	C1382.652100,596.832764 1384.388794,597.786804 1385.850830,598.761047
M1381.907227,685.405579
	C1380.468628,685.315125 1379.030029,685.224609 1377.713379,685.141846
	C1377.713379,671.426025 1377.713379,658.171875 1377.713379,644.458618
	C1397.932617,644.458618 1417.833008,644.458618 1437.856689,644.458618
	C1437.856689,630.737488 1437.856689,617.483093 1437.856689,603.788940
	C1417.728271,603.788940 1397.822021,603.788940 1377.710693,603.788940
	C1377.710693,590.290833 1377.710693,577.234924 1377.710693,563.841553
	C1399.142944,563.841553 1420.255737,563.841553 1441.491699,563.841553
	C1441.491699,550.123169 1441.491699,536.746338 1441.491699,523.290771
	C1403.706543,523.290771 1366.254761,523.290771 1328.570679,523.290771
	C1328.570679,590.885193 1328.570679,658.288574 1328.570679,725.830078
	C1366.324951,725.830078 1403.893799,725.830078 1441.539917,725.830078
	C1441.539917,712.293701 1441.539917,699.047485 1441.539917,685.405151
	C1421.781006,685.405151 1402.304321,685.405151 1381.907227,685.405579
z"
            />
            <path
                fill="#F1F1F1"
                opacity="1.000000"
                stroke="none"
                d="
M855.542725,535.002441
	C855.542786,529.193359 855.542786,523.883667 855.542786,518.299927
	C856.843262,518.194031 857.785889,518.050293 858.728577,518.050110
	C887.060730,518.045166 915.403992,517.603577 943.718933,518.305115
	C953.695007,518.552246 964.081970,520.265625 973.447571,523.630371
	C999.113342,532.851379 1014.266785,556.705444 1013.244385,585.500671
	C1012.924622,594.505493 1011.445557,603.835144 1008.463867,612.299866
	C1003.000488,627.810364 991.072021,637.465210 975.773010,643.045166
	C974.081055,643.662292 972.398682,644.305786 970.204041,645.127014
	C992.198608,673.822571 1013.937439,702.184448 1035.996948,730.964783
	C1034.052979,731.053711 1032.590576,731.178040 1031.128052,731.178955
	C1009.628906,731.191895 988.129089,731.102722 966.631409,731.279846
	C963.351257,731.306885 961.600525,730.232849 959.890137,727.551575
	C946.114563,705.955994 932.185913,684.458069 918.300659,662.932434
	C917.447510,661.609802 916.566040,660.305481 914.851196,657.712830
	C914.851196,683.087830 914.851196,706.938599 914.851196,730.985779
	C894.914917,730.985779 875.473633,730.985779 855.542725,730.985779
	C855.542725,665.713074 855.542725,600.607422 855.542725,535.002441
M909.686401,658.503174
	C909.686401,652.904480 909.686401,647.305725 909.686401,640.331482
	C911.393677,642.926941 912.304077,644.288330 913.191956,645.664307
	C929.690002,671.231384 946.227173,696.773499 962.619385,722.408264
	C964.326477,725.077881 966.011780,726.247070 969.322815,726.209229
	C986.644775,726.011292 1003.970215,726.122314 1021.294434,726.105408
	C1022.520874,726.104187 1023.747192,725.938965 1025.644775,725.801392
	C1004.041687,697.612244 982.886230,670.007263 961.393311,641.961975
	C964.570740,641.131653 966.978149,640.576477 969.341431,639.873230
	C987.037781,634.607422 1000.201111,624.241638 1005.420044,605.968567
	C1007.312012,599.344543 1008.076965,592.226379 1008.230957,585.308594
	C1008.847656,557.620605 994.394470,535.649902 969.533997,527.517029
	C961.139648,524.770935 951.952942,523.488953 943.092041,523.306702
	C916.783630,522.765808 890.457031,523.100952 864.137573,523.116394
	C863.036377,523.117065 861.935303,523.346680 860.979858,523.453430
	C860.979858,591.136475 860.979858,658.502075 860.979858,725.784485
	C877.380493,725.784485 893.441650,725.784485 909.686462,725.784485
	C909.686462,703.468567 909.686462,681.485291 909.686401,658.503174
z"
            />
            <path
                fill="#F2F2F2"
                opacity="1.000000"
                stroke="none"
                d="
M821.753113,556.977539
	C821.753113,561.109070 821.753113,564.745422 821.753113,568.853394
	C800.174500,568.853394 778.935059,568.853394 757.492432,568.853394
	C757.492432,578.907776 757.492432,588.485962 757.492432,598.525757
	C777.594421,598.525757 797.502441,598.525757 817.729126,598.525757
	C817.729126,615.688477 817.729126,632.392822 817.729126,649.558350
	C797.788330,649.558350 777.864990,649.558350 757.615356,649.558350
	C757.615356,659.904358 757.615356,669.799988 757.615356,680.275513
	C778.770935,680.275513 800.005737,680.275513 821.492310,680.275513
	C821.492310,697.449219 821.492310,714.048706 821.492310,730.917419
	C780.582214,730.917419 739.656189,730.917419 698.423096,730.917419
	C698.423096,660.203003 698.423096,589.497070 698.423096,518.415955
	C739.214844,518.415955 780.105774,518.415955 821.753113,518.415955
	C821.753113,530.980530 821.753113,543.731384 821.753113,556.977539
M752.209412,593.456177
	C752.209412,583.689880 752.209412,573.923645 752.209412,563.887451
	C773.993713,563.887451 795.127686,563.887451 816.403748,563.887451
	C816.403748,550.213135 816.403748,536.824402 816.403748,523.436340
	C778.571594,523.436340 741.013916,523.436340 703.527405,523.436340
	C703.527405,591.157654 703.527405,658.538086 703.527405,725.731567
	C741.422974,725.731567 778.967651,725.731567 816.422729,725.731567
	C816.422729,712.229492 816.422729,699.119873 816.422729,685.409790
	C794.946289,685.409790 773.714294,685.409790 752.457336,685.409790
	C752.457336,671.544189 752.457336,658.284790 752.457336,644.656921
	C772.643799,644.656921 792.587524,644.656921 812.727295,644.656921
	C812.727295,630.882324 812.727295,617.479675 812.727295,603.687500
	C792.535217,603.687500 772.615295,603.687500 752.209412,603.687500
	C752.209412,600.348877 752.209412,597.392700 752.209412,593.456177
z"
            />
            <path
                fill="#F2F2F2"
                opacity="1.000000"
                stroke="none"
                d="
M1225.619385,681.785278
	C1236.270630,671.600586 1235.002563,659.819275 1221.917603,653.885132
	C1209.253174,648.141846 1195.865723,644.002502 1182.839844,639.043335
	C1166.946899,632.992432 1154.358765,623.188354 1148.038696,606.865540
	C1133.174683,568.476929 1155.856812,524.559021 1195.757202,515.086975
	C1225.470947,508.033142 1253.482178,514.668030 1280.483154,527.330322
	C1282.420288,528.238770 1284.268921,529.335510 1286.705444,530.636719
	C1278.691040,546.371643 1270.750488,561.961426 1262.674194,577.817505
	C1252.952148,569.703979 1242.768677,563.171875 1230.297485,562.724121
	C1224.564575,562.518250 1218.159424,563.562561 1213.089966,566.099609
	C1203.447144,570.925415 1203.147339,582.554688 1212.324097,588.146973
	C1217.905151,591.548035 1224.430420,593.406372 1230.546143,595.922302
	C1242.331909,600.770752 1254.690063,604.569885 1265.791748,610.661316
	C1283.712769,620.494507 1292.897095,636.661743 1294.100708,657.111450
	C1296.646240,700.360779 1272.394653,729.986389 1229.351196,735.830688
	C1195.543701,740.421082 1164.635010,732.535156 1136.654785,713.067932
	C1136.125366,712.699585 1135.676025,712.216248 1135.026489,711.642822
	C1143.476074,695.741882 1151.874512,679.937134 1160.536865,663.635681
	C1163.478149,666.023315 1166.190308,668.334167 1169.014404,670.499023
	C1181.733521,680.249329 1195.559692,687.241394 1212.124390,685.873962
	C1216.597656,685.504761 1220.922729,683.341980 1225.619385,681.785278
M1282.398560,695.749390
	C1288.081055,684.436707 1289.659058,672.378906 1289.205933,659.870300
	C1288.463989,639.391541 1279.686035,623.510437 1261.511597,613.965210
	C1251.445312,608.678284 1240.400391,605.256714 1229.802979,600.978638
	C1223.202881,598.314331 1216.132812,596.416992 1210.119751,592.777588
	C1196.780151,584.704041 1197.621582,567.596191 1211.828125,560.993652
	C1217.434082,558.388306 1224.342651,557.527649 1230.620728,557.658447
	C1241.870728,557.892822 1251.622681,563.152344 1261.000854,569.937256
	C1267.355347,557.473877 1273.582764,545.259949 1279.793579,533.078674
	C1262.918335,522.539917 1232.611328,515.949219 1210.275757,517.813965
	C1174.746216,520.780273 1152.633423,542.679382 1149.404419,578.096985
	C1146.984619,604.638672 1158.850586,624.121094 1183.592529,633.907288
	C1190.228027,636.531982 1197.129517,638.487976 1203.749634,641.147461
	C1212.197266,644.541260 1220.997925,647.488281 1228.713501,652.166504
	C1239.314209,658.594116 1240.544067,672.691589 1232.482544,682.174011
	C1227.383057,688.172241 1220.641235,690.490784 1213.076294,691.037537
	C1197.008789,692.198975 1183.041504,686.609375 1170.150391,677.621033
	C1167.484009,675.761963 1164.907593,673.773743 1162.011475,671.639893
	C1155.066406,684.728699 1148.324341,697.434937 1141.620483,710.069153
	C1167.176514,728.895020 1205.515991,736.619263 1236.184937,729.335388
	C1255.972412,724.635864 1272.267944,715.056580 1282.398560,695.749390
z"
            />
            <path
                fill="#F3F3F3"
                opacity="1.000000"
                stroke="none"
                d="
M513.862000,518.046021
	C519.804565,516.851074 521.981689,519.751404 523.841064,524.519775
	C538.137085,561.181946 552.690063,597.743958 567.176270,634.331848
	C567.830017,635.982910 568.609680,637.584229 569.746338,640.145264
	C586.173767,598.966248 602.268860,558.620178 618.394897,518.196594
	C639.670471,518.196594 660.794189,518.196594 682.445129,518.196594
	C681.786316,520.008240 681.300659,521.549988 680.675537,523.033020
	C652.105408,590.813904 623.497986,658.579163 595.014160,726.396240
	C593.544006,729.896606 591.987732,731.435425 587.858032,731.314331
	C575.369995,730.948181 562.863281,731.096069 550.366333,731.246155
	C547.508545,731.280457 546.045471,730.539856 544.871643,727.737854
	C516.129456,659.128540 487.271576,590.567627 458.446503,521.993042
	C457.946320,520.803101 457.594513,519.550781 457.076569,518.046021
	C476.071808,518.046021 494.728302,518.046021 513.862000,518.046021
M599.045227,703.477295
	C624.350281,643.476746 649.655334,583.476257 675.121155,523.094482
	C658.446899,523.094482 642.617249,523.237854 626.793701,522.990051
	C623.006348,522.930725 621.346497,524.125732 619.976196,527.597290
	C604.067444,567.900757 587.990479,608.137756 571.944031,648.386780
	C571.300354,650.001282 570.517212,651.560120 569.550171,653.693909
	C568.825989,651.962952 568.363831,650.905884 567.938232,649.834229
	C551.698792,608.946655 535.422058,568.073853 519.306702,527.137512
	C518.082153,524.026978 516.702942,522.880859 513.284912,522.996704
	C504.630524,523.289795 495.959564,523.094299 487.295258,523.094482
	C479.900574,523.094604 472.505859,523.094482 464.748596,523.094482
	C465.095184,524.373291 465.193756,525.018127 465.438873,525.601379
	C493.095612,591.414795 520.744629,657.231445 548.552246,722.981079
	C549.161621,724.421814 551.570129,725.947510 553.173889,725.985718
	C564.164612,726.247803 575.165833,726.032715 586.160583,726.189758
	C588.739197,726.226562 589.979858,725.376587 590.907043,723.038757
	C593.416443,716.712158 596.161682,710.479065 599.045227,703.477295
z"
            />
            <path
                fill="#F2F2F2"
                opacity="1.000000"
                stroke="none"
                d="
M1096.704834,672.277527
	C1110.923706,681.643921 1116.743042,696.931702 1112.160645,712.279785
	C1107.788452,726.924011 1094.721558,736.840576 1079.559814,737.020996
	C1063.703003,737.209717 1050.367310,727.630554 1045.657227,712.668396
	C1041.451294,699.307739 1045.558960,684.255310 1056.337280,675.656372
	C1068.778320,665.730835 1082.293945,664.861633 1096.704834,672.277527
M1098.314087,724.866516
	C1107.568604,716.241638 1110.763550,705.746277 1107.229858,693.616211
	C1103.912231,682.227722 1096.022095,675.194336 1084.420654,673.105103
	C1072.269531,670.917053 1062.006836,674.811218 1054.621338,684.912415
	C1046.054199,696.629578 1047.514893,713.092957 1057.738403,723.315796
	C1068.503784,734.080261 1084.434692,734.880066 1098.314087,724.866516
z"
            />
            <path
                fill="#010101"
                opacity="0.000000"
                stroke="none"
                d="
M1402.855469,344.401123
	C1409.936890,382.145111 1385.872803,409.807159 1357.826416,417.442566
	C1326.769775,425.897491 1293.779297,410.204590 1282.016602,381.189056
	C1270.104370,351.804779 1281.728638,318.821960 1309.685547,303.547638
	C1345.795654,283.818756 1392.641968,302.382843 1402.855469,344.401123
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M560.837219,298.017700
	C594.897400,309.053894 607.578613,342.220673 600.854736,374.029144
	C594.040710,406.264374 565.696228,423.774811 525.534668,420.482452
	C525.534668,378.808411 525.534668,337.101074 525.534668,294.899963
	C537.267090,294.698517 549.011841,294.166748 560.837219,298.017700
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M1382.367432,685.405396
	C1402.304321,685.405151 1421.781006,685.405151 1441.539917,685.405151
	C1441.539917,699.047485 1441.539917,712.293701 1441.539917,725.830078
	C1403.893799,725.830078 1366.324951,725.830078 1328.570679,725.830078
	C1328.570679,658.288574 1328.570679,590.885193 1328.570679,523.290771
	C1366.254761,523.290771 1403.706543,523.290771 1441.491699,523.290771
	C1441.491699,536.746338 1441.491699,550.123169 1441.491699,563.841553
	C1420.255737,563.841553 1399.142944,563.841553 1377.710693,563.841553
	C1377.710693,577.234924 1377.710693,590.290833 1377.710693,603.788940
	C1397.822021,603.788940 1417.728271,603.788940 1437.856689,603.788940
	C1437.856689,617.483093 1437.856689,630.737488 1437.856689,644.458618
	C1417.833008,644.458618 1397.932617,644.458618 1377.713379,644.458618
	C1377.713379,658.171875 1377.713379,671.426025 1377.713379,685.141846
	C1379.030029,685.224609 1380.468628,685.315125 1382.367432,685.405396
z"
            />
            <path
                fill="#010101"
                opacity="0.000000"
                stroke="none"
                d="
M909.686401,659.002563
	C909.686462,681.485291 909.686462,703.468567 909.686462,725.784485
	C893.441650,725.784485 877.380493,725.784485 860.979858,725.784485
	C860.979858,658.502075 860.979858,591.136475 860.979858,523.453430
	C861.935303,523.346680 863.036377,523.117065 864.137573,523.116394
	C890.457031,523.100952 916.783630,522.765808 943.092041,523.306702
	C951.952942,523.488953 961.139648,524.770935 969.533997,527.517029
	C994.394470,535.649902 1008.847656,557.620605 1008.230957,585.308594
	C1008.076965,592.226379 1007.312012,599.344543 1005.420044,605.968567
	C1000.201111,624.241638 987.037781,634.607422 969.341431,639.873230
	C966.978149,640.576477 964.570740,641.131653 961.393311,641.961975
	C982.886230,670.007263 1004.041687,697.612244 1025.644775,725.801392
	C1023.747192,725.938965 1022.520874,726.104187 1021.294434,726.105408
	C1003.970215,726.122314 986.644775,726.011292 969.322815,726.209229
	C966.011780,726.247070 964.326477,725.077881 962.619385,722.408264
	C946.227173,696.773499 929.690002,671.231384 913.191956,645.664307
	C912.304077,644.288330 911.393677,642.926941 909.686401,640.331482
	C909.686401,647.305725 909.686401,652.904480 909.686401,659.002563
M940.697327,562.732483
	C930.575623,561.382935 920.453918,560.033386 909.944702,558.632202
	C909.944702,578.720520 909.944702,597.275330 909.944702,616.835205
	C919.172424,616.011047 928.246277,616.055969 936.924133,614.224304
	C949.800049,611.506653 957.005127,602.696594 957.927795,590.827759
	C958.955017,577.615051 953.690796,568.727844 940.697327,562.732483
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M752.209412,593.946350
	C752.209412,597.392700 752.209412,600.348877 752.209412,603.687500
	C772.615295,603.687500 792.535217,603.687500 812.727295,603.687500
	C812.727295,617.479675 812.727295,630.882324 812.727295,644.656921
	C792.587524,644.656921 772.643799,644.656921 752.457336,644.656921
	C752.457336,658.284790 752.457336,671.544189 752.457336,685.409790
	C773.714294,685.409790 794.946289,685.409790 816.422729,685.409790
	C816.422729,699.119873 816.422729,712.229492 816.422729,725.731567
	C778.967651,725.731567 741.422974,725.731567 703.527405,725.731567
	C703.527405,658.538086 703.527405,591.157654 703.527405,523.436340
	C741.013916,523.436340 778.571594,523.436340 816.403748,523.436340
	C816.403748,536.824402 816.403748,550.213135 816.403748,563.887451
	C795.127686,563.887451 773.993713,563.887451 752.209412,563.887451
	C752.209412,573.923645 752.209412,583.689880 752.209412,593.946350
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M1282.225586,696.081421
	C1272.267944,715.056580 1255.972412,724.635864 1236.184937,729.335388
	C1205.515991,736.619263 1167.176514,728.895020 1141.620483,710.069153
	C1148.324341,697.434937 1155.066406,684.728699 1162.011475,671.639893
	C1164.907593,673.773743 1167.484009,675.761963 1170.150391,677.621033
	C1183.041504,686.609375 1197.008789,692.198975 1213.076294,691.037537
	C1220.641235,690.490784 1227.383057,688.172241 1232.482544,682.174011
	C1240.544067,672.691589 1239.314209,658.594116 1228.713501,652.166504
	C1220.997925,647.488281 1212.197266,644.541260 1203.749634,641.147461
	C1197.129517,638.487976 1190.228027,636.531982 1183.592529,633.907288
	C1158.850586,624.121094 1146.984619,604.638672 1149.404419,578.096985
	C1152.633423,542.679382 1174.746216,520.780273 1210.275757,517.813965
	C1232.611328,515.949219 1262.918335,522.539917 1279.793579,533.078674
	C1273.582764,545.259949 1267.355347,557.473877 1261.000854,569.937256
	C1251.622681,563.152344 1241.870728,557.892822 1230.620728,557.658447
	C1224.342651,557.527649 1217.434082,558.388306 1211.828125,560.993652
	C1197.621582,567.596191 1196.780151,584.704041 1210.119751,592.777588
	C1216.132812,596.416992 1223.202881,598.314331 1229.802979,600.978638
	C1240.400391,605.256714 1251.445312,608.678284 1261.511597,613.965210
	C1279.686035,623.510437 1288.463989,639.391541 1289.205933,659.870300
	C1289.659058,672.378906 1288.081055,684.436707 1282.225586,696.081421
z"
            />
            <path
                fill="#010101"
                opacity="0.000000"
                stroke="none"
                d="
M598.929321,703.843079
	C596.161682,710.479065 593.416443,716.712158 590.907043,723.038757
	C589.979858,725.376587 588.739197,726.226562 586.160583,726.189758
	C575.165833,726.032715 564.164612,726.247803 553.173889,725.985718
	C551.570129,725.947510 549.161621,724.421814 548.552246,722.981079
	C520.744629,657.231445 493.095612,591.414795 465.438873,525.601379
	C465.193756,525.018127 465.095184,524.373291 464.748596,523.094482
	C472.505859,523.094482 479.900574,523.094604 487.295258,523.094482
	C495.959564,523.094299 504.630524,523.289795 513.284912,522.996704
	C516.702942,522.880859 518.082153,524.026978 519.306702,527.137512
	C535.422058,568.073853 551.698792,608.946655 567.938232,649.834229
	C568.363831,650.905884 568.825989,651.962952 569.550171,653.693909
	C570.517212,651.560120 571.300354,650.001282 571.944031,648.386780
	C587.990479,608.137756 604.067444,567.900757 619.976196,527.597290
	C621.346497,524.125732 623.006348,522.930725 626.793701,522.990051
	C642.617249,523.237854 658.446899,523.094482 675.121155,523.094482
	C649.655334,583.476257 624.350281,643.476746 598.929321,703.843079
z"
            />
            <path
                fill="#020202"
                opacity="0.000000"
                stroke="none"
                d="
M1098.032471,725.095520
	C1084.434692,734.880066 1068.503784,734.080261 1057.738403,723.315796
	C1047.514893,713.092957 1046.054199,696.629578 1054.621338,684.912415
	C1062.006836,674.811218 1072.269531,670.917053 1084.420654,673.105103
	C1096.022095,675.194336 1103.912231,682.227722 1107.229858,693.616211
	C1110.763550,705.746277 1107.568604,716.241638 1098.032471,725.095520
z"
            />
            <path
                fill="#F2F2F2"
                opacity="1.000000"
                stroke="none"
                d="
M941.064453,562.855469
	C953.690796,568.727844 958.955017,577.615051 957.927795,590.827759
	C957.005127,602.696594 949.800049,611.506653 936.924133,614.224304
	C928.246277,616.055969 919.172424,616.011047 909.944702,616.835205
	C909.944702,597.275330 909.944702,578.720520 909.944702,558.632202
	C920.453918,560.033386 930.575623,561.382935 941.064453,562.855469
M926.214294,564.981201
	C922.456909,564.981201 918.699524,564.981201 914.997803,564.981201
	C914.997803,580.581055 914.997803,595.631287 914.997803,611.788391
	C922.515869,610.887451 929.858032,610.729675 936.812988,609.007935
	C947.704651,606.311523 952.947815,598.823425 952.953308,587.998169
	C952.958801,577.155701 947.668762,570.048157 936.885071,566.843628
	C933.732849,565.906860 930.391602,565.606384 926.214294,564.981201
z"
            />
            <path
                fill="#020202"
                opacity="1.000000"
                stroke="none"
                d="
M926.675659,564.997681
	C930.391602,565.606384 933.732849,565.906860 936.885071,566.843628
	C947.668762,570.048157 952.958801,577.155701 952.953308,587.998169
	C952.947815,598.823425 947.704651,606.311523 936.812988,609.007935
	C929.858032,610.729675 922.515869,610.887451 914.997803,611.788391
	C914.997803,595.631287 914.997803,580.581055 914.997803,564.981201
	C918.699524,564.981201 922.456909,564.981201 926.675659,564.997681
z"
            />
        </svg>
    );
}
