type Props = {
    fill?: string;
    background?: string;
};
export function LogoLargeLight({ fill, background }: Props) {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120%"
            viewBox="30 400 1800 300"
        >
            <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M1789.000000,1081.000000
	C1192.666748,1081.000000 596.833557,1081.000000 1.000240,1081.000000
	C1.000160,721.000183 1.000160,361.000336 1.000080,1.000385
	C640.999695,1.000257 1280.999390,1.000257 1920.999268,1.000128
	C1920.999512,360.999634 1920.999512,720.999268 1920.999756,1080.999512
	C1877.166626,1081.000000 1833.333374,1081.000000 1789.000000,1081.000000
M694.048279,509.384857
	C692.031189,518.111694 689.005920,526.752747 688.254211,535.587341
	C687.331238,546.434509 688.425964,557.365234 691.075378,568.141663
	C696.522888,590.299316 708.259460,608.506836 726.116516,622.193054
	C743.925598,635.842468 764.532471,642.598450 787.232910,642.981201
	C805.058411,643.281860 822.307007,641.076721 838.697144,633.837219
	C859.522339,624.638916 875.714478,610.295654 886.307251,589.998108
	C896.902588,569.695984 899.831238,548.186707 896.023987,525.666748
	C891.765564,500.477997 879.428345,480.159515 859.167908,464.701813
	C844.590149,453.579681 828.117798,447.116852 809.867920,444.920074
	C791.407349,442.697937 773.245728,443.378448 755.474731,449.259247
	C740.871948,454.091675 727.979553,461.478577 717.013977,472.614868
	C706.721497,483.067627 699.072510,494.850861 694.048279,509.384857
M78.503059,448.772461
	C73.742775,448.772461 68.982498,448.772461 64.306969,448.772461
	C64.306969,512.150269 64.306969,574.875122 64.306969,637.663208
	C90.135612,637.663208 115.612572,637.844910 141.085007,637.583801
	C153.161102,637.460022 164.759628,634.459534 175.553650,628.978455
	C189.884033,621.701599 202.259628,611.896790 211.554611,598.686951
	C227.771439,575.640076 233.341080,550.283508 227.150253,522.582764
	C222.204742,500.454163 210.642471,482.431366 192.825821,468.579712
	C177.489487,456.656403 160.180023,449.294922 140.497314,448.977905
	C120.169899,448.650452 99.834480,448.820190 78.503059,448.772461
M435.313843,563.840027
	C458.293976,525.696106 481.274109,487.552124 504.514893,448.975525
	C451.956635,448.975525 400.202759,448.975525 348.200897,448.975525
	C348.200897,462.912354 348.200897,476.645386 348.200897,490.802338
	C372.465576,490.802338 396.518707,490.802338 421.307343,490.802338
	C391.494110,539.982849 362.065491,588.528809 332.344452,637.557190
	C387.271729,637.557190 440.849396,637.557190 494.515350,637.557190
	C494.515350,623.512817 494.515350,609.788696 494.515350,595.529297
	C468.479340,595.529297 442.779053,595.529297 416.082855,595.529297
	C422.703918,584.620300 428.818695,574.545410 435.313843,563.840027
M544.009583,475.538879
	C513.580566,509.849518 506.705780,565.638733 542.915039,608.881470
	C569.384460,640.492432 613.607483,654.069275 663.855225,633.118774
	C663.855225,623.630493 663.855164,613.984314 663.855164,604.338135
	C663.855164,594.759033 663.855225,585.179871 663.855225,574.295410
	C652.399780,586.088745 640.043823,593.796265 624.491943,594.785889
	C608.738403,595.788452 595.109741,590.418091 584.652954,578.808533
	C565.827332,557.907593 566.043823,527.302612 584.891846,507.067139
	C592.825500,498.549469 602.903625,493.418518 614.590820,492.064972
	C634.291443,489.783356 650.318481,496.961456 663.848755,513.017334
	C663.848755,492.731781 663.879822,473.981354 663.755554,455.231934
	C663.749390,454.310150 662.576416,452.944855 661.627808,452.547363
	C643.488403,444.946899 624.555908,441.968323 605.061768,444.393921
	C581.325500,447.347443 560.977295,457.479279 544.009583,475.538879
M260.343628,567.499878
	C260.343628,590.898682 260.343628,614.297485 260.343628,637.491943
	C277.359772,637.491943 293.595459,637.491943 309.654114,637.491943
	C309.654114,574.384583 309.654114,511.659637 309.654114,448.981262
	C293.171509,448.981262 277.043213,448.981262 260.343750,448.981262
	C260.343750,488.293396 260.343750,527.396667 260.343628,567.499878
M1604.130859,575.624756
	C1609.834106,583.498962 1605.717285,591.390076 1599.508911,595.362061
	C1591.147217,600.711609 1582.444458,599.639099 1573.783447,597.361389
	C1562.916016,594.503296 1553.585693,588.629150 1545.343018,581.017334
	C1543.616821,579.423218 1541.886353,577.374268 1540.011108,581.015015
	C1532.825439,594.965820 1525.573853,608.882507 1518.321167,622.861633
	C1519.466675,623.650024 1520.524536,624.372864 1521.577148,625.103088
	C1539.050659,637.224609 1558.613037,644.159119 1579.591675,645.709900
	C1590.290894,646.500732 1601.435181,644.970215 1612.023193,642.776062
	C1632.388306,638.555786 1648.535767,627.682922 1657.615723,608.339661
	C1663.119629,596.614441 1664.522583,584.090515 1663.114746,571.122314
	C1661.843628,559.414856 1658.017700,549.017334 1649.885620,540.398315
	C1640.071533,529.996582 1627.466919,524.449402 1614.228394,519.956909
	C1606.326294,517.275330 1598.423218,514.541016 1590.722412,511.343689
	C1586.240234,509.482697 1582.897583,505.647919 1583.247070,500.640015
	C1583.586182,495.779144 1586.175659,492.017059 1591.289429,489.757538
	C1597.603760,486.967590 1604.078369,486.847717 1609.997070,488.816223
	C1618.583252,491.671906 1626.640381,496.118042 1635.294067,500.054535
	C1642.039062,486.903137 1649.283691,472.777466 1656.728271,458.262238
	C1652.546753,456.229004 1648.794312,454.214203 1644.895630,452.539673
	C1620.540405,442.078583 1595.425171,438.099518 1569.467529,445.221466
	C1553.563110,449.585175 1541.927856,459.539490 1534.125977,474.297424
	C1526.198975,489.291992 1525.694214,505.134644 1528.341797,521.125549
	C1529.810669,529.997009 1534.458740,538.071228 1541.091309,544.388123
	C1550.071655,552.940796 1561.391113,557.235413 1572.987549,561.104675
	C1583.648071,564.661621 1594.947998,567.050781 1604.130859,575.624756
M1745.995728,593.816772
	C1745.159180,593.073364 1743.611328,592.344971 1743.595337,591.584229
	C1743.420776,583.304321 1743.486084,575.019348 1743.486084,566.132324
	C1762.135376,566.132324 1780.191406,566.132324 1798.173096,566.132324
	C1798.173096,550.425354 1798.173096,535.337219 1798.173096,519.671387
	C1779.866821,519.671387 1761.830933,519.671387 1743.916016,519.671387
	C1743.916016,510.546143 1743.916016,502.009186 1743.916016,492.965088
	C1763.435181,492.965088 1782.493530,492.965088 1801.533203,492.965088
	C1801.533203,477.358612 1801.533203,462.279449 1801.533203,447.209503
	C1764.035400,447.209503 1726.841064,447.209503 1689.609619,447.209503
	C1689.609619,511.670013 1689.609619,575.739563 1689.609619,639.868469
	C1727.095215,639.868469 1764.296021,639.868469 1801.555908,639.868469
	C1801.555908,624.414185 1801.555908,609.334900 1801.555908,593.819885
	C1783.156128,593.819885 1765.032715,593.819885 1745.995728,593.816772
M1097.481812,446.826630
	C1083.156982,446.826843 1068.831543,446.888855 1054.507568,446.777557
	C1051.476562,446.753998 1050.035522,447.561737 1048.885986,450.707886
	C1044.823486,461.827850 1040.200806,472.742706 1035.823120,483.748291
	C1030.219849,497.834961 1024.649658,511.934906 1019.061951,526.027832
	C1015.216064,535.727539 1011.364563,545.424988 1007.177612,555.974976
	C1006.134827,554.334656 1005.414551,553.515869 1005.036438,552.561523
	C991.571350,518.574707 978.093811,484.592560 964.776611,450.547729
	C963.588318,447.509857 962.006226,446.721313 958.930359,446.756500
	C944.940369,446.916565 930.947449,446.826660 916.955627,446.826660
	C912.998901,446.826660 909.042175,446.826630 904.995544,446.826630
	C905.066650,448.321930 904.836853,449.433197 905.203674,450.288513
	C909.125793,459.432800 913.217407,468.504578 917.116028,477.658661
	C923.688293,493.090729 930.209961,508.544891 936.650757,524.032288
	C943.965210,541.620361 951.115662,559.276611 958.432434,576.863831
	C966.244446,595.641724 974.259766,614.335693 981.979614,633.151123
	C984.027100,638.141296 986.424866,640.843445 992.603149,640.426331
	C1003.049438,639.720947 1013.579102,640.145447 1024.070923,640.291016
	C1026.896729,640.330261 1028.432007,639.647949 1029.610840,636.801575
	C1037.896484,616.794739 1046.424927,596.888550 1054.842773,576.936279
	C1069.817627,541.442200 1084.777344,505.941833 1099.723877,470.435913
	C1102.952759,462.765656 1106.099121,455.060669 1109.509277,446.826630
	C1105.418091,446.826630 1101.946167,446.826630 1097.481812,446.826630
M1401.046143,475.500031
	C1396.862915,468.419220 1391.541016,462.520355 1384.615967,457.873749
	C1372.470093,449.724091 1358.988892,446.883240 1344.701294,446.849152
	C1319.872314,446.789948 1295.042969,446.824890 1270.213867,446.838623
	C1268.783447,446.839417 1267.352905,447.004181 1266.011475,447.087158
	C1266.011475,511.630707 1266.011475,575.717773 1266.011475,639.901855
	C1283.998413,639.901855 1301.714233,639.901855 1320.080933,639.901855
	C1320.080933,618.470642 1320.080933,597.362549 1320.080933,575.224243
	C1333.758545,596.296875 1346.858032,616.272888 1359.663574,636.435730
	C1361.529297,639.373413 1363.395752,640.315369 1366.675171,640.294312
	C1386.004517,640.170105 1405.334961,640.237671 1424.665039,640.223877
	C1426.074585,640.222839 1427.484131,640.088318 1429.695801,639.974548
	C1409.530151,613.688293 1389.853638,588.039490 1370.169434,562.380798
	C1370.539062,562.137390 1370.793457,561.907349 1371.092041,561.785339
	C1371.852783,561.474365 1372.626221,561.190674 1373.406860,560.932922
	C1383.709717,557.530518 1392.832520,552.040283 1398.775269,542.938110
	C1412.864624,521.358215 1412.179321,498.869476 1401.046143,475.500031
M1224.492676,493.229095
	C1228.083008,493.229095 1231.673340,493.229095 1235.129639,493.229095
	C1235.129639,477.380554 1235.129639,462.295258 1235.129639,447.063873
	C1197.742920,447.063873 1160.664185,447.063873 1123.399780,447.063873
	C1123.399780,511.500763 1123.399780,575.703125 1123.399780,639.968384
	C1160.802124,639.968384 1197.889038,639.968384 1235.078613,639.968384
	C1235.078613,624.513184 1235.078613,609.322327 1235.078613,593.659180
	C1215.649170,593.659180 1196.576294,593.659180 1177.522827,593.659180
	C1177.522827,584.291382 1177.522827,575.404907 1177.522827,566.149902
	C1195.819336,566.149902 1213.748901,566.149902 1231.845825,566.149902
	C1231.845825,550.559570 1231.845825,535.348999 1231.845825,519.831421
	C1222.571655,519.831421 1213.600708,519.831482 1204.629883,519.831482
	C1195.544189,519.831482 1186.458374,519.831421 1177.402954,519.831421
	C1177.402954,510.623230 1177.402954,502.186127 1177.402954,493.852783
	C1177.934937,493.600708 1178.223267,493.345154 1178.512207,493.344147
	C1193.505737,493.292175 1208.499146,493.261810 1224.492676,493.229095
M1472.024658,582.165710
	C1470.363159,582.115967 1468.696533,581.955383 1467.041138,582.033325
	C1445.638916,583.040894 1433.862793,599.587036 1436.277588,618.475281
	C1438.245605,633.868774 1451.820557,647.186584 1470.850342,645.358459
	C1487.520996,643.756958 1500.819092,628.348633 1499.912720,612.544617
	C1499.121094,598.742737 1490.544067,585.773865 1472.024658,582.165710
z"
            />
            <path
                fill="#2B2D53"
                opacity="1.000000"
                stroke="none"
                d="
M694.176514,509.003174
	C699.072510,494.850861 706.721497,483.067627 717.013977,472.614868
	C727.979553,461.478577 740.871948,454.091675 755.474731,449.259247
	C773.245728,443.378448 791.407349,442.697937 809.867920,444.920074
	C828.117798,447.116852 844.590149,453.579681 859.167908,464.701813
	C879.428345,480.159515 891.765564,500.477997 896.023987,525.666748
	C899.831238,548.186707 896.902588,569.695984 886.307251,589.998108
	C875.714478,610.295654 859.522339,624.638916 838.697144,633.837219
	C822.307007,641.076721 805.058411,643.281860 787.232910,642.981201
	C764.532471,642.598450 743.925598,635.842468 726.116516,622.193054
	C708.259460,608.506836 696.522888,590.299316 691.075378,568.141663
	C688.425964,557.365234 687.331238,546.434509 688.254211,535.587341
	C689.005920,526.752747 692.031189,518.111694 694.176514,509.003174
M758.979431,582.527771
	C766.984436,589.178528 776.162292,592.948120 786.466431,594.516541
	C821.876038,599.906189 853.129089,565.861938 844.433899,531.205322
	C836.118164,498.060822 798.881104,481.977570 767.708618,498.066528
	C735.690369,514.591980 730.765015,559.183411 758.979431,582.527771
z"
            />
            <path
                fill="#2B2D53"
                opacity="1.000000"
                stroke="none"
                d="
M79.002808,448.772461
	C99.834480,448.820190 120.169899,448.650452 140.497314,448.977905
	C160.180023,449.294922 177.489487,456.656403 192.825821,468.579712
	C210.642471,482.431366 222.204742,500.454163 227.150253,522.582764
	C233.341080,550.283508 227.771439,575.640076 211.554611,598.686951
	C202.259628,611.896790 189.884033,621.701599 175.553650,628.978455
	C164.759628,634.459534 153.161102,637.460022 141.085007,637.583801
	C115.612572,637.844910 90.135612,637.663208 64.306969,637.663208
	C64.306969,574.875122 64.306969,512.150269 64.306969,448.772461
	C68.982498,448.772461 73.742775,448.772461 79.002808,448.772461
M113.562630,522.499817
	C113.562630,546.754089 113.562630,571.008362 113.562630,595.538635
	C136.310532,596.879456 156.464050,593.006775 169.746933,572.998108
	C181.214127,555.724548 180.403366,528.649658 168.884598,512.480408
	C155.976440,494.360779 135.036606,488.580719 113.562477,491.576508
	C113.562477,501.532166 113.562477,511.515961 113.562630,522.499817
z"
            />
            <path
                fill="#2B2D53"
                opacity="1.000000"
                stroke="none"
                d="
M435.123657,564.155273
	C428.818695,574.545410 422.703918,584.620300 416.082855,595.529297
	C442.779053,595.529297 468.479340,595.529297 494.515350,595.529297
	C494.515350,609.788696 494.515350,623.512817 494.515350,637.557190
	C440.849396,637.557190 387.271729,637.557190 332.344452,637.557190
	C362.065491,588.528809 391.494110,539.982849 421.307343,490.802338
	C396.518707,490.802338 372.465576,490.802338 348.200897,490.802338
	C348.200897,476.645386 348.200897,462.912354 348.200897,448.975525
	C400.202759,448.975525 451.956635,448.975525 504.514893,448.975525
	C481.274109,487.552124 458.293976,525.696106 435.123657,564.155273
z"
            />
            <path
                fill="#2C2E53"
                opacity="1.000000"
                stroke="none"
                d="
M544.256958,475.284485
	C560.977295,457.479279 581.325500,447.347443 605.061768,444.393921
	C624.555908,441.968323 643.488403,444.946899 661.627808,452.547363
	C662.576416,452.944855 663.749390,454.310150 663.755554,455.231934
	C663.879822,473.981354 663.848755,492.731781 663.848755,513.017334
	C650.318481,496.961456 634.291443,489.783356 614.590820,492.064972
	C602.903625,493.418518 592.825500,498.549469 584.891846,507.067139
	C566.043823,527.302612 565.827332,557.907593 584.652954,578.808533
	C595.109741,590.418091 608.738403,595.788452 624.491943,594.785889
	C640.043823,593.796265 652.399780,586.088745 663.855225,574.295410
	C663.855225,585.179871 663.855164,594.759033 663.855164,604.338135
	C663.855164,613.984314 663.855225,623.630493 663.855225,633.118774
	C613.607483,654.069275 569.384460,640.492432 542.915039,608.881470
	C506.705780,565.638733 513.580566,509.849518 544.256958,475.284485
z"
            />
            <path
                fill="#2C2E54"
                opacity="1.000000"
                stroke="none"
                d="
M260.343689,566.999878
	C260.343750,527.396667 260.343750,488.293396 260.343750,448.981262
	C277.043213,448.981262 293.171509,448.981262 309.654114,448.981262
	C309.654114,511.659637 309.654114,574.384583 309.654114,637.491943
	C293.595459,637.491943 277.359772,637.491943 260.343628,637.491943
	C260.343628,614.297485 260.343628,590.898682 260.343689,566.999878
z"
            />
            <path
                fill="#35385B"
                opacity="1.000000"
                stroke="none"
                d="
M1603.879761,575.337036
	C1594.947998,567.050781 1583.648071,564.661621 1572.987549,561.104675
	C1561.391113,557.235413 1550.071655,552.940796 1541.091309,544.388123
	C1534.458740,538.071228 1529.810669,529.997009 1528.341797,521.125549
	C1525.694214,505.134644 1526.198975,489.291992 1534.125977,474.297424
	C1541.927856,459.539490 1553.563110,449.585175 1569.467529,445.221466
	C1595.425171,438.099518 1620.540405,442.078583 1644.895630,452.539673
	C1648.794312,454.214203 1652.546753,456.229004 1656.728271,458.262238
	C1649.283691,472.777466 1642.039062,486.903137 1635.294067,500.054535
	C1626.640381,496.118042 1618.583252,491.671906 1609.997070,488.816223
	C1604.078369,486.847717 1597.603760,486.967590 1591.289429,489.757538
	C1586.175659,492.017059 1583.586182,495.779144 1583.247070,500.640015
	C1582.897583,505.647919 1586.240234,509.482697 1590.722412,511.343689
	C1598.423218,514.541016 1606.326294,517.275330 1614.228394,519.956909
	C1627.466919,524.449402 1640.071533,529.996582 1649.885620,540.398315
	C1658.017700,549.017334 1661.843628,559.414856 1663.114746,571.122314
	C1664.522583,584.090515 1663.119629,596.614441 1657.615723,608.339661
	C1648.535767,627.682922 1632.388306,638.555786 1612.023193,642.776062
	C1601.435181,644.970215 1590.290894,646.500732 1579.591675,645.709900
	C1558.613037,644.159119 1539.050659,637.224609 1521.577148,625.103088
	C1520.524536,624.372864 1519.466675,623.650024 1518.321167,622.861633
	C1525.573853,608.882507 1532.825439,594.965820 1540.011108,581.015015
	C1541.886353,577.374268 1543.616821,579.423218 1545.343018,581.017334
	C1553.585693,588.629150 1562.916016,594.503296 1573.783447,597.361389
	C1582.444458,599.639099 1591.147217,600.711609 1599.508911,595.362061
	C1605.717285,591.390076 1609.834106,583.498962 1603.879761,575.337036
M1535.018555,602.586365
	C1531.844604,608.582642 1528.670654,614.578918 1525.401245,620.755432
	C1526.520508,621.596558 1527.383789,622.344421 1528.338379,622.947998
	C1547.423462,635.016479 1568.224243,640.920227 1590.837646,640.423462
	C1604.683472,640.119263 1618.019165,637.644348 1630.161743,630.724548
	C1650.644531,619.051453 1658.802612,600.556335 1658.652466,577.752930
	C1658.505493,555.419861 1647.874268,539.738770 1627.621094,530.819214
	C1618.986694,527.016541 1609.783447,524.526062 1601.009888,521.011719
	C1595.202393,518.685486 1589.177368,516.317200 1584.182617,512.684937
	C1576.700562,507.243805 1576.126221,496.238953 1582.884399,489.878448
	C1586.223145,486.736481 1590.856323,484.250275 1595.317505,483.159912
	C1607.176147,480.261414 1617.958984,484.184967 1628.093872,490.230011
	C1629.738403,491.210876 1631.318237,492.300140 1633.155518,493.486267
	C1638.933838,482.147827 1644.428711,471.365417 1650.039429,460.355774
	C1630.507446,450.704010 1610.282959,445.661835 1588.630249,446.866028
	C1556.201660,448.669464 1534.870361,469.404022 1532.299438,501.750824
	C1530.375122,525.964417 1541.253784,543.261292 1564.005859,551.988464
	C1569.587402,554.129395 1575.335449,555.841614 1580.891479,558.042236
	C1588.443115,561.033203 1596.325806,563.550842 1603.270630,567.627625
	C1614.300171,574.102356 1615.352905,588.041809 1606.298706,597.133118
	C1601.411133,602.040649 1595.221924,603.668091 1588.579834,603.984497
	C1573.523560,604.701843 1560.659912,598.967468 1548.819092,590.301636
	C1547.132080,589.067017 1545.455200,587.818298 1543.631836,586.471313
	C1540.683716,591.959290 1538.009888,596.936462 1535.018555,602.586365
z"
            />
            <path
                fill="#383A5D"
                opacity="1.000000"
                stroke="none"
                d="
M1746.452637,593.818359
	C1765.032715,593.819885 1783.156128,593.819885 1801.555908,593.819885
	C1801.555908,609.334900 1801.555908,624.414185 1801.555908,639.868469
	C1764.296021,639.868469 1727.095215,639.868469 1689.609619,639.868469
	C1689.609619,575.739563 1689.609619,511.670013 1689.609619,447.209503
	C1726.841064,447.209503 1764.035400,447.209503 1801.533203,447.209503
	C1801.533203,462.279449 1801.533203,477.358612 1801.533203,492.965088
	C1782.493530,492.965088 1763.435181,492.965088 1743.916016,492.965088
	C1743.916016,502.009186 1743.916016,510.546143 1743.916016,519.671387
	C1761.830933,519.671387 1779.866821,519.671387 1798.173096,519.671387
	C1798.173096,535.337219 1798.173096,550.425354 1798.173096,566.132324
	C1780.191406,566.132324 1762.135376,566.132324 1743.486084,566.132324
	C1743.486084,575.019348 1743.420776,583.304321 1743.595337,591.584229
	C1743.611328,592.344971 1745.159180,593.073364 1746.452637,593.818359
M1694.492920,599.499817
	C1694.492920,611.254395 1694.492920,623.008972 1694.492920,634.885986
	C1728.879883,634.885986 1762.627563,634.885986 1796.500000,634.885986
	C1796.500000,622.860229 1796.500000,611.101562 1796.500000,598.731812
	C1777.074585,598.731812 1757.866943,598.731812 1738.718262,598.731812
	C1738.718262,585.880920 1738.718262,573.645752 1738.718262,561.021667
	C1757.031494,561.021667 1774.964111,561.021667 1793.111816,561.021667
	C1793.111816,548.887085 1793.111816,537.149841 1793.111816,524.835815
	C1774.860229,524.835815 1756.816284,524.835815 1738.664307,524.835815
	C1738.664307,512.355225 1738.664307,500.453979 1738.664307,488.114563
	C1758.086060,488.114563 1777.182373,488.114563 1796.498413,488.114563
	C1796.498413,475.914734 1796.498413,464.155762 1796.498413,452.251465
	C1762.487915,452.251465 1728.606812,452.251465 1694.492554,452.251465
	C1694.492554,501.231689 1694.492554,549.865723 1694.492920,599.499817
z"
            />
            <path
                fill="#35385B"
                opacity="1.000000"
                stroke="none"
                d="
M1097.978027,446.826630
	C1101.946167,446.826630 1105.418091,446.826630 1109.509277,446.826630
	C1106.099121,455.060669 1102.952759,462.765656 1099.723877,470.435913
	C1084.777344,505.941833 1069.817627,541.442200 1054.842773,576.936279
	C1046.424927,596.888550 1037.896484,616.794739 1029.610840,636.801575
	C1028.432007,639.647949 1026.896729,640.330261 1024.070923,640.291016
	C1013.579102,640.145447 1003.049438,639.720947 992.603149,640.426331
	C986.424866,640.843445 984.027100,638.141296 981.979614,633.151123
	C974.259766,614.335693 966.244446,595.641724 958.432434,576.863831
	C951.115662,559.276611 943.965210,541.620361 936.650757,524.032288
	C930.209961,508.544891 923.688293,493.090729 917.116028,477.658661
	C913.217407,468.504578 909.125793,459.432800 905.203674,450.288513
	C904.836853,449.433197 905.066650,448.321930 904.995544,446.826630
	C909.042175,446.826630 912.998901,446.826660 916.955627,446.826660
	C930.947449,446.826660 944.940369,446.916565 958.930359,446.756500
	C962.006226,446.721313 963.588318,447.509857 964.776611,450.547729
	C978.093811,484.592560 991.571350,518.574707 1005.036438,552.561523
	C1005.414551,553.515869 1006.134827,554.334656 1007.177612,555.974976
	C1011.364563,545.424988 1015.216064,535.727539 1019.061951,526.027832
	C1024.649658,511.934906 1030.219849,497.834961 1035.823120,483.748291
	C1040.200806,472.742706 1044.823486,461.827850 1048.885986,450.707886
	C1050.035522,447.561737 1051.476562,446.753998 1054.507568,446.777557
	C1068.831543,446.888855 1083.156982,446.826843 1097.978027,446.826630
M1028.754517,516.308350
	C1021.623291,534.108337 1014.492065,551.908264 1006.916870,570.816467
	C990.899963,530.440186 975.363403,491.274841 959.852905,452.175232
	C944.028625,452.175232 928.460327,452.175232 912.455994,452.175232
	C913.131592,454.082642 913.597778,455.640167 914.223755,457.130615
	C926.964539,487.464233 939.726440,517.789001 952.478394,548.117920
	C964.071106,575.689575 975.616089,603.281616 987.342163,630.796448
	C988.089844,632.550842 990.179749,634.914062 991.711670,634.964233
	C1002.683777,635.323425 1013.673157,635.156189 1024.797852,635.156189
	C1050.552979,574.068237 1076.217163,513.195862 1102.060669,451.898163
	C1087.057495,451.898163 1072.732666,451.996857 1058.411255,451.822083
	C1055.214111,451.783081 1053.898071,452.880066 1052.751221,455.823608
	C1044.969849,475.794952 1036.948486,495.672791 1028.754517,516.308350
z"
            />
            <path
                fill="#35385B"
                opacity="1.000000"
                stroke="none"
                d="
M1401.240723,475.818115
	C1412.179321,498.869476 1412.864624,521.358215 1398.775269,542.938110
	C1392.832520,552.040283 1383.709717,557.530518 1373.406860,560.932922
	C1372.626221,561.190674 1371.852783,561.474365 1371.092041,561.785339
	C1370.793457,561.907349 1370.539062,562.137390 1370.169434,562.380798
	C1389.853638,588.039490 1409.530151,613.688293 1429.695801,639.974548
	C1427.484131,640.088318 1426.074585,640.222839 1424.665039,640.223877
	C1405.334961,640.237671 1386.004517,640.170105 1366.675171,640.294312
	C1363.395752,640.315369 1361.529297,639.373413 1359.663574,636.435730
	C1346.858032,616.272888 1333.758545,596.296875 1320.080933,575.224243
	C1320.080933,597.362549 1320.080933,618.470642 1320.080933,639.901855
	C1301.714233,639.901855 1283.998413,639.901855 1266.011475,639.901855
	C1266.011475,575.717773 1266.011475,511.630707 1266.011475,447.087158
	C1267.352905,447.004181 1268.783447,446.839417 1270.213867,446.838623
	C1295.042969,446.824890 1319.872314,446.789948 1344.701294,446.849152
	C1358.988892,446.883240 1372.470093,449.724091 1384.615967,457.873749
	C1391.541016,462.520355 1396.862915,468.419220 1401.240723,475.818115
M1372.713867,573.817993
	C1369.074463,569.074097 1365.434937,564.330200 1361.815918,559.612793
	C1362.236206,559.195068 1362.426147,558.846375 1362.696411,558.765198
	C1363.967651,558.383667 1365.262329,558.080627 1366.546143,557.740295
	C1383.559692,553.231506 1396.718018,544.007996 1401.427734,526.315735
	C1403.487427,518.577576 1404.068970,510.180237 1403.788330,502.135712
	C1403.021484,480.155701 1389.346191,462.100677 1368.480713,455.519135
	C1361.450562,453.301605 1353.809937,452.213470 1346.422241,452.082947
	C1322.268066,451.656219 1298.101929,451.905029 1273.940308,451.912170
	C1272.995361,451.912445 1272.050537,452.098785 1271.077148,452.201416
	C1271.077148,513.244202 1271.077148,573.982788 1271.077148,634.818604
	C1285.718140,634.818604 1300.101440,634.818604 1314.944824,634.818604
	C1314.944824,609.119019 1314.944824,583.757324 1314.944824,557.172424
	C1316.536499,559.622070 1317.434204,560.994873 1318.323242,562.373291
	C1333.206177,585.447449 1348.124634,608.499023 1362.917114,631.631104
	C1364.512085,634.125122 1366.132202,635.273743 1369.235107,635.238281
	C1384.563232,635.063110 1399.894531,635.166748 1415.224609,635.143433
	C1416.427490,635.141602 1417.630005,634.920898 1419.427490,634.743103
	C1403.705444,614.259338 1388.411499,594.333069 1372.713867,573.817993
z"
            />
            <path
                fill="#34365A"
                opacity="1.000000"
                stroke="none"
                d="
M1223.992676,493.229126
	C1208.499146,493.261810 1193.505737,493.292175 1178.512207,493.344147
	C1178.223267,493.345154 1177.934937,493.600708 1177.402954,493.852783
	C1177.402954,502.186127 1177.402954,510.623230 1177.402954,519.831421
	C1186.458374,519.831421 1195.544189,519.831482 1204.629883,519.831482
	C1213.600708,519.831482 1222.571655,519.831421 1231.845825,519.831421
	C1231.845825,535.348999 1231.845825,550.559570 1231.845825,566.149902
	C1213.748901,566.149902 1195.819336,566.149902 1177.522827,566.149902
	C1177.522827,575.404907 1177.522827,584.291382 1177.522827,593.659180
	C1196.576294,593.659180 1215.649170,593.659180 1235.078613,593.659180
	C1235.078613,609.322327 1235.078613,624.513184 1235.078613,639.968384
	C1197.889038,639.968384 1160.802124,639.968384 1123.399780,639.968384
	C1123.399780,575.703125 1123.399780,511.500763 1123.399780,447.063873
	C1160.664185,447.063873 1197.742920,447.063873 1235.129639,447.063873
	C1235.129639,462.295258 1235.129639,477.380554 1235.129639,493.229095
	C1231.673340,493.229095 1228.083008,493.229095 1223.992676,493.229126
M1149.500000,451.896881
	C1142.545898,451.896881 1135.591797,451.896881 1128.498291,451.896881
	C1128.498291,513.213989 1128.498291,574.065491 1128.498291,634.825195
	C1162.590210,634.825195 1196.333252,634.825195 1230.044678,634.825195
	C1230.044678,622.747437 1230.044678,611.007568 1230.044678,598.799683
	C1210.676025,598.799683 1191.603394,598.799683 1172.370483,598.799683
	C1172.370483,586.095398 1172.370483,573.866150 1172.370483,561.086792
	C1190.694458,561.086792 1208.743164,561.086792 1226.834473,561.086792
	C1226.834473,548.787048 1226.834473,537.040833 1226.834473,524.758545
	C1208.534058,524.758545 1190.481323,524.758545 1172.425293,524.758545
	C1172.425293,512.321777 1172.425293,500.426819 1172.425293,488.055237
	C1191.833252,488.055237 1210.909302,488.055237 1230.093750,488.055237
	C1230.093750,475.831848 1230.093750,464.092468 1230.093750,451.896881
	C1203.395508,451.896881 1176.947754,451.896881 1149.500000,451.896881
z"
            />
            <path
                fill="#35385B"
                opacity="1.000000"
                stroke="none"
                d="
M1472.457520,582.241272
	C1490.544067,585.773865 1499.121094,598.742737 1499.912720,612.544617
	C1500.819092,628.348633 1487.520996,643.756958 1470.850342,645.358459
	C1451.820557,647.186584 1438.245605,633.868774 1436.277588,618.475281
	C1433.862793,599.587036 1445.638916,583.040894 1467.041138,582.033325
	C1468.696533,581.955383 1470.363159,582.115967 1472.457520,582.241272
M1458.673218,588.863647
	C1447.455200,593.690247 1441.175537,603.076416 1441.534180,614.481628
	C1441.886719,625.686401 1448.705444,635.216858 1459.012695,638.910889
	C1469.488525,642.665405 1481.378662,639.548645 1488.561646,631.165283
	C1496.097778,622.369995 1497.024780,609.472839 1490.836548,599.515015
	C1484.377075,589.120300 1472.714233,585.054321 1458.673218,588.863647
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M758.704468,582.301208
	C730.765015,559.183411 735.690369,514.591980 767.708618,498.066528
	C798.881104,481.977570 836.118164,498.060822 844.433899,531.205322
	C853.129089,565.861938 821.876038,599.906189 786.466431,594.516541
	C776.162292,592.948120 766.984436,589.178528 758.704468,582.301208
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M113.562553,521.999756
	C113.562477,511.515961 113.562477,501.532166 113.562477,491.576508
	C135.036606,488.580719 155.976440,494.360779 168.884598,512.480408
	C180.403366,528.649658 181.214127,555.724548 169.746933,572.998108
	C156.464050,593.006775 136.310532,596.879456 113.562630,595.538635
	C113.562630,571.008362 113.562630,546.754089 113.562553,521.999756
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M1535.177246,602.250000
	C1538.009888,596.936462 1540.683716,591.959290 1543.631836,586.471313
	C1545.455200,587.818298 1547.132080,589.067017 1548.819092,590.301636
	C1560.659912,598.967468 1573.523560,604.701843 1588.579834,603.984497
	C1595.221924,603.668091 1601.411133,602.040649 1606.298706,597.133118
	C1615.352905,588.041809 1614.300171,574.102356 1603.270630,567.627625
	C1596.325806,563.550842 1588.443115,561.033203 1580.891479,558.042236
	C1575.335449,555.841614 1569.587402,554.129395 1564.005859,551.988464
	C1541.253784,543.261292 1530.375122,525.964417 1532.299438,501.750824
	C1534.870361,469.404022 1556.201660,448.669464 1588.630249,446.866028
	C1610.282959,445.661835 1630.507446,450.704010 1650.039429,460.355774
	C1644.428711,471.365417 1638.933838,482.147827 1633.155518,493.486267
	C1631.318237,492.300140 1629.738403,491.210876 1628.093872,490.230011
	C1617.958984,484.184967 1607.176147,480.261414 1595.317505,483.159912
	C1590.856323,484.250275 1586.223145,486.736481 1582.884399,489.878448
	C1576.126221,496.238953 1576.700562,507.243805 1584.182617,512.684937
	C1589.177368,516.317200 1595.202393,518.685486 1601.009888,521.011719
	C1609.783447,524.526062 1618.986694,527.016541 1627.621094,530.819214
	C1647.874268,539.738770 1658.505493,555.419861 1658.652466,577.752930
	C1658.802612,600.556335 1650.644531,619.051453 1630.161743,630.724548
	C1618.019165,637.644348 1604.683472,640.119263 1590.837646,640.423462
	C1568.224243,640.920227 1547.423462,635.016479 1528.338379,622.947998
	C1527.383789,622.344421 1526.520508,621.596558 1525.401245,620.755432
	C1528.670654,614.578918 1531.844604,608.582642 1535.177246,602.250000
z"
            />
            <path
                fill="#FDFDFE"
                opacity="1.000000"
                stroke="none"
                d="
M1694.492676,598.999756
	C1694.492554,549.865723 1694.492554,501.231689 1694.492554,452.251465
	C1728.606812,452.251465 1762.487915,452.251465 1796.498413,452.251465
	C1796.498413,464.155762 1796.498413,475.914734 1796.498413,488.114563
	C1777.182373,488.114563 1758.086060,488.114563 1738.664307,488.114563
	C1738.664307,500.453979 1738.664307,512.355225 1738.664307,524.835815
	C1756.816284,524.835815 1774.860229,524.835815 1793.111816,524.835815
	C1793.111816,537.149841 1793.111816,548.887085 1793.111816,561.021667
	C1774.964111,561.021667 1757.031494,561.021667 1738.718262,561.021667
	C1738.718262,573.645752 1738.718262,585.880920 1738.718262,598.731812
	C1757.866943,598.731812 1777.074585,598.731812 1796.500000,598.731812
	C1796.500000,611.101562 1796.500000,622.860229 1796.500000,634.885986
	C1762.627563,634.885986 1728.879883,634.885986 1694.492920,634.885986
	C1694.492920,623.008972 1694.492920,611.254395 1694.492676,598.999756
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M1028.879639,515.944580
	C1036.948486,495.672791 1044.969849,475.794952 1052.751221,455.823608
	C1053.898071,452.880066 1055.214111,451.783081 1058.411255,451.822083
	C1072.732666,451.996857 1087.057495,451.898163 1102.060669,451.898163
	C1076.217163,513.195862 1050.552979,574.068237 1024.797852,635.156189
	C1013.673157,635.156189 1002.683777,635.323425 991.711670,634.964233
	C990.179749,634.914062 988.089844,632.550842 987.342163,630.796448
	C975.616089,603.281616 964.071106,575.689575 952.478394,548.117920
	C939.726440,517.789001 926.964539,487.464233 914.223755,457.130615
	C913.597778,455.640167 913.131592,454.082642 912.455994,452.175232
	C928.460327,452.175232 944.028625,452.175232 959.852905,452.175232
	C975.363403,491.274841 990.899963,530.440186 1006.916870,570.816467
	C1014.492065,551.908264 1021.623291,534.108337 1028.879639,515.944580
z"
            />
            <path
                fill="#FEFEFE"
                opacity="1.000000"
                stroke="none"
                d="
M1372.915649,574.112427
	C1388.411499,594.333069 1403.705444,614.259338 1419.427490,634.743103
	C1417.630005,634.920898 1416.427490,635.141602 1415.224609,635.143433
	C1399.894531,635.166748 1384.563232,635.063110 1369.235107,635.238281
	C1366.132202,635.273743 1364.512085,634.125122 1362.917114,631.631104
	C1348.124634,608.499023 1333.206177,585.447449 1318.323242,562.373291
	C1317.434204,560.994873 1316.536499,559.622070 1314.944824,557.172424
	C1314.944824,583.757324 1314.944824,609.119019 1314.944824,634.818604
	C1300.101440,634.818604 1285.718140,634.818604 1271.077148,634.818604
	C1271.077148,573.982788 1271.077148,513.244202 1271.077148,452.201416
	C1272.050537,452.098785 1272.995361,451.912445 1273.940308,451.912170
	C1298.101929,451.905029 1322.268066,451.656219 1346.422241,452.082947
	C1353.809937,452.213470 1361.450562,453.301605 1368.480713,455.519135
	C1389.346191,462.100677 1403.021484,480.155701 1403.788330,502.135712
	C1404.068970,510.180237 1403.487427,518.577576 1401.427734,526.315735
	C1396.718018,544.007996 1383.559692,553.231506 1366.546143,557.740295
	C1365.262329,558.080627 1363.967651,558.383667 1362.696411,558.765198
	C1362.426147,558.846375 1362.236206,559.195068 1361.815918,559.612793
	C1365.434937,564.330200 1369.074463,569.074097 1372.915649,574.112427
M1324.514648,536.034607
	C1330.429199,535.282837 1336.557617,535.266907 1342.212402,533.622131
	C1353.098389,530.455750 1358.962280,521.695862 1358.979858,510.333984
	C1358.997437,498.850220 1353.401245,489.988190 1342.283325,487.117126
	C1333.595703,484.873688 1324.376099,484.690399 1314.989746,483.542389
	C1314.989746,501.842682 1314.989746,518.765625 1314.989746,536.035583
	C1317.917725,536.035583 1320.731323,536.035583 1324.514648,536.034607
z"
            />
            <path
                fill="#FEFEFF"
                opacity="1.000000"
                stroke="none"
                d="
M1150.000000,451.896881
	C1176.947754,451.896881 1203.395508,451.896881 1230.093750,451.896881
	C1230.093750,464.092468 1230.093750,475.831848 1230.093750,488.055237
	C1210.909302,488.055237 1191.833252,488.055237 1172.425293,488.055237
	C1172.425293,500.426819 1172.425293,512.321777 1172.425293,524.758545
	C1190.481323,524.758545 1208.534058,524.758545 1226.834473,524.758545
	C1226.834473,537.040833 1226.834473,548.787048 1226.834473,561.086792
	C1208.743164,561.086792 1190.694458,561.086792 1172.370483,561.086792
	C1172.370483,573.866150 1172.370483,586.095398 1172.370483,598.799683
	C1191.603394,598.799683 1210.676025,598.799683 1230.044678,598.799683
	C1230.044678,611.007568 1230.044678,622.747437 1230.044678,634.825195
	C1196.333252,634.825195 1162.590210,634.825195 1128.498291,634.825195
	C1128.498291,574.065491 1128.498291,513.213989 1128.498291,451.896881
	C1135.591797,451.896881 1142.545898,451.896881 1150.000000,451.896881
z"
            />
            <path
                fill="#FDFDFE"
                opacity="1.000000"
                stroke="none"
                d="
M1459.043457,588.711792
	C1472.714233,585.054321 1484.377075,589.120300 1490.836548,599.515015
	C1497.024780,609.472839 1496.097778,622.369995 1488.561646,631.165283
	C1481.378662,639.548645 1469.488525,642.665405 1459.012695,638.910889
	C1448.705444,635.216858 1441.886719,625.686401 1441.534180,614.481628
	C1441.175537,603.076416 1447.455200,593.690247 1459.043457,588.711792
z"
            />
            <path
                fill="#34365A"
                opacity="1.000000"
                stroke="none"
                d="
M1324.029785,536.035095
	C1320.731323,536.035583 1317.917725,536.035583 1314.989746,536.035583
	C1314.989746,518.765625 1314.989746,501.842682 1314.989746,483.542389
	C1324.376099,484.690399 1333.595703,484.873688 1342.283325,487.117126
	C1353.401245,489.988190 1358.997437,498.850220 1358.979858,510.333984
	C1358.962280,521.695862 1353.098389,530.455750 1342.212402,533.622131
	C1336.557617,535.266907 1330.429199,535.282837 1324.029785,536.035095
M1348.118164,524.664856
	C1358.816284,513.089050 1354.759277,495.766052 1339.917236,491.538727
	C1333.688721,489.764709 1326.937744,489.825470 1320.127441,489.016876
	C1320.127441,503.634277 1320.127441,517.163696 1320.127441,530.901428
	C1329.942505,531.119873 1339.421875,531.422974 1348.118164,524.664856
z"
            />
            <path
                fill="#FDFDFE"
                opacity="1.000000"
                stroke="none"
                d="
M1347.834351,524.900024
	C1339.421875,531.422974 1329.942505,531.119873 1320.127441,530.901428
	C1320.127441,517.163696 1320.127441,503.634277 1320.127441,489.016876
	C1326.937744,489.825470 1333.688721,489.764709 1339.917236,491.538727
	C1354.759277,495.766052 1358.816284,513.089050 1347.834351,524.900024
z"
            />
        </svg>
    );
}
