import { useContext, useEffect, useState } from 'react';
import { Typography, Container } from '@mui/material';

import { Loading, StatsVisualization } from '../components';

import { AccessTokenContext, AccessTokenContextType } from '../contexts';
import { getStatsByOwner } from '../utils';
import { useAuth0User } from '../hooks';
import { Statistics } from '../typings';
import { useTranslation } from 'react-i18next';

export function ResearcherEarnings() {
    const [loaded, setLoaded] = useState(false);
    const [stats, setStats] = useState<null | Statistics>(null);
    const { t } = useTranslation();
    const { user } = useAuth0User();
    const { accessToken } = useContext(
        AccessTokenContext
    ) as AccessTokenContextType;

    useEffect(() => {
        (async () => {
            try {
                if (!loaded) {
                    const result = await getStatsByOwner(
                        accessToken,
                        user.user_id ?? user.sub
                    );
                    setStats(result);
                    setLoaded(true);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return !loaded || !stats ? (
        <Loading />
    ) : (
        <Container maxWidth="md" sx={{ height: '100vh' }}>
            <Typography
                variant="h6"
                style={{
                    margin: '5rem',
                    textAlign: 'center',
                    textTransform: 'uppercase'
                }}
            >
                {t('views.earnings.header')}
            </Typography>
            <StatsVisualization
                data={stats}
                earningChannel={0.1}
                earningVideo={0.2}
            />
        </Container>
    );
}
