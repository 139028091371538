import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route } from 'react-router-dom';
import { Box, ThemeProvider } from '@mui/material';

import { ProtectedRoutes } from './routes/ProtectedRoutes';
import { NewViewer, NewResearcher, ErrorPage, LandingPage } from './views';
import { useInitAuth0User } from './hooks';
import { darkTheme, lightTheme } from './components/ToggleTheme';

function App() {
    const { user: auth0User, isAuthenticated, isLoading } = useAuth0();
    const { user, setUser, accessToken, setAccessToken } = useInitAuth0User(
        auth0User?.sub ?? null
    );
    const [isDarkMode, setIsDarkMode] = useState(false);
    const theme = isDarkMode ? darkTheme : lightTheme;

    useEffect(() => {
        const v = localStorage.getItem('dizcovertheme') ?? false;
        document.body.style.backgroundColor =
            v === 'true' ? '#2B2D53' : '#ffffff';
        setIsDarkMode(v === 'true' ? true : false);
    }, [isDarkMode]);

    if (isLoading) {
        return <></>;
    }

    if (isAuthenticated && !accessToken && !user) {
        return <></>;
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ backgroundColor: theme.palette.background.default }}>
                {isAuthenticated && accessToken && user ? (
                    <ProtectedRoutes
                        isDarkMode={isDarkMode}
                        setIsDarkMode={setIsDarkMode}
                        user={user}
                        setUser={setUser}
                        accessToken={accessToken}
                        setAccessToken={setAccessToken}
                    />
                ) : (
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route
                            path="/new-researcher"
                            element={<NewResearcher />}
                        />
                        <Route path="/new-viewer" element={<NewViewer />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                )}
            </Box>
        </ThemeProvider>
    );
}
export default App;
