import { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';

type Props = {
    message: string;
    duration: number;
    onClose: () => void;
};
export function MessageSnackbar({ message, duration, onClose }: Props) {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
            onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={() => {
                setOpen(false);
                onClose();
            }}
            message={message}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
    );
}
