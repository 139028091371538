import { useState, ChangeEvent, useReducer, Dispatch } from 'react';
import {
    useMediaQuery,
    Box,
    Button,
    TextField,
    Backdrop,
    useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
    FormVideo,
    FormActionVideo,
    DispatchAction,
    videoFormReducer
} from '../reducers';
import { useAuth0User } from '../hooks';
import { Loading, TagSelector } from '../components';
import { Content } from '../typings';

type Props = {
    setIsUpdateContentVideoModalOpen: (x: boolean) => void;
    item: Content;
    setItem: (x: null | Content) => void;
    setContent: (c: Content[]) => void;
    content: Content[];
    index: number;
};
export function UpdateContentVideo({
    index,
    content,
    setContent,
    item,
    setItem,
    setIsUpdateContentVideoModalOpen
}: Props) {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [errorMessage, _setErrorMessage] = useState('');
    const [formErrors, _setFormErrors] = useState<Record<string, string>>({});
    const { user } = useAuth0User();
    const { putContent } = useAuth0User();
    const [selectedTags, setSelectedTags] = useState<string[]>(
        item?.tags ?? []
    );
    const theme = useTheme();
    const [video, dispatch]: [FormVideo, Dispatch<FormActionVideo>] =
        useReducer(videoFormReducer, {
            title: item.title,
            description: item.description
        });
    const [isLoadingPicture, setIsLoadingPicture] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

    const handleSubmit = async () => {
        try {
            if (!isLoadingPicture && !isLoadingUpdate) {
                setIsLoadingUpdate(true);
                // fix me!!
                const result = await putContent({
                    ...item,
                    contentType: 'video',
                    channel_name: user.user_metadata?.channel_name,
                    title: video.title,
                    description: video.description,
                    tags: selectedTags
                });
                dispatch({
                    type: DispatchAction.RESET,
                    payload: {
                        title: '',
                        description: ''
                    }
                });
                setIsLoadingUpdate(false);
                setItem(null);
                setIsUpdateContentVideoModalOpen(false);
                content[index] = {
                    ...item,
                    contentType: 'video',
                    channel_name: user.user_metadata?.channel_name ?? '',
                    title: video.title,
                    description: video.description,
                    tags: []
                };
                setContent(content);
                //setShowMessage(true);
            }
        } catch (e) {
            setIsLoadingUpdate(false);
            //const errorBody = t('components.user_form.dialog_error_body');
            //setErrorMessage('Error');
        }
    };

    if (errorMessage) {
        // <Dialog
        //     title={t('components.user_form.dialog_error_header')}
        // message={errorMessage}
        // setMessage={setErrorMessage}
        // />
        return <div>errorMessage</div>;
    }

    if (isLoadingPicture) {
        return <Loading />;
    }

    return isSmallScreen ? (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: '100%'
            }}
            open={true}
        >
            <Box
                sx={{
                    width: '80%',
                    padding: '2rem',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary
                }}
            >
                {t('views.content.update.legend')}
                <Box sx={{ marginBottom: '2rem' }}>
                    <video
                        style={{ width: '100%' }}
                        controls
                        preload="metadata"
                    >
                        <source src={item.url} type="video/mp4" />
                    </video>
                </Box>
                <TextField
                    label={t('views.content.title')}
                    type="text"
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    id={'title'}
                    name={'title'}
                    value={video.title}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { title: e.target.value }
                        })
                    }
                    error={!!formErrors.title}
                    helperText={formErrors.title}
                />
                <TextField
                    label={t('views.content.description')}
                    multiline
                    rows={5}
                    type="text"
                    id={'description'}
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    name={'description'}
                    value={video.description}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { description: e.target.value }
                        })
                    }
                    error={!!formErrors.description}
                    helperText={formErrors.description}
                />
                <TagSelector
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    predefinedTags={[
                        'Physics',
                        'English',
                        'Chemistry',
                        'Philosophy',
                        'Computer Science',
                        'Art',
                        'History',
                        'Economics',
                        'Mathematics'
                    ]}
                />
                <div style={{ marginTop: '2rem', width: '100%' }}>
                    <Button
                        size="small"
                        onClick={() => setIsUpdateContentVideoModalOpen(false)}
                    >
                        {t('views.content.cancel')}
                    </Button>
                    <Button
                        sx={{ float: 'right' }}
                        disabled={
                            isLoadingPicture ||
                            isLoadingUpdate ||
                            Object.keys(formErrors).length > 0 ||
                            [video.title, video.description].some(
                                (x: string) => x.length === 0
                            )
                        }
                        size="small"
                        onClick={handleSubmit}
                    >
                        {t('views.form.submit')}
                    </Button>
                </div>
            </Box>
        </Backdrop>
    ) : (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={true}
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.background.default,
                    padding: '2rem'
                }}
            >
                <fieldset
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <legend style={{ color: theme.palette.text.primary }}>
                        {t('views.content.update.legend')}
                    </legend>
                    <Box sx={{ marginBottom: '2rem' }}>
                        <video
                            style={{ width: '640px', height: '480px' }}
                            controls
                            preload="metadata"
                        >
                            <source src={item.url} type="video/mp4" />
                        </video>
                    </Box>
                    <TextField
                        label={t('views.content.title')}
                        type="text"
                        sx={{ width: '100%', marginBottom: '1rem' }}
                        id={'title'}
                        name={'title'}
                        value={video.title}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            dispatch({
                                type: DispatchAction.UPDATE,
                                payload: { title: e.target.value }
                            })
                        }
                        error={!!formErrors.title}
                        helperText={formErrors.title}
                    />
                    <TextField
                        label={t('views.content.description')}
                        type="text"
                        id={'description'}
                        sx={{ width: '100%', marginBottom: '1rem' }}
                        name={'description'}
                        value={video.description}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            dispatch({
                                type: DispatchAction.UPDATE,
                                payload: { description: e.target.value }
                            })
                        }
                        error={!!formErrors.description}
                        helperText={formErrors.description}
                    />

                    <TagSelector
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        predefinedTags={[
                            'Physics',
                            'English',
                            'Chemistry',
                            'Philosophy',
                            'Computer Science',
                            'Art',
                            'History',
                            'Economics',
                            'Mathematics'
                        ]}
                    />
                    <div style={{ marginTop: '2rem', width: '100%' }}>
                        <Button
                            size="small"
                            onClick={() =>
                                setIsUpdateContentVideoModalOpen(false)
                            }
                        >
                            {t('views.content.cancel')}
                        </Button>
                        <Button
                            sx={{ float: 'right' }}
                            disabled={
                                isLoadingPicture ||
                                isLoadingUpdate ||
                                Object.keys(formErrors).length > 0 ||
                                [video.title, video.description].some(
                                    (x: string) => x.length === 0
                                )
                            }
                            size="small"
                            onClick={handleSubmit}
                        >
                            {t('views.form.submit')}
                        </Button>
                    </div>
                </fieldset>
            </Box>
        </Backdrop>
    );
}
