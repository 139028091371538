import * as yup from 'yup';

// fix matches
export const validationSchema = yup.object().shape({
    name: yup
        .string()
        .matches(
            /^[a-zåäöÅÄÖA-Z0-9-.\s]+$/,
            'Name can only contain letters and numbers'
        )
        .required('Name is required'),
    nickname: yup
        .string()
        .matches(
            /^[a-zA-Z0-9-.\s]+$/,
            'Nickname can only contain letters and numbers'
        )
        .required('Nickname is required'),
    title: yup
        .string()
        .matches(
            /^[a-zA-Z0-9-.\s]+$/,
            'Title can only contain letters and numbers'
        )
        .required('Title is required'),
    tasks: yup
        .string()
        .matches(
            /^[a-zA-Z0-9-.\s]+$/,
            'Tasks can only contain letters and numbers'
        )
        .required('Tasks are required'),
    email: yup
        .string()
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Must be a valid email adress'
        )
        .required('Tasks are required'),
    password1: yup
        .string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
            'At least one lower- and uppercase letter, one digit and one special character. Length: > 8 characters'
        )
        .required('Tasks are required')
});

export const validationSchemaViewerUpdate = yup.object().shape({
    name: yup
        .string()
        .matches(
            /^[a-zåäöÅÄÖA-Z0-9-.\s]+$/,
            'Name can only contain letters and numbers'
        )
        .required('Name is required'),
    nickname: yup
        .string()
        .matches(
            /^[a-zA-Z0-9-.\s]+$/,
            'Nickname can only contain letters and numbers'
        )
        .required('Nickname is required'),
    email: yup
        .string()
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Must be a valid email adress'
        )
        .required('Email are required')
});

export const validationSchemaViewerCreate = yup.object().shape({
    name: yup
        .string()
        .matches(
            /^[a-zA-Z0-9-.\s]+$/,
            'Name can only contain letters and numbers'
        )
        .required('Name is required'),
    nickname: yup
        .string()
        .matches(
            /^[a-zA-Z0-9-.\s]+$/,
            'Nickname can only contain letters and numbers'
        )
        .required('Nickname is required'),
    email: yup
        .string()
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Must be a valid email adress'
        )
        .required('Tasks are required'),
    password1: yup
        .string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
            'At least one lower- and uppercase letter, one digit and one special character. Length: > 8 characters'
        )
        .required('Tasks are required')
});
