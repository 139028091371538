import { useContext, useEffect, useState } from 'react';
import { useMediaQuery, Container, Box } from '@mui/material';

import {
    ResearcherContentTable,
    UpdateContentVideo,
    CreateContentVideo,
    Loading,
    ResearcherContentTableMobile
} from '../components';
import { useAuth0User } from '../hooks';
import { deleteContent, deleteContentMany, getContentByOwner } from '../utils';
import { AccessTokenContext, AccessTokenContextType } from '../contexts';
import { Content } from '../typings';

export function ResearcherContent() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isNewContentVideoModalOpen, setIsNewContentVideoModalOpen] =
        useState(false);
    const [isUpdateContentVideoModalOpen, setIsUpdateContentVideoModalOpen] =
        useState(false);
    const [content, setContent] = useState<Content[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState<null | Content>(null);
    const { user } = useAuth0User();
    const { accessToken } = useContext(
        AccessTokenContext
    ) as AccessTokenContextType;

    const handleDeleteItem = async (id: string) => {
        await deleteContent(accessToken, id);
        const updatedItems = content.filter((item) => item.id !== id);
        setContent(updatedItems);
    };

    const handleDeleteMany = async (ids: string[]) => {
        await deleteContentMany(accessToken, ids);
        const updatedItems = content.filter(
            (item) => !ids.some((id) => id === item.id)
        );
        setContent(updatedItems);
    };

    const updateContent = (item: Content) => {
        setItem(item);
        setIsUpdateContentVideoModalOpen(true);
    };

    const closeModal = async () => {
        setIsNewContentVideoModalOpen(false);
        try {
            // hookify!!!
            const id =
                user.user_id.split('|')[1] ?? user.sub.split('|')[1] ?? '';
            const result = await getContentByOwner(accessToken, id);
            if (result) {
                setContent(result);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const id =
                    user.user_id.split('|')[1] ?? user.sub.split('|')[1] ?? '';
                const result = await getContentByOwner(accessToken, id);
                if (result) {
                    setContent(result);
                }

                setIsLoading(false);
            } catch (e) {
                console.error(e);
                setIsLoading(false);
            }
        })();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return isMobile ? (
        <>
            {isNewContentVideoModalOpen && (
                <CreateContentVideo closeModal={closeModal} />
            )}
            {isUpdateContentVideoModalOpen && item && (
                <UpdateContentVideo
                    content={content}
                    index={content.findIndex((c: Content) => c.id === item.id)}
                    setContent={setContent}
                    item={item}
                    setItem={setItem}
                    setIsUpdateContentVideoModalOpen={
                        setIsUpdateContentVideoModalOpen
                    }
                />
            )}
            <Box sx={{ marginTop: '5rem' }}>
                <ResearcherContentTableMobile
                    content={content}
                    updateContent={updateContent}
                    setIsNewContentVideoModalOpen={
                        setIsNewContentVideoModalOpen
                    }
                    onDelete={handleDeleteItem}
                />
            </Box>
        </>
    ) : (
        <Container
            maxWidth="xl"
            sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
        >
            {isNewContentVideoModalOpen && (
                <CreateContentVideo closeModal={closeModal} />
            )}
            {isUpdateContentVideoModalOpen && item && (
                <UpdateContentVideo
                    content={content}
                    index={content.findIndex((c: Content) => c.id === item.id)}
                    setContent={setContent}
                    item={item}
                    setItem={setItem}
                    setIsUpdateContentVideoModalOpen={
                        setIsUpdateContentVideoModalOpen
                    }
                />
            )}
            <ResearcherContentTable
                content={content}
                updateContent={updateContent}
                setIsNewContentVideoModalOpen={setIsNewContentVideoModalOpen}
                onDeleteMany={handleDeleteMany}
                onDelete={handleDeleteItem}
            />
        </Container>
    );
}
