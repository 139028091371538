import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMediaQuery, Container, useTheme } from '@mui/material';

import { FormUser } from '../reducers';
import { useNewUser } from '../hooks';
import { ViewerForm } from '../components';
import { useTranslation } from 'react-i18next';

export function NewViewer() {
    const { loginWithRedirect } = useAuth0();
    const { addUser } = useNewUser();
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:600px)');
    const theme = useTheme();

    const redirectToLogin = () => {
        const browserLanguage = navigator.language || 'EN-en';
        const loginUrl = new URL(window.location.href);
        loginUrl.searchParams.set('ui_locales', browserLanguage);
        loginWithRedirect({ appState: { returnTo: loginUrl.toString() } });
    };

    const postNewUser = async (user: Partial<FormUser>) => {
        try {
            if (!isLoadingUpdate) {
                setIsLoadingUpdate(true);
                await addUser({
                    name: user.name,
                    email: user.email,
                    password: user.password1 as string,
                    nickname: user.nickname,
                    picture: user.picture,
                    user_metadata: {
                        has_inited: true,
                        language: user.language,
                        role: 'viewer',
                        subscriptions: []
                    }
                });
                setIsLoadingUpdate(false);
                redirectToLogin();
                //setShowMessage(true);
            }
        } catch (e) {
            setIsLoadingUpdate(false);
            // const errorBody = t('components.user_form.dialog_error_body');
            // setErrorMessage('Error');
        }
    };

    return (
        <Container maxWidth="sm" sx={{ height: '100vh' }}>
            {isMobile ? (
                <ViewerForm action={postNewUser} />
            ) : (
                <fieldset
                    style={{ padding: '2rem', margin: '2rem', width: '500px' }}
                >
                    <legend style={{ color: theme.palette.text.primary }}>
                        {t('views.form.create_account')}
                    </legend>
                    <ViewerForm action={postNewUser} />
                </fieldset>
            )}
        </Container>
    );
}
