import { Typography, useTheme } from '@mui/material';

type Props = {
    text: string;
};
export function Article({ text }: Props) {
    const ps = text.split('\n\n') ?? [];
    const theme = useTheme();

    return ps.length > 0 ? (
        <article>
            {ps.map((p, i) => {
                const t = p.replace(/\*(.*?)\*/g, '<em>$1</em>');
                return (
                    <Typography
                        style={{
                            color: theme.palette.text.primary,
                            marginBottom: '1rem',
                            lineHeight: '1.7rem'
                        }}
                        variant="body1"
                        key={`paragraph-${p.slice(10)}-${i}`}
                    >
                        <span dangerouslySetInnerHTML={{ __html: t }} />
                    </Typography>
                );
            })}
        </article>
    ) : (
        <></>
    );
}
