import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en } from './en';
import { sv } from './sv';
import { sp } from './sp';

export type AvailableLanguages = 'en' | 'sv' | 'sp';

export const userLanguage = localStorage.getItem('language') ?? 'en';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en,
            sv,
            sp
        },
        lng: userLanguage,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export function changeLanguage(lang: AvailableLanguages) {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
}

export default i18n;
