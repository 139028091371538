import { Comment } from '../typings';

function getTimestamp() {
    return new Date().getTime();
}

export function unixTimeToYYMMDD(unixTime: number) {
    const dateObj = new Date(unixTime);
    const year = dateObj.getFullYear().toString().slice(-2);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}
export function convertUnixTimestampToDate(timestamp: string | number) {
    const date = new Date(
        typeof timestamp === 'string' ? parseInt(timestamp, 10) : timestamp
    );
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const dateString = `${year}-${month}-${day}`;
    return dateString;
}

export function getCurrentDate() {
    return convertUnixTimestampToDate(getTimestamp());
}

export function convertImageToBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result;
            resolve(base64String);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}

export function getTimeSince(_timestamp: number): string {
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    const timestamp = Math.floor(_timestamp / 1000);
    const elapsedSeconds = currentTimestamp - timestamp;
    if (elapsedSeconds < 60) {
        return `${Math.floor(elapsedSeconds)} seconds ago`;
    } else if (elapsedSeconds < 3600) {
        return `${Math.floor(elapsedSeconds / 60)} minutes ago`;
    } else if (elapsedSeconds < 86400) {
        return `${Math.floor(elapsedSeconds / 3600)} hours ago`;
    } else if (elapsedSeconds < 604800) {
        return `${Math.floor(elapsedSeconds / 86400)} days ago`;
    } else if (elapsedSeconds < 2419200) {
        return `${Math.floor(elapsedSeconds / 604800)} weeks ago`;
    } else if (elapsedSeconds < 29030400) {
        return `${Math.floor(elapsedSeconds / 2419200)} months ago`;
    } else {
        return `${Math.floor(elapsedSeconds / 29030400)} years ago`;
    }
}

export function formatComments(_comments: Comment[]) {
    const comments = [];
    const tempComments = [];
    for (const c of _comments) {
        if (c.reply_to) {
            tempComments.push(c);
        } else {
            comments.push(c);
        }
    }
    for (const c of tempComments) {
        const index = comments.findIndex((com) => com.id === c.reply_to);
        if (
            index !== -1 &&
            comments[index] &&
            comments[index].replies &&
            Array.isArray(comments[index].replies)
        ) {
            (comments[index].replies as Comment[]).push(c);
        } else {
            comments[index].replies = [c];
        }
    }
    return comments;
}

export function sortDates(a: Comment, b: Comment) {
    if (a.created_at === b.created_at) return 0;
    if (a.created_at > b.created_at) return -1;
    return 1;
}
