import { Box, Typography, useTheme } from '@mui/material';

type Props = {
    children: string;
    goto?: (u: string) => void;
    path?: string;
    logout?: () => void;
};
export function CenteredMenuItem({ goto, path, logout, children }: Props) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: 'underline',
                    color: theme.palette.text.primary
                }
            }}
            onClick={logout ? () => logout() : () => goto && goto(path ?? '/')}
        >
            <Typography
                variant="h4"
                sx={{
                    marginBottom: '2rem',
                    fontSize: '1.7rem',
                    fontWeight: 400,
                    textTransform: 'uppercase',
                    fontFamily: 'Raleway',
                    color: theme.palette.text.primary,
                    textAlign: 'center'
                }}
            >
                {children}
            </Typography>
        </Box>
    );
}
