import { Content } from '../typings';
import { DispatchAction } from './index';

export type FormActionVideo = { type: string; payload: Partial<Content> };

export type FormVideo = Pick<Content, 'title' | 'description'>;

export function videoFormReducer(v: FormVideo, action: FormActionVideo) {
    if (action.type === DispatchAction.UPDATE) {
        return { ...v, ...action.payload } as FormVideo;
    }
    if (action.type === DispatchAction.RESET) {
        return action.payload as FormVideo;
    }
    return v;
}
