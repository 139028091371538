import { Chip, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    predefinedTags: string[];
    selectedTags: string[];
    setSelectedTags: (ts: string[]) => void;
};
export function TagSelector({
    selectedTags,
    setSelectedTags,
    predefinedTags
}: Props) {
    const { t } = useTranslation();
    const handleTagClick = (tag: string) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(
                selectedTags.filter((selectedTag) => selectedTag !== tag)
            );
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '600px' }}>
                {predefinedTags.map((tag) => (
                    <Chip
                        key={tag}
                        label={tag}
                        color={
                            selectedTags.includes(tag) ? 'primary' : 'default'
                        }
                        onClick={() => handleTagClick(tag)}
                        style={{ margin: '0.2rem' }}
                    />
                ))}
            </Box>
            <Box>
                <span style={{ marginRight: '1rem' }}>
                    {t('views.content.choosen_tags')}
                </span>
                {selectedTags.map((tag) => (
                    <Chip
                        key={tag}
                        label={tag}
                        onDelete={() => handleTagClick(tag)}
                        style={{ margin: '0.2rem' }}
                    />
                ))}
            </Box>
        </Box>
    );
}
