import {
    useRef,
    useEffect,
    useState,
    ChangeEvent,
    useContext,
    useReducer,
    Dispatch
} from 'react';
import { useTranslation } from 'react-i18next';
import AWS from 'aws-sdk';

import { postThumbnail } from '../utils';
import {
    FormVideo,
    FormActionVideo,
    DispatchAction,
    videoFormReducer
} from '../reducers';
import { useAuth0User } from '../hooks';
import { AccessTokenContext, AccessTokenContextType } from '../contexts';
import {
    Box,
    Button,
    TextField,
    Backdrop,
    CircularProgress,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { TagSelector, Dialog } from '../components';

AWS.config.update({
    accessKeyId: 'AKIAYWV2DORXWVMYK7JA',
    secretAccessKey: 'oaEyl2nbFzctZIAjwWRS2nB8seJ50u8CBPlqz+5o',
    region: 'eu-central-1' // e.g., 'us-east-1'
});

type Props = {
    closeModal: () => void;
};
export function CreateContentVideo({ closeModal }: Props) {
    const { t } = useTranslation();
    const canvasRef = useRef<null | HTMLCanvasElement>(null);
    const videoRef = useRef<null | HTMLVideoElement>(null);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { accessToken } = useContext(
        AccessTokenContext
    ) as AccessTokenContextType;
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const { user } = useAuth0User();
    const { postContent } = useAuth0User();
    const theme = useTheme();
    const [thumbnailPath, setThumbnailPath] = useState('');
    const [video, dispatch]: [FormVideo, Dispatch<FormActionVideo>] =
        useReducer(videoFormReducer, { title: '', description: '' });
    const [isLoadingPicture, setIsLoadingPicture] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [videoPath, setVideoPath] = useState('');
    const [videoUrlPath, setVideoUrlPath] = useState('');
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const handleSubmit = async () => {
        try {
            if (!isLoadingPicture && !isLoadingUpdate) {
                setIsLoadingUpdate(true);
                await postContent({
                    url: videoPath,
                    thumbnail: thumbnailPath,
                    contentType: 'video',
                    channel_name: user?.user_metadata?.channel_name ?? '',
                    title: video.title,
                    description: video.description,
                    tags: selectedTags
                });
                dispatch({
                    type: DispatchAction.RESET,
                    payload: {
                        title: '',
                        description: ''
                    }
                });
                setVideoPath('');
                setThumbnailPath('');
                setIsLoadingUpdate(false);
                closeModal();
            }
        } catch (e) {
            setIsLoadingUpdate(false);
        }
    };

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        try {
            if (!isLoadingPicture && event.target.files) {
                setIsLoadingPicture(true);
                const file = event.target.files[0];
                const c = URL.createObjectURL(file);
                setVideoUrlPath(c);
                const response = await fetch(
                    'https://nly7ftenzf.execute-api.eu-central-1.amazonaws.com/dev/api/video',
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                );
                const url = await response.json();
                const r = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'video/mp4',
                        'x-amz-acl': 'public-read'
                    },
                    body: file
                });
                const videoUrl = url.split('?')[0];
                setVideoPath(videoUrl ?? '');
                setIsLoadingPicture(false);
            }
        } catch (e) {
            console.log(e);
            setIsLoadingPicture(false);
            setErrorMessage('error');
        }
    };

    useEffect(() => {
        async function f() {
            try {
                if (
                    videoUrlPath.length > 0 &&
                    videoPath.length > 0 &&
                    canvasRef?.current &&
                    videoRef?.current
                ) {
                    const canvas = canvasRef.current;
                    canvas.width = 640;
                    canvas.height = 480;
                    const ctx = canvas.getContext('2d');
                    const [, , , _id] = videoPath.split('/');
                    const id = _id.split('.')[0];
                    if (ctx != null) {
                        videoRef.current.removeEventListener(
                            'canplaythrough',
                            f
                        );
                        await new Promise((resolve) =>
                            setTimeout(resolve, 1000)
                        );
                        ctx.drawImage(videoRef.current, 0, 0, 640, 480);
                        const _dataUrl = canvas.toDataURL('image/jpeg');
                        await postThumbnail(_dataUrl, accessToken, id);
                        setThumbnailPath(
                            `https://dizcover-videos.s3.eu-central-1.amazonaws.com/${id}-thumbnail.jpg`
                        );
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
        if (videoRef.current) {
            videoRef.current?.addEventListener('canplaythrough', f);
        }
        //f();
    }, [videoUrlPath, videoPath, accessToken]);

    if (errorMessage) {
        return (
            <Dialog
                title={t('components.user_form.dialog_error_header')}
                message={errorMessage}
                setMessage={setErrorMessage}
            />
        );
    }

    return isSmallScreen ? (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: '100%'
            }}
            open={true}
        >
            <Box
                sx={{
                    padding: '2rem',
                    width: '80%'
                }}
            >
                <legend style={{ color: 'var(--black)' }}>
                    {t('views.content.create.legend')}
                </legend>
                {isLoadingPicture ? (
                    <Box
                        sx={{
                            width: '640px',
                            height: '480px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    <Box>
                        {videoUrlPath.length > 0 ? (
                            <>
                                <video
                                    ref={videoRef}
                                    style={{
                                        width: '100%'
                                    }}
                                    preload="auto"
                                    controls
                                >
                                    <source
                                        src={videoUrlPath}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                                <canvas
                                    ref={canvasRef}
                                    style={{ display: 'none' }}
                                />
                            </>
                        ) : (
                            <img
                                src={'https://placehold.co/300x200?text=video'}
                            />
                        )}
                    </Box>
                )}
                <Button
                    variant="outlined"
                    style={{
                        width: '250px',
                        marginTop: '1rem',
                        marginBottom: '2rem'
                    }}
                    component="label"
                >
                    {t('views.content.choose_video')}
                    <input
                        type="file"
                        accept="video/mp4"
                        onChange={handleFileChange}
                        hidden
                    />
                </Button>
                <TextField
                    required
                    label={t('views.content.title')}
                    type="text"
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    id={'title'}
                    name={'title'}
                    value={video.title}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { title: e.target.value }
                        })
                    }
                    error={!!formErrors.title}
                    helperText={formErrors.title}
                />
                <TextField
                    required
                    label={t('views.content.description')}
                    multiline
                    rows={5}
                    type="text"
                    sx={{ width: '100%', marginBottom: '1rem' }}
                    id={'description'}
                    name={'description'}
                    value={video.description}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        dispatch({
                            type: DispatchAction.UPDATE,
                            payload: { description: e.target.value }
                        })
                    }
                    error={!!formErrors.description}
                    helperText={formErrors.description}
                />
                <TagSelector
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    predefinedTags={[
                        'Physics',
                        'English',
                        'Chemistry',
                        'Philosophy',
                        'Computer Science',
                        'Art',
                        'History',
                        'Economics',
                        'Mathematics'
                    ]}
                />
                <div style={{ marginTop: '2rem', width: '100%' }}>
                    <Button size="small" onClick={() => closeModal()}>
                        {t('views.content.cancel')}
                    </Button>
                    <Button
                        sx={{ float: 'right' }}
                        disabled={
                            isLoadingPicture ||
                            isLoadingUpdate ||
                            Object.keys(formErrors).length > 0 ||
                            [video.title, video.description].some(
                                (x: string) => x.length === 0
                            )
                        }
                        size="small"
                        onClick={handleSubmit}
                    >
                        {t('views.form.submit')}
                    </Button>
                </div>
            </Box>
        </Backdrop>
    ) : (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={true}
        >
            <Box
                sx={{
                    padding: '2rem',
                    backgroundColor: theme.palette.background.default
                }}
            >
                <fieldset
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <legend style={{ color: theme.palette.text.primary }}>
                        {t('views.content.create.legend')}
                    </legend>
                    {isLoadingPicture ? (
                        <Box
                            sx={{
                                width: '640px',
                                height: '480px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress color="primary" />
                        </Box>
                    ) : (
                        <Box>
                            {videoUrlPath.length > 0 ? (
                                <>
                                    <video
                                        ref={videoRef}
                                        style={{
                                            width: '640px',
                                            height: '480px'
                                        }}
                                        preload="auto"
                                        controls
                                    >
                                        <source
                                            src={videoUrlPath}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                    <canvas
                                        ref={canvasRef}
                                        style={{ display: 'none' }}
                                    />
                                </>
                            ) : (
                                <img
                                    src={
                                        'https://placehold.co/640x480?text=video'
                                    }
                                />
                            )}
                        </Box>
                    )}
                    <Button
                        variant="outlined"
                        style={{
                            width: '250px',
                            marginTop: '1rem',
                            marginBottom: '2rem'
                        }}
                        component="label"
                    >
                        {t('views.content.choose_video')}
                        <input
                            type="file"
                            accept="video/mp4"
                            onChange={handleFileChange}
                            hidden
                        />
                    </Button>
                    <TextField
                        required
                        label={t('views.content.title')}
                        type="text"
                        sx={{ width: '100%', marginBottom: '1rem' }}
                        id={'title'}
                        name={'title'}
                        value={video.title}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            dispatch({
                                type: DispatchAction.UPDATE,
                                payload: { title: e.target.value }
                            })
                        }
                        error={!!formErrors.title}
                        helperText={formErrors.title}
                    />
                    <TextField
                        required
                        label={t('views.content.description')}
                        multiline
                        rows={5}
                        type="text"
                        sx={{ width: '100%', marginBottom: '1rem' }}
                        id={'description'}
                        name={'description'}
                        value={video.description}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            dispatch({
                                type: DispatchAction.UPDATE,
                                payload: { description: e.target.value }
                            })
                        }
                        error={!!formErrors.description}
                        helperText={formErrors.description}
                    />
                    <TagSelector
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        predefinedTags={[
                            'Physics',
                            'English',
                            'Chemistry',
                            'Philosophy',
                            'Computer Science',
                            'Art',
                            'History',
                            'Economics',
                            'Mathematics'
                        ]}
                    />
                    <div style={{ marginTop: '2rem', width: '100%' }}>
                        <Button size="small" onClick={() => closeModal()}>
                            {t('views.content.cancel')}
                        </Button>
                        <Button
                            sx={{ float: 'right' }}
                            disabled={
                                isLoadingPicture ||
                                isLoadingUpdate ||
                                Object.keys(formErrors).length > 0 ||
                                [video.title, video.description].some(
                                    (x: string) => x.length === 0
                                )
                            }
                            size="small"
                            onClick={handleSubmit}
                        >
                            {t('views.form.submit')}
                        </Button>
                    </div>
                </fieldset>
            </Box>
        </Backdrop>
    );
}
