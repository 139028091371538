import React, { useEffect, useState, MutableRefObject } from 'react';
import {
    Box,
    Input,
    useMediaQuery,
    Typography,
    Link,
    useTheme
} from '@mui/material';

import { getTimeSince } from '../utils';
import { Content } from '../typings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TagFilter } from './TagFilter';

type Props = {
    content: Content[];
    predefinedTags: string[];
    activeIndex: number;
    currentlyPlayingVideoRef: null | MutableRefObject<HTMLVideoElement>;
};
export function ViewerContentTableRow({
    currentlyPlayingVideoRef,
    activeIndex,
    content,
    predefinedTags
}: Props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const [sortedData, setSortedData] = useState<Content[]>(content);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const handleTagFilter = (tag: string) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(
                selectedTags.filter((selectedTag) => selectedTag !== tag)
            );
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredData =
        searchTerm.length > 0
            ? sortedData.filter(
                  (item) =>
                      item.title
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                      item.description
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
              )
            : sortedData;

    const filteredByTag =
        selectedTags.length > 0
            ? filteredData.filter((item) =>
                  item.tags.some((i) => selectedTags.includes(i))
              )
            : filteredData;

    useEffect(() => {
        setSortedData(content);
    }, [content]);

    useEffect(() => {
        if (currentlyPlayingVideoRef && currentlyPlayingVideoRef.current) {
            currentlyPlayingVideoRef.current.src =
                filteredByTag[activeIndex].url;
            currentlyPlayingVideoRef.current.play();
        }
    }, [activeIndex, currentlyPlayingVideoRef, filteredByTag]);

    return (
        <>
            <Box
                sx={{
                    marginTop: '5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    flexWrap: 'wrap'
                }}
            >
                <Input
                    sx={{
                        width: '100%',
                        fontSize: isMobile ? '1.2rem' : '1.7rem'
                    }}
                    type="text"
                    placeholder={t('views.table.search') ?? ''}
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </Box>
            <TagFilter
                selectedTags={selectedTags}
                predefinedTags={predefinedTags}
                handleTagFilter={handleTagFilter}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        marginTop: '5rem',
                        paddingBottom: '5rem',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                    }}
                >
                    {filteredByTag.map((item, index) => (
                        <Box
                            key={item.id}
                            sx={{ width: '100%', padding: '1rem' }}
                        >
                            <Box
                                sx={{
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.01)',
                                        cursor: 'pointer',
                                        opacity: 0.9
                                    }
                                }}
                                onClick={() =>
                                    navigate(`/${item.owner}/${item.id}`)
                                }
                                title={`${
                                    item.description.slice(100) ?? ''
                                }...`}
                            >
                                {index === activeIndex ? (
                                    <video
                                        preload="auto"
                                        ref={
                                            index === activeIndex
                                                ? currentlyPlayingVideoRef
                                                : null
                                        }
                                        autoPlay
                                        muted
                                        style={{
                                            width: '100%',
                                            borderRadius: '20px'
                                        }}
                                    >
                                        <source
                                            src={item.url}
                                            type="video/mp4"
                                        />
                                    </video>
                                ) : (
                                    <video
                                        preload="auto"
                                        muted
                                        style={{
                                            width: '100%',
                                            borderRadius: '20px'
                                        }}
                                    >
                                        <source
                                            src={item.url}
                                            type="video/mp4"
                                        />
                                    </video>
                                )}
                            </Box>
                            <Typography
                                variant="h6"
                                sx={{ color: theme.palette.text.primary }}
                            >
                                {item.title}{' '}
                                <span
                                    style={{
                                        fontSize: '1rem',
                                        fontWeight: '400',
                                        color: theme.palette.text.primary,
                                        opacity: 0.5
                                    }}
                                >
                                    {getTimeSince(item.created_at)}
                                </span>
                            </Typography>
                            <Typography variant="body1">
                                <Link
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer',
                                            textDecoration: 'underline'
                                        },
                                        textDecoration: 'none'
                                    }}
                                    onClick={() => navigate(`/${item.owner}`)}
                                >
                                    {item.channel_name}
                                </Link>
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    );
}
