import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { Content } from '../typings';
import { unixTimeToYYMMDD } from '../utils';

type Props = {
    content: Content[];
    onDelete: (ids: string) => void;
    updateContent: (item: Content) => void;
    setIsNewContentVideoModalOpen: (x: boolean) => void;
};

export function ResearcherContentTableMobile({
    content,
    onDelete,
    updateContent,
    setIsNewContentVideoModalOpen
}: Props) {
    const { t } = useTranslation();
    const theme = useTheme();

    const handleDelete = (item: string) => {
        onDelete(item);
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ width: '100%', position: 'relative', top: '2rem' }}>
                <Button
                    size="small"
                    onClick={() => setIsNewContentVideoModalOpen(true)}
                >
                    {t('views.content.upload_content_video')}
                </Button>
            </Box>

            <Box sx={{ marginTop: '3rem' }}>
                {content.map((item) => (
                    <Box key={item.id} sx={{ marginBottom: '1rem' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.primary,
                                marginLeft: '0.2rem'
                            }}
                        >
                            {item.title}{' '}
                            <Button onClick={() => handleDelete(item.id)}>
                                <FaTrash />
                            </Button>
                            <span
                                style={{
                                    float: 'right',
                                    position: 'relative',
                                    right: '10px',
                                    top: '5px',
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    color: theme.palette.text.primary,
                                    opacity: 0.4
                                }}
                            >
                                {unixTimeToYYMMDD(item.created_at)}
                            </span>
                        </Typography>
                        <img
                            style={{ width: '100%' }}
                            src={item.thumbnail}
                            onClick={() => updateContent(item)}
                        />
                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.text.primary,
                                marginLeft: '0.2rem'
                            }}
                        >
                            {`${item.description.slice(0, 100)}...`}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Container>
    );
}
