export const en = {
    translation: {
        views: {
            title: 'dizcover',
            about_us: {
                header: 'About us'
            },
            landing_page: {
                subheader: '[Connecting Sciences with the Real Worlds]',
                header: 'Our mission',
                paragraph: `Our mission is making *research accessible to all*. We support researchers in transforming
    their research into videos, games, or digital content and make them accessible through
    our platform.

    *Profits will support low-income countries education and economic development*
    through our business model in which we connect education, training, and jobs.`,
                option_researcher: 'Are you a researcher?',
                option_viewer: 'Are you a viewer?'
            },
            menu: {
                item_main: 'Home',
                item_content: 'Content',
                item_manage_content: 'Manage my content',
                item_statistics: 'Statistics',
                item_earnings: 'Earnings',
                item_settings: 'Settings',
                item_about_us: 'About us',
                item_logout: 'Logout'
            },
            content: {
                choosen_tags: 'Choosen tags',
                people_like_this: 'People like this',
                share: 'Share',
                add_reply_cancel: 'Cancel',
                add_reply: 'Reply',
                add_reply_placeholder: 'Add reply',
                by: 'By:',
                add_comment: 'Comment',
                choose_video: 'Choose a video',
                title: 'Title',
                title_placeholder: 'Title',
                description: 'Description',
                description_placeholder: 'Description',
                cancel: 'Cancel',
                upload_content_video: 'Upload content (video)',
                update: {
                    legend: 'Update video content'
                },
                create: {
                    legend: 'Create video content'
                }
            },
            form: {
                data_saved: 'Data saved',
                create_account: 'Create Account',
                choose_channel_background: 'Channel background',
                choose_picture: 'Choose an image',
                choose_language: 'Choose language',
                name: 'Name',
                name_placeholder: 'your name',
                nickname: 'Nickname',
                nickname_placeholder: 'your nickname',
                email: 'Email',
                email_placeholder: 'your email',
                title: 'Title',
                title_placeholder: 'your title',
                tasks: 'Area of research',
                tasks_placeholder: 'describe your area of research',
                channel_name: 'Channel name',
                channel_description: 'Channel description',
                submit: 'Submit',
                password1: 'Password',
                password2: 'Password again',
                password1_placeholder: 'Password',
                password2_placeholder: 'Password again',
                delete_user_button: 'Remove account',
                delete_user_confirmation:
                    'Are you sure you want to remove your user?',
                delete_user_confirmation_cancel: 'Cancel',
                delete_user_confirmation_affirm: 'Yes'
            },
            table: {
                search: 'Dizcover by title or description',
                created_at: 'Date',
                owner: 'Owner',
                channel_name: 'Channel',
                tags: 'Tags',
                title: 'Title',
                description: 'Description',
                remove_selected: 'Remove selected',
                upload: 'New video'
            },
            statistics: {
                header: 'Visits by period'
            },
            earnings: {
                header: 'Earnings ($) by period'
            },
            settings: {
                header: 'Settings'
            },
            breadcrumbs: {
                home: 'Home'
            }
        }
    }
};
