import { Box, useTheme } from '@mui/material';
import { AiOutlineMenu } from 'react-icons/ai';

type Props = {
    setIsMenuOpen: (o: boolean) => void;
};
export function MenuButton({ setIsMenuOpen }: Props) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.default,
                cursor: 'pointer',
                position: 'absolute',
                right: '20px',
                top: '20px'
            }}
            onClick={() => setIsMenuOpen(true)}
        >
            <AiOutlineMenu color={theme.palette.text.primary} size="40px" />
        </Box>
    );
}
